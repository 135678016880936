<template>
  <div class="pub_head">
    <div class="pub_head_content">
      <div class="pub_head_content_set EC_width">
        <div class="height_100 display_center_ud">
          <div class="pub_bigHead">
            <img :src="userImage" />
          </div>
          <div class="pub_hc_left">
            <div class="pub_Name marginBottom10"
                 style="display:flex;align-items: center;">
              {{ nicknameMo }}
              <img src="../../assets/img/s.png"
                   class="viplogpo"
                   alt=""
                   v-if="this.userType ==1" />
              <img src="../../assets/img/v.png"
                   class="viplogpo"
                   alt=""
                   v-if="this.userType ==2" />
            </div>
            <div class="pub_intro"
                 v-if="userType == 1">
              <span class="pub_tip"
                    v-if="userPerInfo.memberDate">权限到期时间：{{ userPerInfo.memberDate.m_EndDate }}</span>

            </div>
            <div class="pub_intro"
                 v-else-if="userType ==2">
              <span class="pub_tip"
                    v-if="userPerInfo.memberDate">权限到期时间：{{ userPerInfo.memberDate.m_EndDate }}</span>

            </div>
          </div>
        </div>
        <div class="pub_hc_right">
          <div class="display_between">
            <div class="pub_hcr_infor">
              <div class="pub_hcr_infor_num">{{ duration }}h</div>
              <div class="pub_hcr_infor_text">
                学习时长
              </div>
            </div>
            <div class="pub_hcr_infor">
              <div>
                <div class="pub_hcr_infor_num">
                  {{ NumberOfcourses }}
                </div>
                <div class="pub_hcr_infor_text">
                  课程
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user_head',

  data () {
    return {
      userPerInfo: '',
      userImage: '',
      // 用户id
      AuthorId: '',
      // vip到期时间
      VipEdiDate: '',
      // 是否是vip
      userType: 0,
      // 昵称
      nicknameMo: '',
      // 个性签名
      personSign: '',
      //学习时长
      duration: 0,
      //课程数
      NumberOfcourses: 0
    };
  },
  methods: {
    // 获取用户个人信息
    getUserInfo () {
      this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: this.AuthorId
        })
        .then(res => {
          this.userPerInfo = res.data;
          this.personSign = this.userPerInfo.introduction;
          this.nicknameMo = this.userPerInfo.nickname;
          this.userImage = this.userPerInfo.avatarUrl;
          this.getLearningNumber();
        });
    },
    getLearningNumber () {
      this.httphelper.get('CourseClassApi/GetLearningNumber?userid=' + this.AuthorId).then(res => {
        if (res.code == 200) {
          this.duration = res.data[0];
          this.NumberOfcourses = res.data[1];
        }
      });
    }
  },
  created () {
    if (localStorage.getItem('ResData')) {
      // 用户id
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id;

      this.userType = localStorage.getItem('userType');
    }
    this.getUserInfo();
  }
};
</script>

<style scoped>
/* 头部 */
.pub_head {
  min-width: 1240px;
}

.pub_head_bar {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pub_left_logo {
  width: 200px;
}

.pub_center_menu {
  flex: 1;
  height: 100%;
}

.pub_menu {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
}

.pub_menu li {
  float: left;
  height: 100%;
  line-height: 54px;
}

.pub_menu li a {
  padding: 0 20px;
}

.pub_menu_active a {
  color: #0b8686;
}

.pub_right_opra {
  width: 320px;
  height: 100%;
  line-height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputRelative {
  position: relative;
  display: inline-block;
}

.pub_head_bar_search {
  width: 200px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 24px;
  outline: none;
  border: 1px solid #ddd;
  padding-left: 12px;
}

.inputRelative img {
  position: absolute;
  right: 11px;
  top: 19px;
  cursor: pointer;
}

.pub_mes_img {
  margin: 0 20px;
}

.pub_bar_headimg {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  overflow: hidden;
  line-height: 24px;
  text-align: center;
  border: 1px solid #ddd;
}

.pub_head_content {
  height: 125px;
  background: url("../../assets/img/headbg.png");
}

.pub_head_content_set {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.pub_bigHead {
  height: 100%;
  width: 150px;
  position: relative;
}

.pub_bigHead img {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border: 1px solid #ddd;
  position: absolute;
  top: 12px;
  overflow: hidden;
}

.pub_hc_left {
  margin-left: 20px;
}

.pub_Name {
  font-size: 24px;
  color: #fff;
}

.pub_intro a {
  display: inline-block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  width: 80px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  background: #c42524;
  border-radius: 24px;
  margin-left: 18px;
}

.pub_tip {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}

.pub_tip ~ .pub_tip::before {
  content: "|";
  color: rgba(255, 255, 255, 0.5);
  margin: 0 6px;
}

.pub_hcr_infor {
  margin-left: 20px;
}

.pub_hcr_infor_num {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
}

.pub_hcr_infor_text {
  color: rgba(255, 255, 255, 0.6);
}

.pub_hcr_setbtn {
  width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border: 1px solid #fff;
  position: relative;
  border-radius: 30px;
}

.pub_hcr_setbtn img {
  margin-right: 4px;
  vertical-align: -3px;
}
.viplogpo {
  width: 23px;
  height: 23px;
  margin-left: 10px;
  /* position: absolute;
    left: 330px;
    top: 30px; */
  margin-bottom: -3px;
  z-index: 9;
}
</style>
