<template>
  <div class="container">
    <div class="title">科研选题与设计</div>
    <div class="content">还没确定选题，正在为选题而茫然？——多维度选题推荐，助您快速找到高价值的选题方向</div>
    <div class="main">
      <div class="main-left">
        <div class="main-left-child"
             @click="toInforList">
          <div class="main-left-child-title">科研选题</div>
          <img class="img"
               src="../../assets/img/ky1.png"
               alt="">
          <div class="main-left-child-content">多个选题维度，开启科研思维大门</div>
          <div class="main-left-child-detail">查看详情 ></div>
        </div>
        <div class="main-left-child"
             style="margin-left: 40px;"
             @click="toInforList">
          <div class="main-left-child-title">科研设计</div>
          <img class="img"
               src="../../assets/img/ky2.png"
               alt="">
          <div class="main-left-child-content">一键点通常见科研设计类型与方案</div>
          <div class="main-left-child-detail">查看详情 ></div>
        </div>
      </div>
      <div class="main-right">
        <div class="main-right-top">
          <div class="main-right-top-child"
               :class="{ active: activeIndex == 0 }"
               @click="activeIndex = 0">推荐工具</div>
          <div class="main-right-top-child"
               style="margin-left: 5px;"
               :class="{ active: activeIndex == 1 }"
               @click="activeIndex = 1">推荐课程</div>
          <div class="main-left-top"
               @click="toMore"
               v-show="activeIndex == 0 && toolList.length > 0">查看更多 ></div>
          <div class="main-left-top"
               @click="toMore"
               v-show="activeIndex == 1 && videoList.length > 0">查看更多 ></div>
        </div>

        <div class="main-right-bottom"
             v-if="activeIndex == 0">
          <swiper :options="option"
                  v-if="toolList.length > 0">
            <swiper-slide v-for="(item, index) in toolList"
                          :key="index">
              <img :src="item.slideShow"
                   alt=""
                   class="img"
                   @click="itemClick(item)">
              <div class="name"
                   @click="itemClick(item)">{{ item.title }}</div>
              <div class="introduce"
                   @click="itemClick(item)">{{ item.abstract }}</div>
            </swiper-slide>
          </swiper>
          <div class="t5prevbutton el-icon-arrow-left"
               v-if="toolList.length > 0"></div>
          <div class="t5nextbutton el-icon-arrow-right"
               v-if="toolList.length > 0"></div>
          <div class="wu"
               v-if="toolList.length <= 0">
            <img src="../../assets/image/wu.png"
                 alt=""
                 style="margin-top: 30px">
          </div>
        </div>
        <div class="main-right-video"
             v-if="activeIndex == 1">
          <div class="video-child"
               v-for="(key, k) in videoList"
               :key="k"
               @click="toVideo(key)">
            <img :src="key.acr_PictResUrl"
                 class="video-img"
                 alt="">
            <div class="main-left-child-content">{{ key.title }}</div>
            <img src="../../assets/icon/video.png"
                 class="bf"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   videoList: {
  //     type: Array,
  //     default: []
  //   }
  // },
  data () {
    return {
      activeIndex: 0,
      option: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 18,
        // width: 640,
        navigation: {
          nextEl: '.t5nextbutton',
          prevEl: '.t5prevbutton',
          disabledClass: 't5-button-disabled'
        }
      },
      subjectList: [],
      designList: [],
      toolList: [],

      videoList: []
    }
  },

  created () {
    this.getColumn()
    this.getVideoList()
  },

  methods: {
    // 获取栏目
    getColumn () {
      let params = {
        c_Id: this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 236 : 231,
        isCHorEN: 1
      }
      this.httphelper.post('/ScientificApi/GetColumsInfoAllList', params).then(res => {
        // console.log(res, 'ky')
        if (res.code == 200) {
          let data = res.data
          data.forEach(item => {
            if (item.infoType == 2) {
              let params = {
                keyword: '',
                c_Id: item.id,
                c_PId: 0,
                userId: 0,
                pageIndex: 1,
                pageSize: 10
              }
              this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
                // console.log(res, 'ke 推荐工具')
                this.toolList = res.data
              })
            }
          });
        }
      })
    },

    getVideoList () {
      let params = {
        labelName: '科研选题与设计',
        number: 2
      }
      this.httphelper.post('NewApi/GetDbRecommendHomeCoursePageList', params).then(res => {
        this.videoList = res.data
      })
    },

    toMore () {
      if (this.activeIndex == 0) {
        this.$router.push('/tool#top1')
      } else {
        this.$router.push({
          path: '/course#top1',
          // query: {
          //   id: 1
          // }
        })
      }
    },

    toInforList () {
      const { href } = this.$router.resolve({
        path: '/Inforlist',
        query: {
          id: this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 236 : 231,
          labelName: '科研选题与设计',
        }
      });
      window.open(href, '_blank')
    },

    itemClick (item) {
      if (item.address) {
        window.open(item.address, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: '/inforDetails',
          query: {
            id: item.id,
            column_Type: 0
          }
        });
        window.open(href, '_blank')
      }
    },

    toVideo (item) {
      let url = this.httphelper.IsVideo() + '/detail?courseId=' + item.id;
      window.open(url);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/banner2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 220px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
  .content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-top: 18px;
  }
  .main {
    width: 80%;
    // height: 450px;
    margin: 50px auto;
    display: flex;
    .main-left {
      position: relative;
      width: 50%;
      padding-right: 60px;
      display: flex;
      padding-top: 42px;
      padding-bottom: 60px;
      justify-content: flex-end;
      .main-left-child {
        width: 300px;
        border-radius: 8px;
        cursor: pointer;
        .main-left-child-title {
          width: 100%;
          text-align: center;
          height: 36px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          line-height: 30px;
        }
        .img {
          width: 300px;
          height: 170px;
          display: block;
          background: #fff;
          margin: 26px auto;
          border-radius: 8px;
          border: 1px solid #e5e5e5;
          box-shadow: 7px 19px 30px 0px rgba(0, 0, 0, 0.06);
        }
        .main-left-child-content {
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
          margin-top: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .main-left-child-detail {
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 28px;
          margin-top: 10px;
        }
      }
      .main-left-child:hover {
        .img {
          border: 1px solid #0b8586;
          box-shadow: 7px 19px 30px 0px rgba(11, 133, 134, 0.35);
        }
        .main-left-child-detail {
          color: #0b8586;
        }
      }
    }
    .main-left::after {
      position: absolute;
      top: 0;
      right: 0;
      content: " ";
      width: 1px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }

    .main-right {
      position: relative;
      padding-left: 60px;
      width: 50%;
      padding-top: 42px;
      .main-right-top {
        width: 100%;
        display: flex;
        align-items: flex-end;
        .main-right-top-child {
          width: 120px;
          height: 36px;
          background: #fff;
          border: 1px solid #d5d5d5;
          border-radius: 18px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }
        .active {
          color: #0b8586;
          background: #edfafa;
          border: 1px solid #0b8586;
        }
      }
      .main-right-bottom {
        width: 100%;
        position: relative;
        cursor: pointer;
        .img {
          // width: 300px;
          width: 300px;
          height: 170px;
          background: #fff;
          margin-top: 26px;
          border-radius: 8px;
          margin: 26px auto;
          display: block;
          border: 1px solid #e5e5e5;
          box-shadow: 7px 19px 30px 0px rgba(0, 0, 0, 0.06);
        }
        .name {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          text-align: center;
          margin-top: 22px;
        }
        .introduce {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-size: 30px;
          color: #0b8586;
        }
        .el-icon-arrow-left {
          position: absolute;
          top: 32%;
          left: -35px;
          z-index: 9;
        }
        .el-icon-arrow-right {
          position: absolute;
          top: 32%;
          right: -40px;
          z-index: 9;
        }
        .t5-button-disabled {
          color: #d5d5d5;
        }
        .img:hover {
          border: 1px solid #0b8586;
          box-shadow: 7px 19px 30px 0px rgba(11, 133, 134, 0.35);
        }
      }
      .main-right-video {
        display: flex;
        .video-child {
          width: 300px;
          position: relative;
          cursor: pointer;
          .video-img {
            // width: 100%;
            // height: 173px;
            width: 300px;
            height: 172px;
            background: #fff;
            margin: 26px auto 0 auto;
            border-radius: 8px;
          }
          .main-left-child-content {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-top: 23px;
          }
          .bf {
            position: absolute;
            bottom: 75px;
            left: 10px;
            width: 30px;
            height: 30px;
          }
        }
        .video-child:last-child {
          margin-left: 40px;
        }
      }
    }
  }
}
.main-left-top {
  // margin: 20px 0;
  // width: 50%;
  flex: 1;
  text-align: right;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  cursor: pointer;
}
.wu {
  text-align: center;
  line-height: 270px;
  font-weight: bold;
  font-size: 24px;
}
// @media screen and (max-width: 1900px) {
//   .container {
//     padding-top: 130px;
//     .title {
//       font-size: 28px;
//       margin-top: 40px;
//     }
//     .main {
//       margin: 30px auto;
//     }
//   }
//   .container .main .main-right .main-right-bottom .img {
//     width: calc(100% - 10px);
//   }
// }
</style>