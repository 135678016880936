<template>
  <div class="container">
    <div class="title">文献检索</div>
    <div class="content">怎么去找、如何去读文献？——轻松读懂文献，为您提供文献找不到、看不懂、效率低、收获少的解决方案</div>
    <div class="main">
      <div class="main-left">
        <div class="main-left-top"
             @click="toTool"
             v-show="list.length > 0">查看更多 ></div>
        <div class="main-right-bottom"
             v-if="list.length > 0">
          <swiper :options="option">
            <swiper-slide v-for="(item, index) in list"
                          :key="index">
              <img :src="item.slideShow"
                   class="img"
                   alt=""
                   @click="toolClick(item)">
              <div class="name"
                   @click="toolClick(item)">{{ item.title }}</div>
            </swiper-slide>
          </swiper>
          <div class="t5prevbutton el-icon-arrow-left"
               @click="prev"></div>
          <div class="t5nextbutton el-icon-arrow-right"
               @click="next"></div>
        </div>
        <div class="wu"
             v-if="list.length <= 0">
          <img src="../../assets/image/wu_bai.png"
               alt=""
               style="margin-top: 70px">
        </div>
      </div>
      <div class="main-right">
        <div class="main-right-top">
          <div class="main-right-top-child"
               :class="{ active: activeIndex == 0 }"
               @click="activeIndex = 0">文献资讯</div>
          <div class="main-right-top-child"
               style="margin-left: 5px;"
               :class="{ active: activeIndex == 1 }"
               @click="activeIndex = 1">推荐课程</div>
          <div class="more"
               v-show="activeIndex == 1"
               @click="toMore">查看更多 ></div>
          <div class="more"
               v-show="activeIndex == 0"
               @click="toMorezx">查看更多 ></div>
        </div>
        <div class="main-right-information"
             v-if="activeIndex == 0">
          <div v-for="(item, index) in informationList"
               :key="index"
               v-if="informationList.length > 0"
               @click="itemClick(item)"
               class="main-right-information-child">
            <div class="information-title">{{ item.title }}</div>
            <div style="display: flex;">
              <div class="information-time">{{ item.publishtime.split(' ')[0] }}</div>
              <div class="information-num">
                <img src="../../assets/icon/eye.png"
                     alt=""
                     class="eye">{{ item.clickVolume }}
              </div>
            </div>
          </div>
          <div class="wu"
               v-if="informationList.length <= 0">
            <img src="../../assets/image/wu_bai.png"
                 alt=""
                 style="margin-top: 30px">
          </div>
        </div>
        <div class="main-right-video"
             v-if="activeIndex == 1">
          <div class="video-child"
               v-for="(key, k) in videoList"
               :key="k"
               @click="toVideo(key)">
            <img :src="key.acr_PictResUrl"
                 class="video-img"></img>
            <div class="main-left-child-content">{{ key.title }}</div>
            <img src="../../assets/icon/video.png"
                 class="bf"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   videoList: {
  //     type: Array,
  //     default: []
  //   }
  // },
  data () {
    return {
      activeIndex: 0,
      option: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        slidesPerGroup: 6,
        spaceBetween: 20,
        slidesPerColumnFill: 'row',
        navigation: {
          nextEl: '.t5nextbutton',
          prevEl: '.t5prevbutton',
          disabledClass: 't5-button-disabled'
        }
      },
      informationList: [],
      list: [],
      videoList: [],
      c_Id: '',
      pageIndex: 1
    }
  },

  created () {
    this.getColumn()
    this.getVideoList()
  },

  methods: {
    // 获取栏目
    getColumn () {
      let params = {
        c_Id: this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 215 : 235,
        isCHorEN: 1
      }
      this.httphelper.post('/ScientificApi/GetColumsInfoAllList', params).then(res => {
        console.log(res.data, '====sss')
        if (res.code == 200) {
          let data = res.data
          data.forEach(item => {
            if (item.name == '推荐工具') {
              this.c_Id = item.id
            }
            let params = {
              keyword: '',
              c_Id: item.id,
              c_PId: 0,
              userId: 0,
              pageIndex: this.pageIndex,
              pageSize: 6
            }
            this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
              if (res.data.length > 0) {
                if (res.data[0].c_Name == '推荐工具') {
                  this.list = res.data
                } else if (res.data[0].c_Name == '文献资讯') {
                  this.informationList = res.data.splice(0, 4)
                  console.log(this, this.informationList, '==文献资讯')
                }
              }
            })
          });
        }
      })
    },

    next () {
      this.pageIndex = this.pageIndex + 1
      let params = {
        keyword: '',
        c_Id: this.c_Id,
        c_PId: 0,
        userId: 0,
        pageIndex: this.pageIndex,
        pageSize: 6
      }
      this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
        if (res.code == 200 && res.data.length > 0) {
          this.list = res.data
        } else {
          this.pageIndex = this.pageIndex - 1
        }
      })
    },

    prev () {
      if (this.pageIndex == 1) return
      this.pageIndex = this.pageIndex - 1
      let params = {
        keyword: '',
        c_Id: this.c_Id,
        c_PId: 0,
        userId: 0,
        pageIndex: this.pageIndex,
        pageSize: 6
      }
      this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
    },

    toolClick (item) {
      if (item.address) {
        window.open(item.address, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: '/inforDetails',
          query: {
            id: item.id,
            column_Type: 0
          }
        });
        window.open(href, '_blank')
      }
    },

    getVideoList () {
      let params = {
        labelName: '文献检索',
        number: 2
      }
      this.httphelper.post('NewApi/GetDbRecommendHomeCoursePageList', params).then(res => {
        this.videoList = res.data
      })
    },

    toMore () {
      this.$router.push({
        path: '/course#top2',
        // query: {
        //   id: 2
        // }
      })
    },

    toMorezx () {
      const { href } = this.$router.resolve({
        path: '/Inforlist',
        query: {
          id: this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 215 : 235,
          labelName: '文献检索',
        }
      });
      window.open(href, '_blank')
    },

    toTool () {
      this.$router.push('/tool#top2')
    },

    itemClick (item) {
      console.log(item)
      item.clickVolume = item.clickVolume + 1
      if (item.address) {
        window.open(item.address, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: '/inforDetails',
          query: {
            id: item.id,
            column_Type: item.c_ID
          }
        });
        window.open(href, '_blank')
      }
    },

    toVideo (item) {
      let url = this.httphelper.IsVideo() + '/detail?courseId=' + item.id;
      window.open(url);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/banner3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 220px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #fff;
  }
  .content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    margin-top: 18px;
  }
  .main {
    width: 80%;
    // height: 450px;
    margin: 50px auto;
    display: flex;
    .main-left {
      position: relative;
      width: 50%;
      padding-right: 60px;
      padding-top: 42px;
      padding-bottom: 42px;
      .main-left-top {
        margin: 20px 0;
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
      .main-right-bottom {
        width: 100%;
        position: relative;
        cursor: pointer;
        .img {
          width: 200px;
          height: 114px;
          // background: #fff;
          border-radius: 8px;
        }
        .img:hover {
          border: 1px solid #0b8586;
          box-shadow: 7px 19px 30px 0px rgba(11, 133, 134, 0.35);
        }
        .name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-top: 12px;
        }
        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-size: 40px;
          color: #0b8586;
        }
        .el-icon-arrow-left {
          position: absolute;
          top: 50%;
          transform: translateY((-50%));
          left: -45px;
          z-index: 9;
        }
        .el-icon-arrow-right {
          position: absolute;
          top: 50%;
          transform: translateY((-50%));
          right: -45px;
          z-index: 9;
        }
        .t5-button-disabled {
          color: #d5d5d5;
        }
      }
    }
    .main-left::after {
      position: absolute;
      top: 0;
      right: 0;
      content: " ";
      width: 1px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }

    .main-right {
      width: 50%;
      padding-left: 60px;
      padding-top: 42px;
      .main-right-top {
        width: 100%;
        display: flex;
        align-items: flex-end;
        .main-right-top-child {
          width: 120px;
          height: 36px;
          color: #ffffff;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 18px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }
        .active {
          color: #000000;
          background: #fff;
        }
      }
      .main-right-information {
        margin-top: 11px;
        .main-right-information-child {
          margin-top: 26px;
          cursor: pointer;
          .information-title {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .information-time,
          .information-num {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.45;
            margin-top: 14px;
            .eye {
              width: 17px;
              height: 11px;
              margin-right: 7px;
            }
          }
          .information-time {
            margin-right: 32px;
          }
        }
      }
      .main-right-video {
        display: flex;
        .video-child {
          width: calc(50% - 40px);
          position: relative;
          cursor: pointer;
          .video-img {
            width: 100%;
            // height: 173px;
            background: #fff;
            margin-top: 36px;
            border-radius: 8px;
          }
          .main-left-child-content {
            margin-top: 15px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          .bf {
            position: absolute;
            bottom: 50px;
            left: 10px;
            width: 30px;
            height: 30px;
          }
        }
        .video-child:last-child {
          margin-left: 40px;
        }
      }
    }
  }
}
.more {
  // margin: 20px 0;
  // width: 50%;
  flex: 1;
  text-align: right;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}
.wu {
  text-align: center;
  line-height: 270px;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}
// @media screen and (max-width: 1900px) {
//   .container {
//     padding-top: 130px;
//     .title {
//       font-size: 28px;
//       margin-top: 40px;
//     }
//     .main {
//       margin: 30px auto;
//     }
//   }
//   .container
//     .main
//     .main-right
//     .main-right-information
//     .main-right-information-child {
//     margin-top: 20px;
//   }
// }
</style>