<template>
  <div class="container"
       :class="{ active: activeIndex != 0 && activeIndex != 2 }">
    <div class="left"
         :class="{ black: activeIndex != 0 && activeIndex != 2 }">
      <img src="../../assets/image/logo.png"
           alt=""
           class="img"
           v-if="activeIndex == 0 || activeIndex == 2">
      <img src="../../assets/image/blackLogo.png"
           alt=""
           class="img"
           v-if="activeIndex != 0 && activeIndex != 2">
      <div class="left-txt"
           @click="toHome">科研首页</div>
      <div class="left-txt"
           @click="toTool">科研工具</div>
      <div class="left-txt"
           @click="toCourse">科研课程</div>
    </div>
    <div class="right"
         :class="{ blackRight: activeIndex != 0 && activeIndex != 2 }">
      <!-- <div class="search">
        <input type="text"
               placeholder="搜索你感兴趣的内容"
               class="input">
        <el-button type="primary"
                   icon="el-icon-search"
                   class="btn">搜索</el-button>
      </div> -->

      <div class="opra"
           v-show="taggleinfor"
           @click.stop>
        <div class="text_right"
             style="position: relative">
          <img src="../../assets/icons/sanjiao3.png"
               alt=""
               class="ArrowTop" />
        </div>

        <div class="display_flex">
          <!-- <img src="/image/index/stateHead.png" /> -->
          <a href="https://gp519.com/user/userInfor"
             target="_blank">
            <img :src="info.avatarUrl"
                 class="img-border" />
          </a>
          <div class="flex1 marginLeft10">
            <p class="marginTop10 marginBottom10"
               style="color: #000;">
              {{ info.userName != '' ? info.userName : info.mobile }}

              <span class="informy"
                    style="cursor: pointer"
                    @click="touserinfo"> 个人资料 </span>

              <!-- </a> -->
            </p>
            <p style="color: #000;">
              <img src="../../assets/icons/phoneIcon.png"
                   alt="" />
              {{ info.mobile }}
            </p>
          </div>
          <div>
            <span class="out"
                  @click="out">退出</span>
          </div>
        </div>
      </div>
      <div class="modal"
           v-if="taggleinfor"
           @click="taggleinfor = false"></div>
      <div class="login"
           v-if="info == ''">
        <i class="el-icon-user-solid"></i>
        <div class="login-txt"
             @click="toLogin">登录</div>
        <div class="login-txt">|</div>
        <div class="login-txt">注册</div>
      </div>
      <div class="login"
           v-else
           @click="taggleinfor = true">
        <img :src="info.avatarUrl"
             alt=""
             style="width: 40px; height: 40px; margin-right: 20px; border-radius: 50%;">
        <div>{{ info.nickname }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      baseUrl: 'https://new.gp519.com/',
      info: '',
      tokenstatus: true,
      taggleinfor: false
    }
  },

  created () {
    this.getLogin()
  },

  methods: {
    // 跳转首页
    toHome () {
      this.$router.push({
        path: '/'
      })
    },
    // 跳转工具页
    toTool () {
      this.$router.push({
        path: '/tool'
      })
    },
    // 跳转课程页
    toCourse () {
      this.$router.push({
        path: '/course'
      })
    },
    // 跳转登录
    toLogin () {
      this.baseUrl = this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 'https://new.gp519.com/' : 'https://gp519.com/'
      let callback = encodeURIComponent(this.$unit.delUrlData('token'));
      window.location.href = this.baseUrl + 'loginIndex/login?type=3&callback=' + callback;
    },

    getLogin () {
      if (localStorage.getItem('ResData')) {
        if (JSON.parse(localStorage.getItem('ResData')).id) {
          this.tokenstatus = false;
          this.getUserInfo(JSON.parse(localStorage.getItem('ResData')).id, 0);
        } else {
          localStorage.clear();
          let curl = this.$unit.delUrlData('token');
          window.location.href = curl;
          this.info = ''
        }
      }
      console.log(this.$route.query);
      if (Object.keys(this.$route.query).length > 0 && Object.prototype.hasOwnProperty.call(this.$route.query, 'token')) {
        if (this.$route.query.token == 'false') {
          localStorage.setItem('tokenstatus', true);
        } else {
          localStorage.removeItem("tokenstatus")
        }
      }
      var _tokenstatus = this.$route.query.token;
      console.log(_tokenstatus)
      //有登录信息、url地址有token且为false
      if (_tokenstatus == 'false') {
        this.tokenstatus = false;
        //无登录信息、url地址有token且不为false
      } else if (_tokenstatus != null && _tokenstatus != 'false') {
        localStorage.setItem('token_newgphome', _tokenstatus);
        this.$store.dispatch('user/fetchTokenStatus', _tokenstatus)
        this.httphelper
          .post('LoginApi/CheckToken', {
            tokenKey: _tokenstatus,
          })
          .then(res => {
            if (res.code == 200) {
              // if (res.data.isTcm) {
              //   this.getUserInfo(res.data.id, 1);
              // }
              this.getUserInfo(res.data.id, 1);
            } else {
              localStorage.clear();
              let curl = this.$unit.delUrlData('token');
              window.location.href = curl;
            }
          });
      } else if (this.$route.query.openid || this.$route.query.userid) {
        this.httphelper
          .post('LoginApi/GetUserInfoByOpenid', {
            openid: this.$route.query.openid || '',
            userid: Number(this.$route.query.userid) || 0
          })
          .then(res => {
            if (res.code == 200) {
              localStorage.setItem('token_newgphome', res.data.token);
              this.$store.dispatch('user/fetchToken', res.data.token)
              this.getUserInfo(res.data.id, 2);
            } else {
              localStorage.clear();
              if (this.$route.query.userid) {
                let curl = this.$unit.delUrlData('userid');
                window.location.href = curl;
              } else if (this.$route.query.openid) {
                let curl = this.$unit.delUrlData('openid');
                window.location.href = curl;
              }
            }
          });
        //无登录信息、url地址无token、本地有token
      } else if (localStorage.getItem('token_newgphome') && localStorage.getItem('token_newgphome') != null) {
        this.httphelper
          .post('LoginApi/CheckToken', {
            tokenKey: localStorage.getItem('token_newgphome'),
          })
          .then(res => {
            if (res.code == 200) {
              this.getUserInfo(res.data.id, 0);
            } else {
              localStorage.clear();
              let curl = this.$unit.delUrlData('token');
              window.location.href = curl;
            }
          });
      } else if (this.tokenstatus) {
        //无登录信息、本地无token
        if (localStorage.getItem('tokenstatus') == null) {
          if (!localStorage.getItem('token_newgphome') || localStorage.getItem('token_newgphome') == null) {
            var callback = encodeURIComponent(window.location.href);
            window.location.href = this.baseUrl + 'loginIndex/ssologin?callback=' + callback;
            //无登录信息、本地有token
          } else {
            this.httphelper
              .post('LoginApi/CheckToken', {
                tokenKey: localStorage.getItem('token_newgphome'),
              })
              .then(res => {
                if (res.code == 200) {
                  this.getUserInfo(res.data.id, 0);

                } else {
                  localStorage.clear();
                  var callback = encodeURIComponent(window.location.href);
                  window.location.href = this.baseUrl + 'loginIndex/ssologin?callback=' + callback;
                }
              });
          }
        }
      }
    },

    // 获取用户消息
    getUserInfo (authorId, removetoke) {
      this.httphelper.post('CGpUserApi/GetUserMessage', {
        id: parseInt(authorId),
      }).then(res => {
        if (res.code == 200) {
          res.data.id = authorId
          localStorage.setItem('ResData', JSON.stringify(res.data));
          this.$store.dispatch('user/fetchUserInfo', res.data)
          var aData = new Date();
          var CurrentDate = aData.getFullYear() + '-' + (aData.getMonth() + 1) + '-' + aData.getDate() + ' ' + aData.getHours() + ':' + aData.getMinutes() + ':' + aData.getSeconds();
          var VipEdDate = res.data.memberDate.m_EndDate;
          var CurrentDategetTime = new Date(CurrentDate.replace(/-/g, '/')).getTime() / 1000;
          var VipEdDategetTime = new Date(VipEdDate.replace(/-/g, '/')).getTime() / 1000;
          if (res.data.memberDate.m_EndDate == null || parseInt(CurrentDategetTime) - parseInt(VipEdDategetTime) >= 0) {
            this.userType = 0;
          } else {
            if (res.data.memberDate.m_Type == 1) {
              this.userType = 1;
            } else if (res.data.memberDate.m_Type == 2) {
              this.userType = 2;
            } else {
              this.userType = 0;
            }
          }
          localStorage.setItem('userType', this.userType);
          this.$store.dispatch('user/fetchUserType', this.userType)
          this.info = res.data;
          console.log(this.info, '===info 信息')
          this.getUnreadmsg();
          if (removetoke == 1) {
            let curl = this.$unit.delUrlData('token');
            window.location.href = curl;
          } else if (removetoke == 2) {
            if (this.$route.query.userid) {
              let curl = this.$unit.delUrlData('userid');
              window.location.href = curl;
            } else if (this.$route.query.openid) {
              let curl = this.$unit.delUrlData('openid');
              window.location.href = curl;
            }
          }
        }
      })
    },

    getUnreadmsg () {
      this.httphelper
        .post('MessageApi/GetNewMessageCount', {
          userId: this.info.id,
        })
        .then(res => {
          this.msgNum = res.data;
        });
    },

    touserinfo () {
      // this.navid = 'ltop1'
      // this.taggleinfor = false;
      // let roterJump = this.$router.resolve({ path: '/user/userinfo' });
      // window.open(roterJump.href, '_blank')
    },

    // 推出
    out () {
      if (localStorage.getItem('token_newgphome') && localStorage.getItem('token_newgphome') != null) {
        this.httphelper
          .post('LoginApi/ClearToken', {
            tokenKey: localStorage.getItem('token_newgphome'),
          })
          .then(res => {

            if (res.code == 200) {
              localStorage.clear();
              this.taggleinfor = false;
              this.info = '';
              this.$router.go(0);
            } else {
              localStorage.clear();
              this.taggleinfor = false;
              this.info = '';
              this.$router.go(0);
            }
          });
      } else {
        localStorage.clear();
        this.taggleinfor = false;
        this.info = '';
        // let curl = this.delUrlData('token');
        // window.location.href = curl;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  z-index: 9;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 11px 0;
  align-items: center;
  .left {
    color: #ffffff;
    display: flex;
    align-items: center;
    .img {
      width: 164px;
      height: 50px;
      margin-left: 60px;
    }
    .left-txt {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-left: 60px;
      cursor: pointer;
    }
  }
  .black {
    color: #000000;
  }
  .right {
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-right: 60px;
    position: relative;
    .search {
      .input {
        width: calc(200px - 30px);
        height: 36px;
        background: #ffffff;
        border-radius: 18px 0px 0px 18px;
        border: none;
        outline: none;
        padding-left: 30px;
      }
      .btn {
        width: 100px;
        background: #0b8586;
        border-radius: 0px 18px 18px 0px;
        outline: none;
        border: none;
      }
    }
    .login {
      display: flex;
      align-items: center;
      cursor: pointer;
      .el-icon-user-solid {
        margin-left: 33px;
      }
      .login-txt {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-left: 14px;
        cursor: pointer;
      }
    }
  }
  .blackRight {
    color: #000000;
    .search {
      .input {
        border: 1px solid #dcdcdc;
        height: 34px;
      }
    }
  }
}
.active {
  border-bottom: 1px solid #d5d5d5;
}

.opra {
  padding: 25px;
  display: inline-block;
  position: absolute;
  background: #fff;
  width: 400px;
  box-sizing: border-box;
  right: 0px;
  top: 70px;
  box-shadow: 0px 5px 15px 0px rgba(6, 50, 52, 0.2);
  border-radius: 3px;
  z-index: 10000;
}

.out {
  padding: 2px 8px;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
  cursor: pointer;
  color: #000000;
}

.informy {
  width: 77px;
  height: 25px;
  background: #0b8586;
  border-radius: 13px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  color: #fff;
  margin-left: 5px;
  // font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.vip {
  height: 50px;
  background: url("../../assets/imgs/header/gerenbar.png");
  padding: 0 6px;
}

.kaitong {
  width: 96px;
  height: 29px;
  background: linear-gradient(90deg, #ff1e00, #ff6600);
  border-radius: 14px;
  text-align: center;
  line-height: 29px;
  color: #fff;

  a {
    color: #fff;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}

.ArrowTop {
  width: 20px;
  height: 18px;
  position: absolute;
  top: -37px;
  right: 10px;
  // background-color: #fff;
}

.img-border {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  width: 60px;
  height: 60px;
  padding-top: 30px;
}

.msgnum {
  width: 6px;
  height: 7px;
  position: absolute;
  top: -5px;
  left: 3px;
  background-color: red;
  border-radius: 50%;
  // font-size: 12px;
  color: #fff;
  box-sizing: border-box;
  padding: 1px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
</style>