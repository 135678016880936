<template>
  <div id="app">
    <keep-alive include="home">
      <router-view v-if="isRouterAlive" />
    </keep-alive>

  </div>
</template>

<script>
import $ from 'jquery';
export default {
  name: 'App',
  // 提供一个刷新的依赖
  provide () {
    return {
      reload: this.reload
    };
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
}
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
}
</style>
