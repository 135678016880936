<template>
  <!-- 申请开发票 -->
  <div id="OpenInvoice"
       class="OpenInvoice">
    <div class="OpenInvoice_cont">
      <div class="invoic_body_cont">
        <el-form :model="form"
                 :rules="rules"
                 ref="Openinvoice">
          <div class="plain_invoice">
            <p>电子普通发票信息</p>
            <p></p>
            <el-row style="margin-top:15px;">
              <el-col :span="12">
                <el-form-item label="发票类型："
                              prop="InvoiType">
                  <el-select size="small"
                             v-model="form.InvoiType"
                             @change="InvoType">
                    <el-option v-for="item in SumInvoiType"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="服务名称："
                              prop="InvoiCont">
                  <el-input size="small"
                            v-model="form.InvoiCont"
                            disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="抬头类型："
                              prop="LookUpType">
                  <el-select size="small"
                             v-model="form.LookUpType"
                             @change="LookType">
                    <el-option v-for="item in LookUpType"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 电子发票个人 -->
            <el-row style="position:relative;">
              <el-col :span="12">
                <el-form-item label="发票抬头："
                              prop="InvoiTitle">
                  <el-input size="small"
                            :placeholder="form.LookUpType == 1?'填写个人姓名':'填写公司名称'"
                            v-model="form.InvoiTitle"></el-input>
                </el-form-item>
                <!-- <span style="position: absolute;left: 60%;top: 11px;font-size: 14px;"
                      v-if="form.LookUpType == 1">填写个人姓名</span>
                <span style="position: absolute;left: 60%;top: 11px;font-size: 14px;"
                      v-if="form.LookUpType == 2">填写公司名称</span> -->
              </el-col>
            </el-row>
            <el-row v-if="InvoTitCompan"
                    style="position:relative;">
              <el-col :span="12">
                <el-form-item label="单位税号："
                              prop="InvoiNum">
                  <el-input size="small"
                            placeholder="请您务必正确填写单位税号"
                            v-model="form.InvoiNum"></el-input>
                </el-form-item>
                <span style="position: absolute;left: 60%;top: 11px;font-size: 14px;"></span>
              </el-col>
            </el-row>
            <el-row v-if="InvoTitCompan"
                    style="position:relative;">
              <el-col :span="12">
                <el-form-item label="开户行及账号："
                              style="margin-left:-18px">
                  <el-input size="small"
                            style="width:350px"
                            placeholder="例：XX银行XX支行 1105016**********521"
                            v-model="form.vat_bank_account"></el-input>
                </el-form-item>
                <span style="position: absolute;left: 60%;top: 11px;font-size: 14px;">选填</span>
              </el-col>
            </el-row>
            <el-row v-if="InvoTitCompan"
                    style="position:relative;">
              <el-col :span="12">
                <el-form-item label="地址、电话："
                              style="margin-left:-4px">
                  <el-input size="small"
                            style="width:350px"
                            placeholder="例：北京市西城区广义街5号9层3-907E 010-83*****9"
                            v-model="form.vat_company_address"></el-input>
                </el-form-item>
                <span style="position: absolute;left: 60%;top: 11px;font-size: 14px;">选填</span>
              </el-col>
            </el-row>
            <el-row v-if="InvoTitCompan"
                    style="position:relative;">
              <el-col :span="12">
                <el-form-item label="备注："
                              style="margin-left:38px">
                  <el-input size="small"
                            style="width:350px"
                            v-model="form.vat_remark"></el-input>
                </el-form-item>
                <span style="position: absolute;left: 60%;top: 11px;font-size: 14px;">选填</span>
              </el-col>
            </el-row>
          </div>
          <div class="plain_invoice">
            <p>收票信息</p>
            <p></p>
            <el-row style="margin-top:15px;margin-left:23px">
              <el-col :span="12"
                      style="width:68%">
                <el-form-item label="总金额："
                              prop="Sumcount">
                  <el-input size="small"
                            v-model="form.Sumcount"
                            disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="position:relative;">
              <el-col :span="12">
                <el-form-item label="电子邮箱："
                              prop="PerEmail">
                  <el-input size="small"
                            v-model="form.PerEmail"></el-input>
                </el-form-item>
                <span style="position: absolute;left: 60%;top: 11px;font-size: 14px;">用于向您发送电子发票，请认真填写！</span>
              </el-col>
            </el-row>
          </div>
          <div class="formBtn"
               @click="updateinvoice()"
               v-if="this.$route.query.type == 'edit'">提交</div>
          <div class="formBtn"
               @click="submitinvoice()"
               v-else>提交</div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// import util from "../../../assets/script/util";
import validate from "../../../assets/unit/validate";
export default {
  name: 'OpenInvoice',
  data () {
    const VALIDATE_EMAIL = (rule, value, callback) => {
      if (!validate.validateEmail(value)) {
        callback(new Error("请正确输入Email"));
      } else {
        callback();
      }
    };
    const VALIDATE_NUM = (rule, value, callback) => {
      var zg = /^[0-9a-zA-Z]*$/;
      if (zg.test(value)) {
        if (value.length != 15 && value.length != 18) {
          callback(new Error("请正确输入15或18位单位税号"));
        } else {
          callback()
        }
      } else {
        callback(new Error("请正确输入单位税号"));
      }
    };
    return {
      // 编辑获取发票信息
      editgetinfo: {},
      // 订单id
      OrderId: '',
      OrderIdB: [],
      // 是否显示资质信息
      QuaiInfo: false,
      // 是否显示收票信息
      TicketInfo: false,
      // 电子发票个人
      InvoTitle: false,
      // 电子发票单位
      InvoTitCompan: false,
      // 发票信息
      form: {
        // 订单编号
        // OrderNum:'',
        // 发票类型
        InvoiType: '1',
        // 服务名称
        InvoiCont: '培训费',
        // 抬头类型
        LookUpType: '1',
        // 发票抬头
        InvoiTitle: '',
        // 单位税号
        InvoiNum: '',
        // 电子邮箱
        PerEmail: '',
        // 总金额
        Sumcount: '',
        // 银行
        vat_bank_account: '',
        // 单位地址
        vat_company_address: '',
        // 备注
        vat_remark: ''
      },
      // 发票类型
      SumInvoiType: [
        {
          name: '普通发票',
          id: '1'
        },
      ],
      // 抬头类型
      LookUpType: [
        {
          name: '个人',
          id: '1'
        },
        {
          name: '单位',
          id: '2'
        }
      ],
      rules: {
        InvoiType: [{ required: true, message: "请选择发票类型", trigger: "change" }],
        InvoiCont: [{ required: true, message: "请选择发票内容", trigger: "blur" }],
        LookUpType: [{ required: true, message: "请选择抬头类型", trigger: "change" }],
        InvoiNum: [
          { required: true, message: "请输入单位税号", trigger: "blur" },
          { validator: VALIDATE_NUM, trigger: 'blur' }
        ],
        InvoiTitle: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
        PerEmail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: VALIDATE_EMAIL, trigger: "blur" },
        ],
      },
      checkout: false,
    }
  },
  methods: {
    // 提交发票信息
    submitinvoice () {
      console.log(1111);
      if (this.form.LookUpType == 1) {
        // this.form.InvoiNum = '请您务必正确填写单位税号'
        this.InvoTitCompan = false
        this.$refs.Openinvoice.validate(valid => {
          if (valid) {
            this.httphelper.post('InvoiceApi/SaveInvoiceInfo', {
              userId: this.AuthorId,
              iOrders: this.OrderId,
              // 发票类型
              invoiceType: Number(this.form.InvoiType),
              // 抬头类型 
              iHerdType: Number(this.form.LookUpType),
              // 发票抬头
              invoiceHerd: this.form.InvoiTitle,
              // 单位税号
              unitNumber: this.form.InvoiNum,
              // 服务名称
              serviceName: this.form.InvoiCont,
              iEmil: this.form.PerEmail,
              vat_bank_account: this.form.vat_bank_account,
              vat_company_address: this.form.vat_company_address,
              vat_remark: this.form.vat_remark,
            }).then((res) => {
              this.form.InvoiNum = ''
              this.form.InvoiTitle = ''
              this.form.PerEmail = ''
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: 'success'
                })
                this.$router.push({
                  path: '/user/userMyInvoice',
                  query: {
                    name: '我的发票'
                  }
                })
              } else if (res.code == 303) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 304) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 305) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 302) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 301) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
          }
        })
      } else {
        this.$refs.Openinvoice.validate(valid => {
          if (valid) {
            this.httphelper.post('/api/InvoiceApi/SaveInvoiceInfo',
              {
                userId: this.AuthorId,
                iOrders: this.OrderId,
                // 发票类型
                invoiceType: Number(this.form.InvoiType),
                // 抬头类型 
                iHerdType: Number(this.form.LookUpType),
                // 发票抬头
                invoiceHerd: this.form.InvoiTitle,
                // 单位税号
                unitNumber: this.form.InvoiNum,
                // 服务名称
                serviceName: this.form.InvoiCont,
                iEmil: this.form.PerEmail,
                vat_bank_account: this.form.vat_bank_account,
                vat_company_address: this.form.vat_company_address,
                vat_remark: this.form.vat_remark,
              }).then((res) => {
                this.form.InvoiNum = ''
                this.form.InvoiTitle = ''
                this.form.PerEmail = ''
                if (res.code == 200) {
                  this.$message({
                    message: res.msg,
                    type: 'success'
                  })
                  this.$router.push({
                    path: '/user/userMyInvoice',
                    query: {
                      name: '我的发票'
                    }
                  })
                } else if (res.code == 303) {
                  this.$message({
                    message: res.msg,
                    type: 'warning'
                  })
                } else if (res.code == 304) {
                  this.$message({
                    message: res.msg,
                    type: 'warning'
                  })
                } else if (res.code == 305) {
                  this.$message({
                    message: res.msg,
                    type: 'warning'
                  })
                } else if (res.code == 302) {
                  this.$message({
                    message: res.msg,
                    type: 'warning'
                  })
                } else if (res.code == 301) {
                  this.$message({
                    message: res.msg,
                    type: 'warning'
                  })
                }
              })
          }
        })
      }

    },
    // 选择发票类型触发事件
    InvoType (val) {
      if (val == 2) {
        this.form.LookUpType = '2'
        this.QuaiInfo = true
        this.TicketInfo = true
        this.InvoTitle = false
        this.InvoTitCompan = true
      } else {
        this.form.LookUpType = '1'
        this.QuaiInfo = false
        this.TicketInfo = false
        this.InvoTitle = true
        this.InvoTitCompan = false
      }
    },
    // 选择抬头类型触发事件
    LookType (val) {
      if (val == 2) {
        this.QuaiInfo = true
        this.InvoTitle = false
        this.InvoTitCompan = true
      } else {
        // this.form.InvoiType = '1'
        this.TicketInfo = false
        this.QuaiInfo = false
        this.InvoTitle = true
        this.InvoTitCompan = false
      }
    },
    // 获取发票信息
    getInvoiceInfo () {
      this.httphelper.post('/api/InvoiceApi/GetInvoiceDetailsInfo', {
        userId: this.AuthorId,
        id: Number(this.$route.query.id)
      }).then((res) => {
        // 发票类型
        this.form.InvoiType = String(res.data.invoiceType)
        // 服务名称
        // this.form.InvoiCont = res.data.serviceName
        // 抬头类型
        this.form.LookUpType = String(res.data.iHerdType)
        if (this.form.LookUpType == 2) {
          this.InvoTitCompan = true
        }
        // 发票抬头
        this.form.InvoiTitle = res.data.invoiceHerd
        // 单位税号
        this.form.InvoiNum = res.data.unitNumber
        // 总金额
        this.form.Sumcount = res.data.totalAmount
        // 电子邮箱
        this.form.PerEmail = res.data.iEmil
        if (res.data.vat_bank_account != null) {
          this.form.vat_bank_account = res.data.vat_bank_account
        }
        if (res.data.vat_company_address != null) {
          this.form.vat_company_address = res.data.vat_company_address
        }
        if (res.data.vat_remark != null) {
          this.form.vat_remark = res.data.vat_remark
        }
      })
    },

    // 更新修改发票信息
    updateinvoice () {
      if (this.form.LookUpType == 1) {
        // this.form.InvoiNum = '请您务必正确填写单位税号'
        this.InvoTitCompan = false
        this.$refs.Openinvoice.validate(valid => {
          if (valid) {
            this.httphelper.post('/api/InvoiceApi/EditInvoiceInfo', {
              userId: Number(this.AuthorId),
              id: Number(this.$route.query.id),
              // 发票类型
              invoiceType: Number(this.form.InvoiType),
              // 抬头类型 
              iHerdType: Number(this.form.LookUpType),
              // 发票抬头
              invoiceHerd: this.form.InvoiTitle,
              // 单位税号
              unitNumber: this.form.InvoiNum,
              // 服务名称
              serviceName: this.form.InvoiCont,
              iEmil: this.form.PerEmail,
              vat_bank_account: this.form.vat_bank_account,
              vat_company_address: this.form.vat_company_address,
              vat_remark: this.form.vat_remark,
            }).then((res) => {
              console.log(res);
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: 'success'
                })
                this.$router.push({
                  path: '/user/userMyInvoice',
                  query: {
                    name: '我的发票'
                  }
                })
              } else if (res.code == 301) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 302) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 303) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 304) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
          }
        })
      } else {
        this.$refs.Openinvoice.validate(valid => {
          if (valid) {
            this.httphelper.post('/api/InvoiceApi/EditInvoiceInfo', {
              userId: Number(this.AuthorId),
              id: Number(this.$route.query.id),
              // 发票类型
              invoiceType: Number(this.form.InvoiType),
              // 抬头类型 
              iHerdType: Number(this.form.LookUpType),
              // 发票抬头
              invoiceHerd: this.form.InvoiTitle,
              // 单位税号
              unitNumber: this.form.InvoiNum,
              // 服务名称
              serviceName: this.form.InvoiCont,
              iEmil: this.form.PerEmail,
              vat_bank_account: this.form.vat_bank_account,
              vat_company_address: this.form.vat_company_address,
              vat_remark: this.form.vat_remark,
            }).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: 'success'
                })
                this.$router.push({
                  path: '/user/userMyInvoice',
                  query: {
                    name: '我的发票'
                  }
                })
              } else if (res.code == 301) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 302) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 303) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              } else if (res.code == 304) {
                this.$message({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
          }
        })
      }
    }
  },
  created () {
    if (this.form.InvoiType == 1 && this.form.LookUpType == 1) {
      this.InvoTitle = true
    } else if (this.form.InvoiType == 1 && this.form.LookUpType == 2) {
      this.InvoTitle = false
      this.InvoTitCompan = true
    }

    this.form.Sumcount = this.$route.query.totalPrice
    this.OrderId = this.$route.query.id
    if (this.$route.query.StartordesId) {
      this.OrderId = this.$route.query.StartordesId
    } else if (this.$route.query.ordesId) {
      var OrderIdArr = []
      OrderIdArr = this.$route.query.ordesId
      this.OrderIdB.push(OrderIdArr.map(item => item.ordesId).join(','))
      this.OrderId = this.OrderIdB.toString()
    }

    // 获取用户id
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id
    }


    if (this.$route.query.type == 'edit') {
      this.getInvoiceInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form {
  width: 88%;
  margin: 0 auto;
  .el-col-12 {
    width: 66%;
    .el-form-item {
      margin-bottom: 13px;
    }
    .el-select {
      width: 64%;
      .el-input {
        width: 100%;
      }
    }
    .el-input {
      width: 64%;
    }
  }
}
.OpenInvoice_cont {
  position: relative;
  padding: 10px 16px 20px 16px;
  .invoic_body_cont {
    width: 100%;
    .plain_invoice {
      margin-top: 20px;
      p {
        position: relative;
        left: -24px;
      }
      p:nth-child(2) {
        margin-top: 15px;
        width: 80%;
        border-top: 1px dotted #ccc;
      }
    }
  }
  .formBtn {
    width: 105px;
    height: 37px;
    background: #e00019;
    border-radius: 4px;
    text-align: center;
    line-height: 37px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 22px;
    margin-left: 83px;
  }
}

@media screen and (max-width: 1010px) {
  ::v-deep .moheader {
    display: none !important;
  }
}
</style>
