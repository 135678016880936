<template>
  <div>
    <div class="gl_title_bar marginBottom10">
      <div class="gl_tb_left">
        <span class="normaltitle">我的订阅</span>
      </div>
    </div>
    <div v-if="userlivesub.length > 0">
      <div class="display_between marginTop20"
           v-for="(item, index) in userlivesub"
           :key="index + '1'">
        <div class="pub_square"
             v-for="(itemx, indexx) in item"
             :key="indexx + '11'"
             @click="meetinginfo(itemx.live_id)">
          <div class="pubs_img">
            <img :src="Date.parse(itemx.start_time) < new Date() && Date.parse(itemx.end_time) > new Date() ? require('../../../assets/img/live/live.png')
                : Date.parse(itemx.end_time) < new Date() && !itemx.isPlayback ? require('../../../assets/img/live/over.png')
                    : Date.parse(itemx.end_time) < new Date() && itemx.isPlayback ? require('../../../assets/img/live/playback.png')
                        : Date.parse(itemx.start_time) > new Date() ? require('../../../assets/img/live/notice.png') : require('../../../assets/img/live/live.png')"
                 style="position: absolute; " />
            <img class="pubs_img"
                 :src="itemx.coverPictureUrl" />
          </div>
          <div class="pubs_body">
            <div class="pubs_title"
                 :title="itemx.title">{{ ellipsis(itemx.title, 17) }}</div>
          </div>
          <div class="display_between"
               v-if="itemx.org_name!=null && itemx.org_name!=''">
            <div class="pubs_tip pubs_body">
              <span style=""><img :src="itemx.orgLogoUrl"
                     width="18px" /></span>
              <span style="position: absolute;color: #000; margin-left: 10px; 
                            line-height: 20px;"
                    :title="itemx.org_name">{{ ellipsis(itemx.org_name, 17) }}</span>
            </div>
          </div>
          <div class="display_between">
            <div class="pubs_tip pubs_body">
              <span>直播时间:</span>
              <span class="normalColor"> {{ formatTime(itemx.start_time, 'YYYY-MM-DD') }} 至 {{
                                formatTime(itemx.end_time, 'YYYY-MM-DD')
                            }}</span>
            </div>
          </div>
        </div>
        <div class="pub_square1"
             v-for="zhw in 3 - item.length"
             :key="zhw"></div>
      </div>
    </div>
    <div v-else>
      <ul class="NoneData">
        <p>
          <span>您还没有订阅过任何直播！</span>
        </p>
      </ul>
    </div>
  </div>
</template>
<script>
import format from '@/assets/unit/unit.js';
import lodash from 'lodash';
export default {
  name: 'userMyMeeting',
  data () {
    return {
      trainmeetinglist: [], //全部会议
      AuthorId: 0,
      userlivesub: []
    };
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id;
    }
    this.getTrainMeetingList();
  },
  methods: {
    /*全国全科会议及培训*/
    getTrainMeetingList () {
      let _this = this;
      this.httphelper.post('LiveTrainApi/GetUserSubLivie', {
        userId: this.AuthorId,
        type: 2,
        page: 1,
        limit: 99999
      })
        .then(res => {
          if (res.code == 200) {
            _this.userlivesub = lodash.chunk(res.data, 3);
          }
        });
    },
    formatTime (datatime, timeformat) {
      return format(datatime, timeformat);
    },
    meetinginfo (liveid) {
      var url = 'https://live.gp519.com/detail?id=' + liveid
      window.open(url, '_blank');
    }
  }
};
</script>
<style>
.pubs_title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  font-weight: bold;
  overflow: hidden;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 块状列表 */
.pub_square {
  width: 300px;
  height: 280px;
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.pub_square1 {
  width: 300px;
  height: 280px;
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04); */
}

.NoneData {
  padding: 40px;
  text-align: center;
  color: #999;
  font-size: 18px;
  letter-spacing: 1px;
}
</style>
