<template>

  <div class="wrap">
    <div class="EC_width">
      <Header />
    </div>
    <user_head />
    <div class="content display_flex EC_width">
      <div class="contentLeftMenu">
        <ul class="newMenu">
          <li :class="{ newMenuActive: activeMenu == index }"
              v-for="(item, index) in menuList"
              @click="tagMenu(item.pathName, index)"
              :key="index">
            <span class="newMenuItem">
              <img v-for="(itemimg, indeximg) in item.img"
                   :key="indeximg + '1'"
                   :src="itemimg"
                   :class="[indeximg == 0 ? 'normalImg' : 'activeImg']" />
              {{ item.title }}
              <a style="margin-left:15%"
                 v-if="item.dropMenu != null && item.dropMenu.length > 0"><img v-if="activeMenu != index"
                     src="../../assets/image/geren/xiangshang.png" /><img v-else-if="activeMenu == index"
                     src="../../assets/image/geren/xiangxia.png" /></a>
            </span>
            <template v-if="item.dropMenu.length > 0">
              <ul class="DropMenu"
                  @click.stop>
                <li :class="{ DropMenuActive: activeDropMenu == indexx }"
                    class="DropMenuItem"
                    v-for="(itemx, indexx) in item.dropMenu"
                    @click="tagDropMenu(itemx.pathName, indexx, itemx)"
                    :key="indexx">
                  <span :class="[itemx.dropMenu != null && itemx.dropMenu.length ? 'inlinestart20' : '']"> {{
                      itemx.title
                  }}</span>
                  <a v-if="itemx.dropMenu != null && itemx.dropMenu.length > 0">
                    <img v-if="activeDropMenu != indexx"
                         src="/image/geren/chixiangshang.png"
                         style="vertical-align:top; margin-top: 12px;" />
                    <img v-else-if="activeDropMenu == indexx"
                         src="/image/geren/chixiangxia.png"
                         style="vertical-align:top; margin-top: 12px;" />
                  </a>
                  <template v-if="itemx.dropMenu != null && itemx.dropMenu.length > 0">
                    <ul :class="[activeDropMenuTowdispaly == true ? 'DropMenuActiveTow' : 'DropMenuTow']"
                        class="DropMenu "
                        style="padding-inline-start: 20px;"
                        @click.stop>
                      <li :class="{ DropMenuActive: activeDropMenuTow == indexxx }"
                          class="DropMenuItem"
                          v-for="(itemxx, indexxx) in itemx.dropMenu"
                          :key="indexxx"
                          @click="tagDropMenuTow(itemxx.pathName, indexxx)">
                        <span v-if="itemxx.isdisplay">{{ itemxx.title }}</span>
                        <span v-else-if="!itemxx.isdisplay">{{ itemxx.title }}</span>
                      </li>
                    </ul>
                  </template>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
      <div class="contentRightMenu">
        <router-view></router-view>
      </div>
    </div>
    <!-- <footer_index></footer_index> -->
  </div>
</template>

<script>
import Header from '@/components/header'
import user_head from '@/components/header/user_head'
// import footer_index from "../home/footer_index"
export default {
  name: 'index',
  data () {
    return {
      tagIndex: 'userinfo',
      menuList: [
        {
          pathName: 'userinfo',
          title: '个人中心',
          img: [require('../../assets/image/menuIcon1.png'), require('../../assets/image/menuActive1.png')],
          dropMenu: []
        },
        {
          pathName: 'userMyCourse',
          title: '我的收藏',
          img: [require('../../assets/image/userclass.png'), require('../../assets/image/userclassAcive.png')],
          dropMenu: []
        },
        {
          pathName: '',
          title: '我的直播',
          img: [require('../../assets/image/geren/live.png'), require('../../assets/image/geren/liveactive.png')],
          dropMenu: [
            { pathName: 'userLive', title: '我的直播' },
            { pathName: 'liveclass', title: '直播课程' },
            { pathName: 'livesub', title: '直播订阅' },
            { pathName: 'livecollect', title: '直播收藏' },
            { pathName: 'liveorder', title: '直播订单' }
          ]
        },
      ],
      activeMenu: 0,
      activeDropMenu: 0,
      activeDropMenuTow: -1,
      activeDropMenuTowdispaly: false
    };
  },
  created () {
    switch (this.$route.name) {
      case 'userinfo':
        this.activeMenu = 0;
        this.activeDropMenu = 0;
        break;
      case 'userMyCourse':
        this.activeMenu = 1;
        this.activeDropMenu = 1;
        break;
    }
  },
  components: {
    Header,
    user_head
  },
  methods: {
    tagContent (index) {
      this.tagIndex = index;
      this.$router.push({ name: this.tagIndex });
    },
    tagMenu (pathName, index) {
      this.activeMenu = index;
      this.activeDropMenuTow = -1;
      this.activeDropMenu = -1;
      this.activeDropMenuTowdispaly = false;
      this.$router.push({ name: pathName });
    },
    tagDropMenu (pathName, index, itemx) {
      if (itemx.dropMenu != null) {
        this.activeDropMenuTowdispaly = true;
        this.activeDropMenuTow = -1;
        this.activeDropMenu = '';
      } else {
        this.activeDropMenuTowdispaly = false;
      }
      this.activeDropMenu = index;
      this.$router.push({ name: pathName });
    },
    tagDropMenuTow (pathName, index) {
      this.activeDropMenuTow = index;
      this.$router.push({ name: pathName });
    }
  },
};
</script>

<style scoped>
.activeImg {
  display: none;
}

.pubL_menu_active .activeImg {
  display: inline-block;
}

.pubL_menu_active .normalImg {
  display: none;
}

.wrap {
  min-height: 800px;
  display: flex;
  flex-direction: column;
}

/* 左边菜单部分 */
.pubL_menu {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 184px;
}

.pubL_menu li {
  position: relative;
}

.pubL_menu li a {
  display: inline-block;
  height: 46px;
  width: 138px;
  text-align: center;
  line-height: 46px;
}

.pubL_menu li a img {
  float: left;
  margin-left: 30px;
  margin-top: 15px;
}

.arrtig {
  position: absolute;
  width: 0;
  height: 0;
  border: solid 23px;
  border-color: transparent transparent transparent #0b8686;
  right: 0;
  top: 0;
  display: none;
}

.pubL_menu_active a {
  background: #0b8686;
  color: #fff;
  border-radius: 6px 0 0 6px;
}

.pubL_menu_active .arrtig {
  display: block;
}

.content {
  flex: 1;
  padding-top: 40px;
  padding-bottom: 40px;
}

.contentLeftMenu {
  padding-top: 40px;
}

.contentRightMenu {
  margin-left: 67px;
  flex: 1;
}

/*新菜单*/
.newMenu {
  width: 200px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.newMenuItem {
  display: inline-block;
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.newMenuItem img {
  vertical-align: -3px;
  margin-right: 10px;
  width: 20px;
  height: 18px;
}

.newMenuActive .DropMenu {
  max-height: 600px;
}

.newMenuActive .activeImg {
  display: inline-block;
}

.newMenuActive .normalImg {
  display: none;
}

.newMenuActive .newMenuItem {
  background: #0b8586;
  border-radius: 5px;
  color: #fff;
}

.DropMenu {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
}

.DropMenuItem {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  cursor: pointer;
}

.DropMenuActive {
  color: #0b8586;
}

.DropMenuTow {
  display: none;
}

.DropMenuActiveTow {
  display: block;
}

.inlinestart20 {
  padding-inline-start: 20px;
}
</style>

<style lang="scss" scoped>
.EC_width {
  width: 1250px;
}

@media only screen and (max-width: 1920px) {
  .EC_width {
    width: 78%;
  }
}
</style>
