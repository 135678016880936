<template>
  <div>
    <!-- 支付弹框 -->
    <pay-cont v-if="PayContShow"
              :CourseInfo="CourseInfo"
              :type="3"
              @closePay="closePay"
              :payType="2"></pay-cont>
    <div class="gl_title_bar marginBottom10">
      <div class="gl_tb_left">
        <div>
          <span class="normaltitle"
                :style="vipType==1?'color:#0b8586;cursor: pointer;':'cursor: pointer;border-bottom:none'"
                @click="vipType=1">SVIP中心</span>
          <span style="margin:0 10px">/</span>
          <span class="normaltitle"
                :style="vipType==2?'color:#0b8586;cursor: pointer;':'cursor: pointer;border-bottom:none'"
                @click="vipType=2">VIP中心</span>
        </div>
      </div>
    </div>
    <div class="display_between"
         v-show="VIPrecharge"
         v-if="vipType==1">
      <div v-for="(item, index) in VIchargedata"
           :key="index + '11'"
           :class="[index == 0 ? 'block bg1 display_center' : index == 1 ? 'block bg2 display_center' : 'block bg3 display_center']">
        <div style="position: relative;top: 54px;">
          <p class="text_center marginBottom20 fontBold">
            <span class="fontsize18">￥</span><span style="font-size: 40px;">{{ item.disMember }}</span>
          </p>
          <p class="text_center fontsize18 marginBottom10">
            <a v-if="index == 0">SVIP年卡</a>
            <a v-if="index == 1">SVIP半年卡</a>
            <a v-if="index == 2">SVIP季卡</a>
          </p>
          <p class="text_center fontsize18">
            <a v-if="index == 0">全站资源免费观看1年权益</a>
            <a v-if="index == 1">全站资源免费观看半年权益</a>
            <a v-if="index == 2">全站资源免费观看3个月权益</a>
          </p>
          <p class="text_center marginTop30"><a href="javascript:void(0);"
               @click="UpPayAler(item)"
               class="blockBtn">升级</a></p>
        </div>
      </div>
    </div>
    <div class="display_between"
         v-show="VIPrecharge"
         v-if="vipType==2">
      <div v-for="(item, index) in VIchargedata"
           :key="index + '11'"
           :class="[index == 0 ? 'block bg4 display_center' : index == 1 ? 'block bg5 display_center' : 'block bg6 display_center']">
        <div style="position: relative;top: 54px;">
          <!-- <p class="text_center marginBottom20 fontBold">
            <span class="fontsize18">￥</span><span style="font-size: 40px;">{{ item.disMember }}</span>
          </p> -->
          <p class="text_center fontsize18 marginBottom50"
             style="font-weight:800">
            <a v-if="index == 0">VIP年卡</a>
            <a v-if="index == 1">VIP半年卡</a>
            <a v-if="index == 2">VIP季卡</a>
          </p>
          <p class="text_center fontsize16">
            <a v-if="index == 0">VIP专项课程免费观看1年权益</a>
            <a v-if="index == 1">VIP专项课程免费观看半年权益</a>
            <a v-if="index == 2">VIP专项课程免费观看3个月权益</a>
          </p>
          <!-- <p class="text_center marginTop30"><a href="javascript:void(0);"
               @click="UpPayAler(item)"
               class="blockBtn">升级</a></p> -->
        </div>
      </div>
    </div>
    <div class="botxy"
         v-if="vipType==1">
      <span>升级前请阅读</span>
      <span @click="dialogVisible=true"
            style="color:#0B8586;cursor: pointer;">《全科医学网【全科知家】全卡SVIP、知卡VIP会员服务协议》</span>
    </div>
    <div class="botxy"
         v-if="vipType==2">
      <span>注：知卡VIP会员目前通过全科医学网【全科知家】平台赠与会员权限或未来新增的支付渠道等方式成为会员；</span>
    </div>
    <el-dialog title="全科医学网【全科知家】全卡SVIP、知卡VIP会员服务协议"
               :visible.sync="dialogVisible"
               width="800px">
      <div class="botxynr">
        <p>【首部及导言】</p>
        <p>欢迎您使用全科医学网【全科知家】全卡SVIP、知卡VIP会员服务协议！</p>
        <p>为使用全科医学网【全科知家】全卡SVIP、知卡VIP会员服务（简称为：本服务），您应当阅读并遵守《全科医学网【全科知家】全卡SVIP、知卡VIP会员服务协议》（简称为：本协议）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。免除或限制责任条款可能以加粗等形式提示您注意。</p>
        <p>除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您对本服务的任何购买或接受赠与等获取行为及登录、查看、收听、等任何使用行为即视为您已阅读并同意本协议的约束。</p>
        <p><br></p>
        <p>一、【协议的范围】</p>
        <p>1.1【协议适用主体范围】</p>
        <p>关于用户成为全科医学网【全科知家】全卡SVIP、知卡VIP会员且使用全科医学网提供的会员服务所订立的协议。“全卡SVIP、知卡VIP会员”或“您”是指享受全科医学网提供的全卡SVIP、知卡VIP会员服务的个人或单一实体。</p>
        <p>1.2【协议更新】全科医学网有权随时对服务条款进行更改，一旦服务条款发生变更或修改，将在全科医学网【全科知家】的相关网站或APP客户端页面进行公告，如果全卡SVIP、知卡VIP会员不同意本协议的任一修改，可以取消已经获取的全卡SVIP、知卡VIP会员服务并停止使用，如果全卡SVIP、知卡VIP会员继续使用全科医学网【全科知家】的全卡SVIP、知卡VIP会员服务，则视为全卡SVIP、知卡VIP会员已接收本协议的全部修改。</p>
        <p><br></p>
        <p>二、【服务内容】</p>
        <p>2.1【全卡SVIP服务】</p>
        <p>您在开通本会员服务后，将享有专属身份标识，具体的身份标识信息以全科医学网【全科知家】实际服务页面显示的为准；可享受在全科医学网【全科知家】APP内所有VIP专项内容及单独付费内容的畅听服务；</p>
        <p>2.2【知卡VIP服务】</p>
        <p>您在开通本会员服务后，将享有专属身份标识，具体的身份标识信息以全科医学网【全科知家】实际服务页面显示的为准；可享受在全科医学网【全科知家】APP内所有VIP专项内容的畅听服务；</p>
        <p>您理解并同意：上述会员服务内容以全科医学网【全科知家】官方网站或APP客户端公布的会员服务内容为准，全科医学网有权基于自身业务发展需要变更全部或部分会员服务内容，就签署服务内容的变更会在相应的服务页面进行通知或者公告，您也可以通过全科医学网【全科知家】官方那个网站查询最新的会员权益。</p>
        <p>您通过全科医学网指定渠道开通本服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及其他第三方原因等导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，并不会因此向全科医学网提出任何主张或追究全科医学网的任何责任。</p>
        <p><br></p>
        <p>三、权利义务</p>
        <p>3.1【关于收费】</p>
        <p>本服务是全科医学网提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。此外，您可能也可以通过接受好友赠送等全科医学网认可的其他方式享有、使用本服务。</p>
        <p>您理解并同意：全科医学网可能会根据本服务的整体规划，对本服务相关权益细则、收费标准、续费方式等进行修改和变更，前述修改、变更，以及对不同阶段充值、续费的全卡SVIP的具体收费规则以全科医学网【全科知家】相关服务页面公示的信息为准。您若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。</p>
        <p>3.2【服务开通】</p>
        <p>3.2.1您应该通过全科医学网已有的和未来新增的渠道成为全科医学网【全科知家】全卡SVIP会员，包括但不限于：通过支付宝、微信或未来新增的支付渠道等方式成为会员，在依约支付一定费用后开通本服务。本服务开通之后，全卡SVIP会员仅限于申请账户主体自行使用，全卡SVIP会员服务期限内不可转移、赠与、借用、租用、转让、售卖或退订。全科医学网在此声明：任何未经全科医学网书面授权销售全科医学网【全科知家】全卡SVIP会员的属于非法销售，全科医学网有权追究其法律责任。</p>
        <p>3.2.2知卡VIP会员目前通过全科医学网【全科知家】平台赠与会员权限或未来新增的支付渠道等方式成为会员；</p>
        <p>本服务开通之后，不可进行转让。同时，您不得通过以下任何方式为自己或他人开通本服务：</p>
        <p>（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；</p>
        <p>（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷数据软件等任何程序、软件方式为自己或他人开通本服务；</p>
        <p>（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；</p>
        <p>（4）通过非全科医学网指定的方式为自己或他人开通本服务；</p>
        <p>（5）通过侵犯全科医学网或他人合法权益的方式为自己或他人开通本服务；</p>
        <p>（6）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。</p>
        <p>特别提示：
        <p>（1）开通本全卡SVIP、知卡VIP会员服务后，您可以在计算机及移动电话等手持移动终端设备，下同）上使用您的会员账号。超过上述范围使用的，全科医学网有权中止或终止对您的服务并取消全卡SVIP、知卡VIP会员资格，您应自行承担超范围使用而导致的任何损失。同时全科医学网保留追究上述超范围使用行为人法律责任的权利。
        </p>
        <p>（2）您明确了解并同意，全科医学网【全科知家】全卡SVIP会员的付费方式是代收运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险，包括但不限于不法分子利用您的账户或银行卡进行消费的行为，该等违法行为均会给您造成相应的经济损失，您应自行承担向侵权方追究侵权责任以及追究责任不能的后果。支付完成后不可转让或退订。</p>
        <p>3.3【服务期限】</p>
        <p>3.3.1本服务的服务期限以您自行选择并支付相应服务费用的期限为准，您也可以登陆全科医学网充值中心或者本服务的相应页面查询。会员服务到期后，会员服务月期间提供的所有服务，非会员用户将不再享有。特别声明：成为全卡SVIP、知卡VIP会员后，全卡SVIP、知卡VIP会员有权利选择不继续接受全科医学网【全科知家】的VIP服务，但取消会员服务行为无法获得已支付的全卡SVIP、知卡VIP会员服务费退还。</p>
        <p>3.3.2本服务的服务开通最高期限会受到一定限制，具体以全科医学网制定的的规则为准。</p>
        <p>3.4【行为规范】</p>
        <p>3.4.1【五不准】</p>
        <p>您在使用本服务时不得利用本服务从事以下行为，包括但不限于：</p>
        <p>（1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；</p>
        <p>（2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</p>
        <p>（3）虚构事实、隐瞒真相以误导、欺骗他人；</p>
        <p>（4）发表、传送、传播广告信息及垃圾信息；</p>
        <p>（5）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
        <p>3.4.2【用户禁止行为】</p>
        <p>本服务仅供您个人使用，全科医学网提供的会员服务不得用于商业、盈利用途。除非经全科医学网书面许可，您不得进行以下行为：</p>
        <p>（1）将本服务再次许可他人使用，并允许他人通过您的账户收听、下载非他人付费购买的会员服务提供的音频作品；</p>
        <p>（2）将会员服务提供的音频作品进行复制、下载、修改、翻译、开发、传播、销售、出租或授权给任何第三方；</p>
        <p>（3）通过本服务发布包含广告、宣传、促销等内容的信息；</p>
        <p>（4）通过任何方式搜集本服务中其他用户的用户名、用户账户、电子邮件、手机号码等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户损害第三方利益，包括但不限于隐私权、知识产权、财产权等；</p>
        <p>（5）利用会员服务提供的音频进行创作衍生作品、进行教学或研究、进行商业开发或推广等；</p>
        <p>（6）利用非法手段对会员账户的服务期限、消费金额、交易状态进行更改，或用非法的方式或非法的目的使用已购买的会员服务提供的音频；</p>
        <p>（7）其他未经全科医学网书面许可的行为。</p>
        <p>如您有上述行为，全科医学网有权在未经通知的情况下取消相应的账户，并取消受转让账户的全卡SVIP、知卡VIP会员服务资格，由此带来的损失，由您自行承担。</p>
        <p>3.4.3【对自己行为负责】</p>
        <p>您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括但不限于您所发表的任何内容（不限于图片、音频、文字等形式）以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。全科医学网向全科医学网【全科知家】全卡SVIP、知卡VIP会员提供的全部服务，均仅限于全科医学网【全科知家】全卡SVIP、知卡VIP会员在全科医学网【全科知家】平台内使用，任何以恶意破解等非法手段将全科医学网【全科知家】平台提供的内容与全科医学网【全科知家】平台分离的行为，均不属于本协议中约定的服务，由此产生的一切法律后果有行为人自行负责，与全科医学网无关，且全科医学网有权追究行为人的责任。</p>
        <p>3.5【服务的变更、中止或终止】</p>
        <p>3.5.1全科医学网将依据本协议的约定变更服务内容、服务方式以及本会员服务协议内容，如果会员不同意全科医学网的变更，有权取消并停止使用已经获取的对应的全部或部分服务；如果会员继续使用全科医学网提供的上述服务，则视为会员已经接受全科医学网的上述调整。</p>
        <p>3.5.2您充分了解并同意，由于互联网服务的特殊性或不可抗拒的事由，如政府行为、不可抗力、导致全卡SVIP、知卡VIP会员服务无法继续，全科医学网会按照相关法规、双方约定或在其他必要时，中止或终止向您提供本服务，并以最快的方式通知全卡SVIP、知卡VIP会员，但不承担由此对全卡SVIP、知卡VIP会员造成的任何损失并不退还全卡SVIP、知卡VIP会员服务费。</p>
        <p><br></p>
        <p>四、【违约责任】</p>
        <p>4.1如果全科医学网发现或收到他人举报您有违反本协议任何行为的，全科医学网有权依法进行独立判断并采取技术手段予以删除、屏蔽、下架，对于用户账号实施禁言、撤V、封号等处理措施。同时，全科医学网有权视您的行为性质，对您采取包括但不限于暂停或终止部分或全部本服务、中止或终止您对全科医学网用户账户的使用、追究您法律责任等措施，全科医学网也无需向您退还任何费用，而由此给您带来的损失（包括但不限于通信中断、相关数据清空、未使用的服务费用作为违约金而归全科医学网所有等），由您自行承担，造成全科医学网损失的，您也应予以赔偿。</p>
        <p>4.2【对第三方损害的处理】</p>
        <p>您有违反本协议任何行为，导致任何第三方损害的，您应当独立承担责任；全科医学网因此遭受损失的，您也应当一并赔偿。</p>
        <p><br></p>
        <p>五、【其他】</p>
        <p>5.1【协议的生效】</p>
        <p>您使用本服务即视为您已阅读并同意受本协议的约束。</p>
        <p>5.2【适用法律即条款效力】</p>
        <p>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
        <p>5.3【争议解决】</p>
        <p>若您和全科医学网之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</p>
        <p>5.4【条款标题】</p>
        <p>本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PayCont from '@/views/user/payCont';
export default {
  name: 'userVip',
  components: {
    PayCont
  },
  data () {
    return {
      AuthorId: '',
      // 支付弹框
      PayContShow: false,
      // 判断是否充值过vip
      Vipdate: '',
      // vip充值
      VIPrecharge: true,
      // VIP获取记录
      VIPexhibition: false,
      // vip充值数据
      VIchargedata: [],
      // vip获取记录数据
      VipgetData: [],
      // 到期操作
      VipEdidate: {},
      dialogVisible: false,
      vipType: 1
    };
  },
  created () {
    this.getVIchargedata();
  },
  methods: {
    // 拉起支付弹框
    UpPayAler (item) {
      this.PayContShow = true;
      this.CourseInfo = item;
    },
    closePay () {
      console.log(1)
      this.PayContShow = false
    },
    // 立即续费充值
    immedrenaw () {
      this.VIPexhibition = false;
      this.VIPrecharge = true;
    },
    // vip充值商品
    getVIchargedata () {
      this.httphelper.get('CGpUserApi/GetVipCommList').then(res => {
        this.VIchargedata = res.data;
      });
    },
    // 获取vip获取记录
    getVipRecode () {
      this.httphelper
        .post('CGpUserApi/GetVipDetails', {
          authorId: this.AuthorId
        })
        .then(res => {
          if (res.data) {
            this.VipEdidate = res.data;
            this.VipgetData = res.data.vipreclist;
          }
        });
    }
  }
};
</script>

<style scoped>
.block {
  width: 297px;
  height: 424px;
}
.bg1 {
  background: url("../../assets/image/geren/hbg11.png") center no-repeat;
  color: #5b3e09;
}
.bg2 {
  background: url("../../assets/image/geren/hbg21.png") center no-repeat;
  color: #393939;
}
.bg3 {
  background: url("../../assets/image/geren/hbg31.png") center no-repeat;
  color: #542b05;
}
.bg4 {
  background: url("../../assets/image/geren/hbg1.png") center no-repeat;
  color: #5b3e09;
}
.bg5 {
  background: url("../../assets/image/geren/hbg2.png") center no-repeat;
  color: #393939;
}
.bg6 {
  background: url("../../assets/image/geren/hbg3.png") center no-repeat;
  color: #542b05;
}
.blockBtn {
  display: inline-block;
  width: 130px;
  height: 35px;
  background: #785513;
  border-radius: 17px;
  text-align: center;
  line-height: 35px;
  color: #fff;
}
.btnColor2 {
  background: #5c5c5c;
}
.btnColor3 {
  background: #9d6430;
}
.botxy {
  border-top: 1px solid #eee;
  padding: 10px 0;
  font-size: 14px;
  margin-top: 10px;
}
.botxynr {
  font-size: 14px;
}
.botxynr p {
  text-indent: 2em;
}
</style>
