<template>
  <div class="container">
    <div class="title">科研专家</div>
    <div class="content">遇到科研难题和挑战，向谁求助？——多位科研领域专家大咖，为您答疑解惑，助您勇攀高峰</div>
    <div class="swiper">
      <!-- <swiper :options="swiperOption">
        <swiper-slide v-for="(item, index) in list"
                      :key="index">
          <img :src="item.headPortraitUrl"
               alt=""
               class="img">
          <div class="name">{{ item.name }} <span style="margin: 0 5px;">/</span> {{ item.techTitle }}</div>
          <div class="address">{{ item.unit }}</div>
          <div class="bottom">
            <div class="introduce"
                 @click="seeDetail(item)">专家简介</div>
            <div class="question"
                 @click="problem(item)">向专家提问</div>
          </div>
        </swiper-slide>
      </swiper> -->
      <div class="list">
        <div class="list-item"
             v-for="(item, index) in list">
          <div class="img-div"
               :class="(index == 0 || index == 4) ? 'xiao' : (index == 1 || index == 3) ? 'zhong' : ''">
            <img :src="item.headPortraitUrl"
                 alt=""
                 class="img">
          </div>
          <div class="name">{{ item.name }} <span style="margin: 0 5px;">/</span> {{ item.techTitle }}</div>
          <div class="address">{{ item.unit }}</div>
          <div class="bottom">
            <div class="introduce"
                 @click="seeDetail(item)">专家简介</div>
            <div class="question"
                 @click="problem(item)">向专家提问</div>
          </div>
        </div>
        <div class="t5prevbutton el-icon-arrow-left"
             @click="prev"></div>
        <div class="t5nextbutton el-icon-arrow-right"
             @click="next"></div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible"
               width="680px"
               :before-close="handleClose"
               :append-to-body="true">
      <div class="body">
        <div class="body-left">
          <img :src="model.headPortraitUrl"
               class="logo"
               alt="">
          <div class="tname">{{ model.name }} <span style="margin: 0 5px;">/</span> {{ model.techTitle }}</div>
          <div class="taddress">{{ model.unit }}</div>
        </div>
        <div class="body-right">
          {{ model.detailsInfo }}
        </div>
      </div>
    </el-dialog>

    <!-- 向专家提问 -->
    <el-dialog title="向专家提问"
               :visible.sync="problemdVisible"
               width="680px"
               center
               :before-close="handleClose"
               :append-to-body="true">
      <div class="main">
        <div>
          <el-input type="textarea"
                    placeholder="请输入内容"
                    v-model="params.leaveMessage"
                    maxlength="200"
                    show-word-limit>
          </el-input>
        </div>
        <div style="margin-top: 20px; display: flex; align-items: center;">
          <div>上传图片</div>
          <webupload fileaccept=".jpg,.jpeg,.png"
                     :Maxfileszie="3"
                     :uploadtype="1"
                     :orgid="0"
                     :onSuccess="certificateHandleSuccess"
                     :before-upload="beforeAvatarUpload"
                     style="margin-left: 20px;">
            <img v-if="params.questionOneUrl"
                 :src="params.questionOneUrl"
                 class="avatarImg" />
            <div v-else
                 class="avatar">+</div>
          </webupload>

          <webupload fileaccept=".jpg,.jpeg,.png"
                     :Maxfileszie="3"
                     :uploadtype="1"
                     :orgid="0"
                     :onSuccess="certificateHandleSuccess2"
                     :before-upload="beforeAvatarUpload"
                     style="margin-left: 20px;">
            <img v-if="params.questionTwoUrl"
                 :src="params.questionTwoUrl"
                 class="avatarImg" />
            <div v-else
                 class="avatar">+</div>
          </webupload>
          <div style="margin-left: 20px; color: #B6B6B6;">最多上传2张照片</div>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="problemdVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import webupload from '@/components/webupload/webupload.vue'
import { Dialog } from 'vant';
import 'vant/lib/index.css'
export default {
  components: { webupload },
  data () {
    return {
      dialogVisible: false,
      problemdVisible: false,
      swiperOption: {
        spaceBetween: 30,
        slidesPerView: 5,
        centeredSlides: true,
        slideToClickedSlide: true,
        loop: true,
        on: {
          tap: function () {
            console.log('点击的位置', this.activeIndex)
          }
        }
      },
      model: {},
      list: [],

      params: {
        expertId: '',
        userId: 0,
        leaveMessage: '',
        questionOneUrl: '',
        questionOneId: 0,
        questionTwoUrl: '',
        questionTwoId: 0,
      },

      pageIndex: 1,
      baseUrl: this.httphelper.IsDebugGP519(),
    }
  },

  created () {
    if (localStorage.getItem('ResData')) {
      this.id = JSON.parse(localStorage.getItem('ResData')).id
      this.params.userId = JSON.parse(localStorage.getItem('ResData')).id
    }
    this.getList()
  },

  methods: {
    handleClose () {
      this.dialogVisible = false
      this.problemdVisible = false
    },

    seeDetail (item) {
      this.dialogVisible = true
      this.model = item
    },

    problem (item) {
      if (localStorage.getItem('ResData')) {
        this.problemdVisible = true
        this.params.expertId = item.id
      } else {
        this.DialogLogin()
      }

    },

    getList () {
      let params = {
        page: this.pageIndex,
        limit: 5
      }
      this.httphelper.post('/ScientificApi/GetHotspotResearchExpertList', params).then(res => {
        if (res.code == 200 && res.data.length > 0) {
          this.list = res.data
        } else {
          this.pageIndex = this.pageIndex - 1
        }
      })
    },

    next () {
      this.pageIndex = this.pageIndex + 1
      this.getList()
    },

    prev () {
      if (this.pageIndex == 1) return
      this.pageIndex = this.pageIndex - 1
      this.getList()
    },

    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },

    certificateHandleSuccess (response) {
      this.params.questionOneUrl = response.ossfileurl;
      this.params.questionOneId = response.resid
    },

    certificateHandleSuccess2 (response) {
      this.params.questionTwoUrl = response.ossfileurl;
      this.params.questionTwoId = response.resid
    },

    submit () {
      this.httphelper.post('/ScientificApi/ExpertQuestionApply', this.params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.problemdVisible = false
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },

    DialogLogin () {
      Dialog.confirm({
        title: '系统提示',
        message: '请先登录',
      })
        .then(() => {
          this.confirm();
        })
    },

    // 点击收藏去登陆
    confirm () {
      let callback = encodeURIComponent(this.$unit.delUrlData('token'));
      window.location.href = this.baseUrl + '/loginIndex/login?type=3&callback=' + callback;
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/banner9.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 220px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
  .content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-top: 18px;
  }
  .swiper {
    width: 70%;
    margin: 90px auto;
    .list {
      display: flex;
      justify-content: space-between;
      align-items: end;
      position: relative;
      .list-item {
        width: 25%;
        .img-div {
          width: 250px;
          height: 250px;
          border-radius: 50%;
          margin: 0 auto;
          padding: 10px;
          box-shadow: 10px 17px 20px 0px rgba(0, 0, 0, 0.07);
          .img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .xiao {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          margin-bottom: 60px;
        }
        .zhong {
          width: 190px;
          height: 190px;
          border-radius: 50%;
          margin-bottom: 40px;
        }
        .img-div:hover {
          border: 1px solid #006773;
        }

        .name {
          width: 100%;
          text-align: center;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1e1e1e;
          margin-top: 24px;
        }
        .address {
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1e1e1e;
          margin-top: 14px;
        }
        .bottom {
          margin-top: 23px;
          display: flex;
          justify-content: center;
          .introduce {
            width: 90px;
            height: 30px;
            background: #fcf6f6;
            border: 1px solid #d6030c;
            border-radius: 3px;
            margin-right: 5px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #d6030c;
            line-height: 30px;
            cursor: pointer;
            text-align: center;
          }
          .question {
            width: 90px;
            height: 30px;
            background: #dff3f4;
            border: 1px solid #0b8686;
            border-radius: 3px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8686;
            line-height: 30px;
            cursor: pointer;
            text-align: center;
          }
          .introduce:hover {
            background: #d6030c;
            border: 1px solid #d6030c;
            color: #fff;
          }
          .question:hover {
            background: #0b8686;
            border: 1px solid #0b8686;
            color: #fff;
          }
        }
      }
      .el-icon-arrow-left,
      .el-icon-arrow-right {
        width: 37px;
        height: 37px;
        text-align: center;
        line-height: 37px;
        border-radius: 50%;
        font-size: 22px;
        font-weight: bold;
        color: #0b8586;
        background: #fff;
        border: 1px solid #0b8586;
        cursor: pointer;
      }
      .el-icon-arrow-left {
        position: absolute;
        top: 130px;
        left: -35px;
        z-index: 9;
      }
      .el-icon-arrow-right {
        position: absolute;
        top: 130px;
        right: -35px;
        z-index: 9;
      }
      .el-icon-arrow-left:hover {
        color: #fff;
        background: #0b8586;
      }
      .el-icon-arrow-right:hover {
        color: #fff;
        background: #0b8586;
      }
      // .t5-button-disabled {
      //   color: #0b8586;
      //   background: #fff;
      //   border: 1px solid #0b8586;
      // }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.7);
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    // background-color: aqua;
    transform: scale(1);
  }
}
.body {
  width: 100%;
  height: 380px;
  display: flex;
  .body-left {
    width: 30%;
    height: 100%;
    .logo {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      margin: 0 auto;
      display: block;
    }
    .tname {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin-top: 23px;
    }
    .taddress {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      opacity: 0.8;
      margin-top: 12px;
    }
  }
  .body-right {
    width: calc(70% - 52px);
    height: 100%;
    text-indent: 2em;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
    padding: 0 26px;
  }
}

.main {
  width: 100%;
  height: 380px;
  .avatar {
    width: 100px;
    height: 100px;
    background: #f5f7fa;
    border: 1px solid #e4e7e8;
    font-size: 60px;
    text-align: center;
    line-height: 87px;
  }
  .avatarImg {
    width: 100px;
    height: 100px;
  }
}

// @media screen and (max-width: 1900px) {
//   .container {
//     padding-top: 130px;
//     .title {
//       font-size: 28px;
//       margin-top: 40px;
//     }
//     .main {
//       margin: 30px auto;
//     }
//   }
//   .container .swiper {
//     width: 80%;
//     margin: 30px auto;
//   }
//   .container .swiper-slide .name {
//     font-size: 16px;
//   }
// }
</style>