var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),(_vm.userlivecollect.length > 0)?_c('div',_vm._l((_vm.userlivecollect),function(item,index){return _c('div',{key:index + '1',staticClass:"display_between marginTop20"},[_vm._l((item),function(itemx,indexx){return _c('div',{key:indexx + '11',staticClass:"pub_square",on:{"click":function($event){return _vm.liveinfo(itemx.live_id)}}},[_c('div',{staticClass:"pubs_img"},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":Date.parse(itemx.start_time) < new Date() && Date.parse(itemx.end_time) > new Date() ? require('../../../assets/img/live/live.png')
              : Date.parse(itemx.end_time) < new Date() && !itemx.isPlayback ? require('../../../assets/img/live/over.png')
                  : Date.parse(itemx.end_time) < new Date() && itemx.isPlayback ? require('../../../assets/img/live/playback.png')
                      : Date.parse(itemx.start_time) > new Date() ? require('../../../assets/img/live/notice.png') : require('../../../assets/img/live/live.png')}}),_c('img',{staticClass:"pubs_img",attrs:{"src":itemx.coverPictureUrl}})]),_c('div',{staticClass:"pubs_body"},[_c('div',{staticClass:"pubs_title",attrs:{"title":itemx.title}},[_vm._v(_vm._s(_vm.ellipsis(itemx.title, 17)))])]),(itemx.org_name!=null && itemx.org_name!='')?_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',{},[_c('img',{attrs:{"src":itemx.orgLogoUrl,"width":"18px"}})]),_c('span',{staticStyle:{"position":"absolute","color":"#000","margin-left":"10px","line-height":"20px"},attrs:{"title":itemx.org_name}},[_vm._v(_vm._s(_vm.ellipsis(itemx.org_name, 17)))])])]):_vm._e(),_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',[_vm._v("开直播时间:")]),_c('span',{staticClass:"normalColor"},[_vm._v(" "+_vm._s(_vm.formatTime(itemx.start_time, 'YYYY-MM-DD'))+" 至 "+_vm._s(_vm.formatTime(itemx.end_time, 'YYYY-MM-DD')))])])]),_c('div',{staticClass:"display_between",staticStyle:{"float":"right"},on:{"click":function($event){$event.stopPropagation();return _vm.collection(itemx.live_id)}}},[_vm._m(1,true)])])}),_vm._l((3 - item.length),function(zhw){return _c('div',{key:zhw,staticClass:"pub_square1"})})],2)}),0):_c('div',[_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl_title_bar marginBottom10"},[_c('div',{staticClass:"gl_tb_left"},[_c('span',{staticClass:"normaltitle"},[_vm._v("我的收藏")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pubs_body",staticStyle:{"padding-top":"10px"}},[_c('div',{staticStyle:{"border":"1px red solid","border-radius":"5px","width":"80px","line-height":"25px","font-size":"14px","text-align":"center","background-color":"red","color":"#fff"}},[_c('span',[_vm._v("取消收藏")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"NoneData"},[_c('p',[_c('span',[_vm._v("您还没有收藏过任何直播！")])])])
}]

export { render, staticRenderFns }