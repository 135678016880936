export default {
  /**
   * 校验文件大小
   * @param val
   */
  validateFileSize(val, size = 10) {
    return val < size * 1024 * 1024;
  },

  /**
   * 校验图片类型
   * @param val
   * @returns {boolean}
   */
  validateImageType(val) {
    return val === 'image/png' || val === 'image/jpeg' || val === 'image/gif';
  },

  /**
   * 校验登录名是否合法
   * @param val
   * @returns {boolean}
   */
  validateUsername(val) {
    const reg = /^[a-zA-Z0-9_-]{2,14}$/;
    return reg.test(val);
  },

  /**
   * 校验登录密码是否合法
   * @param val
   * @returns {boolean}
  */
  
  validatePassword(val) {
    // const reg = /^[\w\s~!@%#$^*+='?\-\\/(){}\[\],.\|《》、，。！{}·#￥……*（）——:：“”？【】；‘’`_;\"]{9,20}$/;

    // 所有 + 特殊符号
    // const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,.\/]).{6,20}$/;
    // 所有 + 特殊符号
    const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.).{6,20}$/;
    // const reg = /(?!^\\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,}/
    return reg.test(val);
  },

  /**
   * 校验电话是否合法（包括手机号）
   * @param val
   * @returns {boolean}
   */
  validatePhone(val) {
    const reg = /^(1\d{10}|[0-9-—]{7,20})$/;
    return reg.test(val);
  },

  /**
   * 校验手机号是否合法
   * @param {*} val
   */
  validateMobile(val) {
    const reg = /^1\d{10}$/;
    return reg.test(val);
  },

  /**
   * 校验邮箱是否合法
   * @param {*} val
   */
  validateEmail(val) {
    const reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
    return reg.test(val);
  },

  /**
   * 校验身份证号码是否合法
   * @param {*} val
   */
  validateIdCode(val) {
    const reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    return reg.test(val);
  },

  /**
   * 校验URL是否合法
   * @param {*} val
   */
  validateUrl(val) {
    const reg = /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i;
    return reg.test(val);
  },

  /**
   * 校验路由是否合法
   * @param {*} val
   */
  validateRouter(val) {
    const reg = /^\/+/;
    return reg.test(val);
  },

  /**
   * 校验金额是否合法
   * @param {*} val
   */
  validateAmount(val) {
    const reg = /^[0-9]{1,8}(\.[0-9]{1,2})?$/;
    return reg.test(val);
  },

  /**
   * 校验整数是否合法
   * @param {*} val
  */
  validateInteger(val) {
    const reg = /^-?[0-9]\d*$/;
    return reg.test(val);
  },

  /**
   * 校验浮点数是否合法
   * @param {*} val
   */
  validateFloat(val) {
    const reg = /^(-?\d+)(\.\d+)?$/;
    return reg.test(val);
  }
};
