<template>
  <div class="container">
    <div class="title">量表工具</div>
    <div class="content">到处找量表、找工具，苦不堪言？——“量表工具箱”，助您一站式找到最全医学科研量表和工具</div>
    <div class="main">
      <div class="main-right-bottom">
        <swiper :options="option">
          <swiper-slide v-for="(item, index) in list"
                        :key="index">
            <div class="body">
              <div class="name">{{ item.name }}</div>
              <div class="line"></div>
              <div class="text-body">
                <div class="text"
                     v-if="item.child"
                     v-for="(key, index) in item.child"
                     @click="itemClick(key)"
                     :key="key.id">
                  {{ key.title }}
                </div>
              </div>
              <div class="sl">......</div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="t5prevbutton el-icon-arrow-left"></div>
        <div class="t5nextbutton el-icon-arrow-right"></div>
      </div>
    </div>
    <div class="more"
         v-if="list.length > 0"
         @click="toMore">查看更多工具</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      option: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 20,
        navigation: {
          nextEl: '.t5nextbutton',
          prevEl: '.t5prevbutton',
          disabledClass: 't5-button-disabled'
        }
      },
      list: []
    }
  },

  created () {
    this.getColumn()
  },

  methods: {
    // 获取栏目
    getColumn () {
      let params = {
        c_Id: this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 216 : 238,
        isCHorEN: 1
      }
      this.httphelper.post('/ScientificApi/GetColumsInfoAllList', params).then(res => {
        if (res.code == 200) {
          res.data.forEach(item => {
            let params = {
              keyword: '',
              c_Id: item.id,
              c_PId: 0,
              userId: 0,
              pageIndex: 1,
              pageSize: 10
            }
            this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
              if (res.data.length > 0) {
                item.child = res.data.splice(0, 4)
              }
            })
          });
          setTimeout(() => {
            this.list = res.data
          }, 500)

        }
      })
    },

    itemClick (item) {
      console.log(item)
      if (item.address) {
        window.open(item.address, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: '/inforDetails',
          query: {
            id: item.id,
            column_Type: 0
          }
        });
        window.open(href, '_blank')
      }
    },

    toMore () {
      this.$router.push({
        path: '/tool#top3',
        // query: {
        //   id: 3
        // }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/banner_four.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 220px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
  .content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-top: 18px;
  }
  .main {
    width: 80%;
    height: 360px;
    margin: 70px auto;
    position: relative;
    .main-right-bottom {
      width: 100%;
      position: relative;
      .name {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        text-align: center;
        padding: 47px 0 21px 0;
      }
      .line {
        width: 40px;
        height: 1px;
        background: #000000;
        margin: 0 auto;
      }
      .text-body {
        // height: calc(100% - 150px);
        // overflow-y: auto;
        .text {
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          margin-top: 20px;
          padding: 0 24px;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .text:hover {
          color: #0b8586;
        }
      }
      .sl {
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }

      .text-body::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      .text-body::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
      .text-body::-webkit-scrollbar-thumb {
        background-color: rgb(147, 147, 153, 0.5);
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }

      .el-icon-arrow-left,
      .el-icon-arrow-right {
        width: 37px;
        height: 37px;
        background: #0b8586;
        text-align: center;
        line-height: 37px;
        border-radius: 50%;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
      }
      .el-icon-arrow-left {
        position: absolute;
        top: 170px;
        left: -55px;
        z-index: 9;
      }
      .el-icon-arrow-right {
        position: absolute;
        top: 170px;
        right: -55px;
        z-index: 9;
      }
      .t5-button-disabled {
        color: #0b8586;
        background: #fff;
        border: 1px solid #0b8586;
      }
    }
  }
  .body {
    width: 100%;
    height: 360px;
    background: #fff;
    margin-top: 26px;
    border-radius: 8px;
    border: 1px solid #dedede;
    box-shadow: 7px 19px 30px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
  }
  .body:hover {
    border: 1px solid #0b8586;
    box-shadow: 7px 19px 30px 0px rgba(11, 133, 134, 0.35);
  }
}
.more {
  width: 200px;
  height: 37px;
  border: 1px solid #0b8586;
  border-radius: 19px;
  margin: 0 auto;
  text-align: center;
  line-height: 37px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0b8586;
  cursor: pointer;
}
.more:hover {
  background: #0b8586;
  color: #fff;
}
// @media screen and (max-width: 1900px) {
//   .container {
//     padding-top: 130px;
//     .title {
//       font-size: 28px;
//       margin-top: 40px;
//     }
//     .main {
//       margin: 30px auto;
//     }
//   }
//   .container .body {
//     width: 100%;
//     height: 300px;
//     background: #fff;
//     margin-top: 26px;
//     border-radius: 8px;
//   }
// }
</style>