import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import user from "@/views/user/index";
import userMyInfor from "@/views/user/userMyInforShow";
import userData from "@/views/user/userData";
import userMyCourse from "@/views/user/userMyCourse.vue"
import playback from "@/views/livedetail/playback.vue"
/**我的直播 */
import userLive from '../views/user/liveinfo';
import livesub from '../views/user/liveinfo/livesub.vue';
import liveorder from '../views/user/liveinfo/liveorder.vue';
import livecollect from '../views/user/liveinfo/livecollect.vue';
import liveclass from '../views/user/liveinfo/liveclass.vue';
import OpenInvoice from '../views/Personal/OpenInvoice';
import userMyInvoice from '../views/user/userMyInvoice';
import userVip from '../views/user/userVip';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/course',
    name: 'course',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/course/index.vue')
  },
  {
    path: '/tool',
    name: 'tool',
    component: () => import('../views/tool/index.vue')
  },
  {
    path: '/live',
    name: 'live',
    component: () => import('../views/live/index.vue')
  },
  {
    path: "/playback",
    name: "playback",
    component: playback,
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/livedetail/index.vue')
  },
  {
    path: '/Inforlist',
    name: 'Inforlist',
    component: () => import('../views/inforList/inforList.vue')
  },
  {
    path: '/inforDetails',
    name: 'inforDetails',
    component: () => import('../views/inforList/inforDetail.vue')
  },
  {
    path: "/user",
    name: "user",
    component: user,
    redirect: "/user/userinfo",
    children: [
      {
        path: "userData",
        name: "userData",
        component: userData,
        meta: {
          navShow: true,
          requireAuth: true,
          activeNum: "",
          title: "个人资料修改",
        },
      },
      {
        path: "userinfo",
        name: "userinfo",
        component: userMyInfor,
        meta: {
          navShow: true,
          requireAuth: true,
          activeNum: "",
          title: "个人资料展示",
        },
      },
      {
        path: "userMyCourse",
        name: "userMyCourse",
        component: userMyCourse,
        meta: {
          navShow: true,
          requireAuth: true,
          activeNum: "",
          title: "我的课程",
        },
      },
      {
        path: 'userLive',
        name: 'userLive',
        component: userLive,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: '我的直播' }
      },
      {
        path: 'livesub',
        name: 'livesub',
        component: livesub,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: '直播订阅' }
      },
      {
        path: 'liveorder',
        name: 'liveorder',
        component: liveorder,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: '直播订单' }
      },
      {
        path: 'livecollect',
        name: 'livecollect',
        component: livecollect,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: '直播收藏' }
      },
      {
        path: 'liveclass',
        name: 'liveclass',
        component: liveclass,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: '直播课程' }
      },
      {
        path: 'Personal/OpenInvoice',
        name: 'OpenInvoice',
        component: OpenInvoice,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: '编辑发票' }
      },
      {
        path: 'userMyInvoice',
        name: 'userMyInvoice',
        component: userMyInvoice,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: '我的发票' }
      },
      {
        path: 'userVip',
        name: 'userVip',
        component: userVip,
        meta: { navShow: true, requireAuth: true, activeNum: '', title: 'VIP中心' }
      },
    ],
  }
]

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

//replace
const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace (to) {
  return VueRouterReplace.call(this, to).catch(err => err);
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
