<template>
  <div class="el-upload-model">
    <el-upload class="el-avatar-uploader"
               action="#"
               ref="upload"
               :accept="fileaccept"
               :show-file-list="false"
               :http-request="handleUpload"
               :on-success="handleAvatarSuccess"
               :before-upload="beforeResUpload==null?beforeAvatarUpload:beforeResUpload">
      <slot></slot>
    </el-upload>
    <div class="el-progress-grid">
      <!-- <el-progress class="el-progressbar"
                   type="circle"
                   v-if="progressFlag"
                   color="#67c23a"
                   :percentage="progressPercent"> </el-progress> -->
      <el-progress v-if="progressFlag"
                   color="#0B8586"
                   type="line"
                   :percentage="progressPercent"> </el-progress>
    </div>
    <!-- <div>
            <img :src="imageUrl" v-if="ismiamge" />
            <a :href="fileurl" v-if="fileishow">{{ fileName }}</a>
        </div> -->
  </div>
</template>
<script>
import $axios from 'axios';
import md5 from 'js-md5';
export default {
  name: 'webupload',
  props: {
    pictureUrl: {
      type: String,
      default: ''
    },
    Maxfileszie: {
      type: Number,
      default: 10
    },
    fileaccept: {
      type: String,
      default: ''
    },
    /**上传类型：1：专家，2：机构，0：默认 */
    uploadtype: {
      type: Number,
      default: 0
    },
    orgid: {
      type: Number,
      default: 0
    },
    onSuccess: {
      type: Function,
      default: () => { }
    },
    beforeResUpload: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      imageUrl: '',
      progressFlag: false,
      progressPercent: 0,
      ismiamge: false,
      fileishow: false,
      fileurl: '',
      fileName: '文件'
    };
  },
  methods: {
    // 截取上传文件后缀
    getSuffix (filename) {
      let pos = filename.lastIndexOf('.');
      let suffix = '';
      if (pos != -1) suffix = filename.substring(pos);
      return suffix;
    },
    // 生成随机数
    randomString (len) {
      len = len || 32;
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      const maxPos = chars.length;
      let pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    ossfilename (filename) {
      var timestamp1 = Date.parse(new Date());
      return md5(timestamp1 + filename);
    },
    /**校验文件 */
    beforeAvatarUpload (file) {
      this.ismiamge = false;
      this.fileishow = false;
      var fileExtension = file.name.split('.').pop();

      const isFiletype = this.fileaccept == '' ? true : this.fileaccept.search(fileExtension.toLowerCase()) > -1;

      const isMaxfileszie = file.size / 1024 / 1024 <= this.Maxfileszie;

      if (!isFiletype) {
        this.$message.warning('只能上传' + this.fileaccept + '格式!');
      } else if (!isMaxfileszie) {
        this.$message.warning('上传的图片大小不能超过' + this.Maxfileszie + 'MB!');
      }

      return isFiletype && isMaxfileszie;
    },
    /**上传文件 */
    handleUpload (file) {
      const _self = this;
      var ossfilenamemd5 = _self.ossfilename(file.file.name);
      _self.httphelper
        .post('/OssFileApi/GetPolicyToken', {
          filename: file.file.name,
          ossfilename: ossfilenamemd5,
          mime: file.file.type,
          type: _self.uploadtype,
          orgid: _self.orgid
        })
        .then(response => {
          if (response.code && response.code === 200) {
            var res = response.data;
            var fileExtension = file.file.name.split('.').pop();
            let keyValue = res.key + ossfilenamemd5 + '.' + fileExtension;
            //注意formData里append添加的键的大小写
            let formData = new FormData();
            formData.append('name', file.file.name); // 文件名称
            formData.append('key', keyValue); // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', res.accessid); // //accessKeyId
            formData.append('policy', res.policy); // policy
            formData.append('Signature', res.signature); //签名
            formData.append('success_action_status', 200);
            formData.append('callbackbody', res.callback);
            formData.append('file', file.file, file.file.name); // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传即可
            var ossfilename = ossfilenamemd5 + '.' + fileExtension;

            _self.progressFlag = true;
            $axios.defaults.withCredentials = false;
            return new Promise((resolve, reject) => {
              $axios
                .post(res.host, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  // 图片上传进度
                  onUploadProgress: progressEvent => {
                    _self.progressPercent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                  }
                })
                .then(rep => {
                  if (rep.status === 200) {
                    var ossfileurl = res.host + '/' + keyValue;
                    const isJPG =
                      file.file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/svg+xml';
                    if (isJPG) {
                      _self.imageUrl = ossfileurl;
                      _self.ismiamge = true;
                    } else {
                      _self.fileurl = ossfileurl;
                      _self.fileName = file.file.name;
                      _self.fileishow = true;
                    }
                    var responsedata = { resid: res.resid, fileSzie: file.file.size, ossfilename: ossfilename, ossfileurl: ossfileurl };
                    _self.httphelper.post('/OssFileApi/OssCallback', { resid: res.resid, size: file.file.size }).then(callbackres => {
                      if (callbackres.code == 200) {
                        _self.onSuccess(responsedata, file.file);
                      }
                    });
                    if (_self.progressPercent >= 100) {
                      _self.progressFlag = false;
                      setTimeout(() => {
                        _self.progressPercent = 0;
                      }, 1000);
                    }
                  }
                  resolve(rep);
                })
                .catch(err => {
                  reject(err);
                });
            });
          }
        })
    },
    /**移除文件 */
    handleRemove (file) {
      this.$refs.upload.abort();
      this.$message({
        message: '成功移除' + file.name,
        type: 'success'
      });
    },
    /**上传成功 */
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    }
  }
};
</script>
