<template>
  <div>
    <div>
      <div style="background-image: linear-gradient(to left, #101011, #373737);">
        <Header :activeIndex="0"></Header>
      </div>
      <!-- 没付费 -->
      <div v-show="!flag && loginend && authType != 3"
           class="livebox">
        <div class="outside">
          <div>
            <img :src="liveDetailList.coverPictureUrl"
                 alt="">
          </div>
          <div class="livecont">
            <h3>{{ liveDetailList.title }} (直播回放)</h3>
            <span v-if="liveDetailList.org_name != ''">主办单位：{{ liveDetailList.org_name }}</span>
            <span>开播时间：{{ formatTime(liveDetailList.start_time, 'YYYY-MM-DD HH:MM:SS') }}</span>
            <span>结束时间：{{ formatTime(liveDetailList.end_time, 'YYYY-MM-DD HH:MM:SS') }}</span>
            <div>
              <div>
                <span v-if="(userId == 0 || (!liveDetailList.isPurchase && liveDetailList.payStatus == 2)) && !isfreetrial && liveDetailList.broadcastTime > 0 && liveDetailList.isBroadcast"
                      style="margin-left: 52%; background-color: #dd8526; text-align: center;line-height: 35px;color: #fff;  width: 38%; border-radius: 16px;cursor: pointer;"
                      @click="freetrial">
                  免登录试看 {{ liveDetailList.broadcastTime }} 分钟
                </span>
                <span v-else-if="isfreetrial && liveDetailList.broadcastTime > 0"
                      style="margin-left: 50%; background-color: #dd8526; text-align: center;line-height: 35px;color: #fff;  width: 40%; border-radius: 16px;cursor: pointer;">
                  正在加载回放
                  <van-loading style="float: right;margin-top:5px;margin-right:15px"
                               size="24px" />
                </span>
              </div>
              <div>

                <div v-if="(liveDetailList.showPrice == 0) || (userId > 0 && liveDetailList.isPurchase)"
                     class="price"
                     @click="toShop"
                     :disable="isReadonly"
                     style="background-color:#D7000B">免费</div>
                <div v-else
                     @click="toShop"
                     :disable="isReadonly"
                     class="price">
                  <span :style="{ 'float': 'left', 'padding-left': '40%' }">门票：{{
                    liveDetailList.showPrice
                  }}元</span>
                </div>
              </div>

              <!-- <div class="remind">

                <img v-if="liveDetailList.isFavorite" src="../../assets/imgs/love3.png" alt="取消收藏" title="取消收藏" @click="collection(false)" style="cursor:pointer;">
                <img v-else src="../../assets/imgs/love1.png" alt="收藏" title="收藏" style="cursor:pointer;" @click="collection(true)">
              </div> -->
            </div>
          </div>
        </div>

      </div>
      <!-- 支付 -->
      <payCount style="border: 1px red solid;"
                v-if="isPayContShow"
                :close="closePayContShow"
                :liveDetailList="liveDetailList"
                :orderurl="orderurl"
                :type="1">
      </payCount>
      <!-- 付费 -->
      <div v-show="flag || authType == 3"
           class="livebox2">
        <div class="outside2">
          <div>
            <div class="livetop">
              <div id="J_prismPlayer">
              </div>
            </div>
          </div>
          <div class="chat"
               v-show="imshow == 1 && tryshow">
            <div style="margin-top: 30%; margin-left: 10%;">
              <h3 class="spana">试看倒计时 </h3>
              <van-count-down class="spana"
                              :time="tryTimes * 1000"
                              format="DD 天 HH 时 mm 分 ss 秒">
              </van-count-down>
              <div>
                <h3 class="spana">{{ liveDetailList.title }}</h3>
                <span class="spana"
                      v-if="liveDetailList.org_name != ''">主办单位：{{ liveDetailList.org_name
                }}</span>
                <span class="spana">开播时间：{{ formatTime(liveDetailList.start_time, 'YYYY-MM-DD HH:MM:SS')
                }}</span>
                <span class="spana">结束时间：{{ formatTime(liveDetailList.end_time, 'YYYY-MM-DD HH:MM:SS')
                }}</span>
                <div>
                  <div>

                    <div v-if="liveDetailList.showPrice == 0"
                         @click="toShop"
                         :disable="isReadonly"
                         style="height: 40px; width: 90%; background-color:#2c7ef7; margin-top: 40px; text-align: center; line-height: 40px; color:#ffff; font-size: 15px; border-radius: 15px; font-weight: bold; cursor: pointer;">
                      免费
                    </div>
                    <div v-else
                         @click="toShop"
                         style="height: 40px; width: 90%; background-color:#2c7ef7; margin-top: 40px; color:#ffff; font-size: 15px; border-radius: 15px; font-weight: bold; cursor: pointer;">
                      <span :style="{ 'float': 'left', 'padding-left': '25%', 'margin-top': '10px' }">购买门票：{{
                        liveDetailList.showPrice
                      }}元</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div v-if="tranlist.length > 0"
           style=" position: relative; padding: 0px 100px 50px;width:100%;height:350px; ">

        <swiper :options="swiperOption4"
                style="padding-top: 20px">
          <swiper-slide v-for="(items, indexs) in tranlist"
                        :key="indexs + 's'">
            <!-- <div style="margin-left:20px;background: linear-gradient(to right,#0083C9,transparent);cursor: pointer; width: 100%;  text-align: center;  height: 100px;   padding: 10px 0;  background-color: #f6f4fa;  box-sizing: border-box;  position: relative;" @click="todetail(items)">
              <span style="width:10%"><img :src="items.orgLogoUrl" style="width: 35px;height: 35px; float: left;    margin: 7% 5%;border-radius:50%  " alt=""></span>
              <p class="title" style=" width: 55%; float: left;line-height: 20px;margin: 3% 0%; ">
                <span> {{ items.title }}</span><br>
                <span v-if="pid>0   && liveDetailList.mtcType==1">
                  {{ getShowPrice(items) }}
                </span>
              </p>
              <p style="width:10%;margin: 8% 0%; ">
                <a href="javascript:0" class="livebtn" style="border:1px solid #0081c9;color:#0081c9;box-shadow:0 0 10px #0081c9;">{{ items.isPlayback?'回放':'直播' }}</a>
              </p>
            </div> -->
            <div class="video-card"
                 style="padding: 0.11333rem 0.781vw;background-color: #f6f4fa;-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;margin-left:20px;cursor: pointer; "
                 @click="todetail(items)">
              <!-- <img :src="items.thumbnailUrl" class="cont_img" alt=""> -->
              <img :src="Date.parse(items.start_time) < new Date() && Date.parse(items.end_time) > new Date() ? require('../../assets/imgs/live.png')
                  : Date.parse(items.end_time) < new Date() && !items.isPlayback ? require('../../assets/imgs/over.png')
                    : Date.parse(items.end_time) < new Date() && items.isPlayback ? require('../../assets/imgs/playback.png')
                      : Date.parse(items.start_time) > new Date() ? require('../../assets/imgs/notice.png') : require('../../assets/imgs/live.png')"
                   style="position: absolute;  z-index: 999;" />
              <el-image :src="items.thumbnailUrl"
                        class="cont_img"
                        fit="fill"></el-image>
              <span style="width: 150px;"> {{ ellipsis(items.title, 26) }}</span>
              <span v-if="pid > 0 && liveDetailList.mtcType == 1"
                    style="float: right; margin-top: 20px;"> <el-button type="info"
                           size="mini"
                           round
                           style="width: 130px;">{{ getShowPrice(items) }} </el-button></span>

            </div>
          </swiper-slide>
        </swiper>
        <div v-if="tranlist.length > 4"
             class="prevbutton4 el-icon-arrow-left"></div>
        <div v-if="tranlist.length > 4"
             class="nextbutton4 el-icon-arrow-right"></div>
      </div>
    </div>

    <div>
      <!-- 广告位 -->
      <div style="margin: 5px 6.5% 10px 6.5%"
           class="advertising"
           v-if="LiveAdvert != null && LiveAdvert.isOpen">
        <a :href="hyperlink">
          <img :src="advertResUrl"
               alt=""
               style="width:100%;height: 80px;"></a>
      </div>
      <div class="contbox">
        <!-- 推荐直播 -->
        <div class="wrap_content"
             style="padding-top: 10px"
             v-if="LiveRecommendList.length > 0">
          <h3 class="title">{{ LiveRecommendList[0].redName }}</h3>
          <swiper :options="swiperOption0"
                  style="padding-top: 20px">
            <swiper-slide v-for="(items, indexs) in LiveRecommendList"
                          :key="indexs + 's'">
              <div class="video-card"
                   style="margin-left:20px;cursor: pointer;"
                   @click="videojump(items)">
                <img :src="items.acr_PictResUrl"
                     class="cont_img"
                     alt="">
                <p class="title ng-star-inserted">
                  {{ items.courseName }}
                </p>
              </div>
            </swiper-slide>
          </swiper>
          <div v-if="LiveRecommendList.length > 4"
               class="prevbutton0 el-icon-arrow-left"></div>
          <div v-if="LiveRecommendList.length > 4"
               class="nextbutton0 el-icon-arrow-right"></div>
        </div>
        <!--菜单-->
        <div class="btitle display_center_ud">
          <img v-if="menulist.length > 0"
               src="../../assets/icons/live2.png"
               alt="">
          <div v-for="(item, index) in menulist"
               :key="index">
            <div v-if="item.liveDetailList.length > 0">
              <span v-show="index != 0"
                    style="margin:0 10px;">/</span>
              <span class="mettbrief"
                    :style="showBoxId == index ? 'cursor: pointer;color:#0b8586' : 'cursor: pointer;'"
                    @click="showBoxId = index">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body">
      <div v-for="(item, index) in menulist"
           :key="index">
        <div class="bbox"
             v-if="showBoxId == index">
          <!-- 图文 -->
          <div class="tuwenbox"
               v-if="item.type == 2">
            <div v-if="item.liveDetailList.length > 0"
                 v-html="item.liveDetailList[0].contents"></div>
          </div>
          <!-- 视频 -->
          <div class="wrap_content"
               v-else-if="item.type == 3">
            <swiper :options="swiperOption1">
              <swiper-slide v-for="(items, indexs) in item.liveDetailList"
                            :key="indexs + 's'">
                <div class="video-card"
                     style="margin-left:20px;cursor: pointer;">
                  <img :src="items.coverPictureUrl"
                       class="cont_img"
                       alt="">
                  <img src="../../assets/icons/bofang3.png"
                       class="btn"
                       @click="toShipin(items)"
                       alt="">
                  <p class="title ng-star-inserted">
                    {{ items.title }}
                  </p>
                </div>
              </swiper-slide>
            </swiper>
            <div v-if="item.liveDetailList.length > 4"
                 class="prevbutton1 el-icon-arrow-left"></div>
            <div v-if="item.liveDetailList.length > 4"
                 class="nextbutton1 el-icon-arrow-right"></div>
          </div>
          <!-- 超级链接 -->
          <div class="wrap_content"
               v-else-if="item.type == 4">
            <swiper :options="swiperOption2">
              <swiper-slide v-for="(itemss, indexs) in item.liveDetailList"
                            :key="indexs + 'ss'">
                <div class="linkcard"
                     style="margin-left:20px;cursor: pointer;"
                     @click="linkclk(itemss)">
                  <img :src="itemss.coverPictureUrl"
                       class="cont_img"
                       alt="">
                </div>
              </swiper-slide>
            </swiper>
            <div v-if="item.liveDetailList.length > 4"
                 class="prevbutton2 el-icon-arrow-left"></div>
            <div v-if="item.liveDetailList.length > 4"
                 class="nextbutton2 el-icon-arrow-right"></div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="isShipin"
                 :title="shipinTitle"
                 :before-close="shipinClose"
                 width="26%">
        <div id="shipin"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { Dialog } from 'vant';
import Header from '../../components/header'
import Player from 'xgplayer';
import format from '../../assets/units/unit'
import payCount from '../../components/pay/payCont.vue'
export default {
  components: {
    Header,
    payCount
  },
  data () {
    return {
      loginend: false,
      isfreetrial: false,
      livestatus: '',
      tryshow: false,
      imshow: -1,
      swiperOption0: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 20,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.nextbutton0',
          prevEl: '.prevbutton0',
          disabledClass: 'my-button-disabled'
        }
      },
      swiperOption1: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 20,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.nextbutton1',
          prevEl: '.prevbutton1',
          disabledClass: 'my-button-disabled'
        }
      },
      swiperOption2: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 4,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.nextbutton2',
          prevEl: '.prevbutton2',
          disabledClass: 'my-button-disabled'
        }
      },
      swiperOption4: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 4,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.nextbutton4',
          prevEl: '.prevbutton4',
          disabledClass: 'my-button-disabled'
        }
      },
      LiveAdvert: {},
      playershowend: false,
      screenWidth: '',
      flag: false,
      value: true,
      id: 1,      //直播频道id
      userId: 0, //用户id
      live_id: '',  //直播间id  //'739805'
      userinfo: this.$store.state.user.userInfo || "",
      liveDetailList: [],
      commentlist: [], //评论列表
      liveGiftConfigDeteilResult: [],  //打赏列表
      LiveRecommendList: [],  //推荐直播列表
      isPayContShow: false,  //支付
      orderurl: '',  //订单编号
      hyperlink: '',  //广告位链接
      sigindata: {},
      advertResUrl: '',
      danmus: [],
      msg_img: '',
      lId: 0, //直播id
      pid: 0,
      LiveSrc: '',
      menulist: [],
      menutype: 0,
      linkList: [],
      breadList: [],
      content: '',
      AuthorId: 0,
      isPlayback: false,  //是否为直播或回放
      isShipin: false,
      shipinTitle: '',
      player: null,
      mtgId: 0,
      liveType: 1,
      mobUrl: '',
      LiveStreamType: 0,
      qid: 0,
      showBoxId: 0,
      authType: 1,  //登录类型
      tryTimes: 0,  //试看时长
      isReadonly: false, //是否置灰价格
      siteType: 1,
      time: 0,
      bgimg: '',
      wcUserId: 0,
      //以下是绑定直播间
      isshowexpression: false,
      ismudu: false,
      roomName: '',
      roomViewNum: 0,
      cover_img: '',//频道图标
      commentlist: [],//评论列表
      mes: '',
      comment: '',
      tranlist: [],

    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.id = this.$route.query.id
      },
      immediate: true
    }
  },

  created () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
    if (this.$route.query.pid) {
      this.pid = this.$route.query.pid
    }
    this.getLiveDetail() //获取直播详情
  },
  methods: {
    getShowPrice (item) {
      let ShowPrice = 0
      if (item.isPlayback) {
        if (item.payStatus == 2)
          ShowPrice = item.playbackPrice
      } else {
        var aData = new Date();
        var CurrentDate = aData.getFullYear() + '-' + (aData.getMonth() + 1) + '-' + aData.getDate() + ' ' + aData.getHours() + ':' + aData.getMinutes() + ':' + aData.getSeconds();
        var CurrentDategetTime = new Date(CurrentDate).getTime();
        if (item.authType == 3) {
          if (item.chargeType == 1)//优惠价格
          {
            ShowPrice = item.discount_price;
          }
          else if (item.chargeType == 2)//限时优惠
          {
            if (new Date(item.disStart_time).getTime() <= CurrentDategetTime && new Date(item.disEnd_time).getTime() >= CurrentDategetTime) {
              ShowPrice = item.discount_price;
            }
            else {
              ShowPrice = item.price;
            }
          }
          else if (item.chargeType == 3)//原价
          {
            ShowPrice = item.price;
          }
        }
      }
      if (ShowPrice > 0) {
        return "门票 : " + ShowPrice + '元'
      } else {
        return "免费"
      }
    },
    videojump (item) {
      window.open(this.httphelper.IsDebugCgphome() + '/video?courseId=' + item.courseId, '_blank')
    },
    todetail (item) {
      if (item.isPlayback) {
        this.$router.push({
          name: 'playback',
          query: {
            id: item.id,
            pid: this.pid
          }
        })
      } else {
        this.$router.push({
          name: 'detail',
          query: {
            id: item.id,
            pid: this.pid
          }
        })
      }
    },
    //免费体验
    freetrial () {
      this.tryTimes = this.liveDetailList.broadcastTime * 60
      this.flag = true;
      this.imshow = 1;
      this.tryshow = true;
      this.isfreetrial = true;
      this.createlive();
    },
    //创建直播间
    createlive () {
      this.isfreetrial = false;
      let ignores = (this.tryshow && this.imshow == 1) ? ['progress'] : [];
      this.player = new Player({
        id: 'J_prismPlayer',
        url: this.liveDetailList.resUrl,
        autoplay: true,
        playsinline: true,
        width: '100%',
        height: '100%',
        // lastPlayTime: ,
        ignores: ignores,
        rotateFullscreen: false,
        'x5-video-player-type': 'h5',
        'x5-video-orientation': 'portraint',
        playbackRate: this.tryshow ? [] : [0.5, 1, 1.5, 2, 3],
      });
      if (this.tryshow && this.imshow == 1) {
        var test2 = setInterval(() => {
          this.tryTimes--
          if (this.tryTimes <= 0) {
            this.player.destroy(true);
            this.flag = false
            clearInterval(test2);
            if (this.userId == 0) {
              Dialog.confirm({
                title: '系统提示',
                message: '试看结束，请先登录后观看',
                confirmButtonText: '登录'
              })
                .then(() => {
                  this.toPcLogin('default')
                }).catch(() => {
                });
            } else {
              this.imshow = -1;
              this.flag = false;
              this.tryshow = false;
            }
          }
        }, 1000)
      }


    },
    // 时间格式化
    formatTime (datatime, timeformat) {
      return format(datatime, timeformat);
    },
    formatDate (str) {
      let date = new Date(Number(str) * 1000);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let day = date.getDate();
      day = day < 10 ? "0" + day : day;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return year + "-" + month + "-" + day + " " + h + ":" + m;
    },
    // 获取直播详情
    getLiveDetail () {
      if (JSON.stringify(this.$store.state.user.userInfo) != '{}') {
        this.userId = this.$store.state.user.userInfo.id
      } else {
        this.userId = 0;
      }
      this.httphelper
        .post('LiveTrainApi/GetLiveTrainDetail', {
          userId: this.userId,
          pid: Number(this.pid),
          trainId: Number(this.id)
        })
        .then(res => {
          if (res.code == 200) {
            this.liveDetailList = res.data
            this.live_id = res.data.live_id

            if (res.data.pid > 0) {
              if (res.data.tranlist[0].isPlayback) {
                this.$router.replace({
                  name: 'playback',
                  query: {
                    id: res.data.tranlist[0].id, pid: res.data.pid
                  }
                })
              } else
                this.$router.push({
                  name: 'detail',
                  query: { id: res.data.tranlist[0].id, pid: res.data.pid },
                });
            }

            if (this.pid > 0) {
              var arr = res.data.tranlist.filter(item => item.id == this.id)
              if (arr.length > 0)
                this.live_id = arr[0].live_id
              this.tranlist = res.data.tranlist
            }
            this.isPlayback = res.data.isPlayback
            this.isReadonly = res.data.isReadonly
            this.authType = res.data.authType
            // 是否已报名
            if (res.data.isPurchase && res.data.authType != 3) {
              //问卷
              this.getIsQuestion();
            } else if (res.data.authType == 3) {
              this.flag = true
              this.imshow = 0;
              this.createlive();
            }
          }
          this.loginend = true;
          this.attract() //广告位
          this.getLiveRecommendList() //推荐直播
          this.getLiveMenuById()  //会议日程
        })
    },
    //判断问卷
    getIsQuestion () {
      if (this.liveDetailList.appointment != null && this.liveDetailList.appointment.isOpen == true) {
        this.qid = this.liveDetailList.appointment.qid;
        this.httphelper.get('LiveTrainApi/CheckIsLiveFeedback', {
          qid: this.qid,
          userid: this.userId
        }).then((res) => {
          if (res.code == 200) {
            this.flag = true
            this.imshow = 0;
            this.createlive();
          } else {
            this.flag = false
            this.imshow = -1;
            this.qid = this.qid;
            let Start_time = new Date(this.liveDetailList.appointment.start_time)
            let End_time = new Date(this.liveDetailList.appointment.end_time)
            let now_time = Date.now()
            if (Start_time.getTime() < now_time && End_time > now_time) {
              let callback = encodeURIComponent(window.location.href);
              this.$router.push({
                path: '/question?',
                query: {
                  qid: this.qid,
                  tid: this.id,
                  callback: callback
                }
              })
            } else {
              this.flag = true
              this.imshow = 0;
              this.createlive();
            }
          }
        })
      } else {
        this.flag = true
        this.imshow = 0;
        this.createlive();
      }
    },
    // 广告位
    attract () {
      this.httphelper
        .post('LiveApi/GetMtgLiveAdvert', {
          id: this.live_id,
          liveType: 1
        })
        .then(res => {
          if (res.code == 200) {
            this.LiveAdvert = res.data;
            if (res.data != null) {
              this.hyperlink = res.data.hyperlink
              this.advertResUrl = res.data.advertResUrl
            }

          }
        })
    },
    // 推荐直播
    getLiveRecommendList () {
      this.httphelper
        .post('LiveTrainApi/GetLiveRecommendList', {
          liveid: Number(this.live_id)
        })
        .then(res => {
          if (res.code == 200) {
            this.LiveRecommendList = res.data
          }
        })
    },
    // 会议日程
    getLiveMenuById () {
      this.httphelper
        .post('LiveApi/getLiveMenuById', {
          Id: this.live_id,
          LiveType: 1
        })
        .then(res => {
          if (res.code == 200) {
            this.menulist = []
            let data = res.data;
            if (data != null) {
              for (let i = 0; i < data.length; i++) {
                if (data[i].type != 5) {
                  this.menulist.push(data[i])
                }
              }
            }
          }
        })
    },
    //超级链接跳转
    linkclk (item) {
      window.open(item.hyperlinks);
    },
    toLink () {
      window.open(this.httphelper.IsDebugGP519());
    },
    toShipin (item) {
      this.shipinTitle = item.title
      this.isShipin = true
      var test1 = setTimeout(() => {
        this.player = new Player({
          id: 'shipin',
          url: item.resUrl,
          autoplay: true,
          playsinline: true,
          width: '100%',
          height: 'auto'
        });
        clearTimeout(test1);
      }, 100);
    },
    shipinClose () {
      if (this.player != null) {
        this.player.destroy();
        this.player = null;
      }
      this.isShipin = false
    },
    // 去支付
    toShop () {
      if (JSON.stringify(this.$store.state.user.userInfo) === '{}') {
        Dialog.confirm({
          title: '系统提示',
          message: '请先登录',
          confirmButtonText: '登录'
        })
          .then(() => {
            this.toPcLogin('default')
          }).catch(() => {
          });
        return;
      }

      let tid = Number(this.id)
      if (this.pid > 0) {
        if (this.liveDetailList.liveClassify == 2 && this.liveDetailList.mtcType == 2) {
          tid = Number(this.pid)
        }
      }
      this.userId = this.$store.state.user.userInfo.id;
      this.httphelper.post('CGpPayApi/LivePayOrd', {
        UserId: this.userId,
        LeId: tid,    //3
        Ltype: this.isPlayback ? 2 : 1
      }).
        then(res => {
          if (res.code == 200) {
            this.orderurl = res.data
            this.isPayContShow = true
          } else if (res.code == 205) {
            this.$message({
              type: 'warning',
              message: '已报名,禁止重复报名!'
            });
            this.flag = true
          } else if (res.code == 206) {
            this.$message({
              type: 'warning',
              message: res.msg
            });
          } else if (res.code == 202) {
            this.$message({
              type: 'success',
              message: '报名成功'
            });
            this.getIsQuestion();
          }
        })
    },
    closePayContShow () {
      this.isPayContShow = false
      location.reload()
    },
    // 试看登录
    toPcLogin (cback) {
      let callback = ''
      if (cback == 'default') callback = callback = encodeURIComponent(this.$unit.delUrlData('token'));;
      window.location.href = this.httphelper.IsDebugGP519() + '/loginIndex/login?callback=' + callback;
    },
    // 点击收藏
    collection (IsFavorite) {
      if (JSON.stringify(this.$store.state.user.userInfo) === '{}') {
        Dialog.confirm({
          title: '系统提示',
          message: '请先登录',
          confirmButtonText: '登录'
        })
          .then(() => {
            this.toPcLogin('default')
          }).catch(() => {
          });
        return;
      } else {
        // 取消收藏
        if (!IsFavorite) {
          this.httphelper
            .post('LiveTrainApi/DeleteGpLiveFavorites', {
              trainId: this.liveDetailList.id,
              userId: this.$store.state.user.userInfo.id
            })
            .then(res => {
              this.liveDetailList.isFavorite = false;
            })
        } else {
          // 收藏
          this.httphelper
            .post('LiveTrainApi/AddGpLiveFavorites', {
              trainId: this.liveDetailList.id,
              userId: this.$store.state.user.userInfo.id
            })
            .then(res => {
              if (res.code == 200) {
                this.liveDetailList.isFavorite = true;
              }
            })
        }
      }

    },
  },
  mounted () {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
      })();
    };
  },
  watch: {
    screenWidth (val) {
      if (this.playershowend) {
        this.player.setPlayerSize(this.screenWidth * 0.61, (this.screenWidth * 0.61) * 0.562)
      }
    },
    '$route' (to, from) {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.pid = this.$route.query.pid
      }
      this.$router.go()
    }
  }

}
</script>
<style lang="scss" scoped>
.spana {
  display: block;
  margin-bottom: 5px;
  margin-right: 10px;
  // color: #0000;
}

.livebox {
  padding: 60px 180px;
  // background-color: #373737;
  background-image: linear-gradient(to left, #101011, #373737);

  .outside {
    position: relative;
    display: flex;
    justify-content: space-between;

    img {
      width: 900px;
      height: 600px;
    }
  }

  .livecont {
    width: 800px;
    height: 600px;
    padding: 80px 50px 0 50px;

    h3 {
      font-size: 30px;
      color: #fff;
      margin-bottom: 80px;
    }

    span {
      display: block;
      margin-bottom: 50px;
      color: #fff;
    }

    .preview {
      position: absolute;
      // float: left;
      width: 150px;
      // height: 40px;
      text-align: center;
      line-height: 35px;
      border-radius: 25px;
      // padding: 0 20px;
      border: 1px solid #dd8526;
      color: #dd8526;
    }

    .preview1 {
      // position: absolute;
      // float: left;
      width: 150px;
      // height: 40px;
      text-align: center;
      line-height: 35px;
      border-radius: 25px;
      // padding: 0 20px;
      border: 1px solid #dd8526;
      color: #dd8526;
    }

    .price {
      margin-top: 80px;
      width: 500px;
      height: 50px;
      background-color: #dd8526;
      text-align: center;
      line-height: 50px;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .remind {
    position: absolute;
    top: 280px;
    right: 150px;

    img {
      margin-left: 40px;
      width: 30px;
      height: 30px;
    }
  }
}

.livebox2 {
  padding: 60px 180px 50px;
  background-color: rgb(244, 247, 253);
  background-image: url("https://static.mudu.tv/assets/img/console/theme-default-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 100%;

  .outside2 {
    display: flex;
    justify-content: space-between;

    .livetop {
      width: 1130px;
      height: 654px;
      position: relative;
    }

    .livetopurl {
      z-index: 9999;
      position: absolute;
      top: 75%;
      left: -64px;
      width: 64px;
      height: 64px;
      background-color: red;
    }

    .livebot {
      padding: 20px 20px 0 10px;
      width: 1162px;
      height: 150px;
      background-color: #fff;

      .livebotd {
        // width: 200px;
        display: flex;
        justify-content: space-between;

        .left {
          width: 200px;
          display: flex;
          justify-content: space-between;
        }

        .right {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;

          img:hover {
            transform: scale(1.1, 1.1);
          }

          .mudububble {
            position: absolute;
            z-index: 22;
            top: -360px;
            left: -200px;
            width: 444px;
            height: 348px;
            background-color: #fff;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);

            .mudutitle {
              width: 100%;
              height: 40px;
              // background-color: pink;
              border-bottom: 1px solid #e5e5e5;

              span {
                display: inline-block;
                margin: 3px 0;
                padding: 0 89px;
                border-right: 1px solid #e5e5e5;
              }

              span:nth-child(2) {
                border-right: none;
              }
            }

            .muducont {
              padding: 5px 10px;
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;

              .muduli {
                width: 100px;
                height: 130px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 5px;

                img {
                  margin-bottom: 3px;
                }

                span {
                  display: inline-block;
                  margin-top: 3px;
                  font-size: 5px;
                  color: #898989;
                }

                .mudulibb {
                  padding: 5px 5px 0;
                  display: none;
                  position: absolute;
                  top: -142px;
                  width: 300px;
                  height: 130px;
                  border-radius: 5px;
                  background-color: #fff;
                  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);

                  .gurl {
                    display: flex;
                    justify-content: flex-start;

                    img {
                      margin-right: 5px;
                    }

                    .pricefree {
                      border-top: 1px solid #e5e5e5;
                      width: 190px;
                      padding: 3px 0;
                      margin-top: 40px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .gname {
                        font-size: 7px;
                      }

                      .gcolor {
                        color: #f04040;
                      }

                      .give {
                        width: 50px;
                        height: 25px;
                        line-height: 25px;
                        background-color: #f04040;
                        color: #fff;
                        text-align: center;
                        border-radius: 3px;
                        font-size: 6px;
                        cursor: pointer;
                      }
                    }
                  }

                  .mudulibbs {
                    position: absolute;
                    bottom: -15px;
                    left: 170px;
                    width: 0;
                    height: 0;
                    border: 3px solid;
                    border-color: #fff transparent transparent transparent;
                    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
                  }
                }
              }

              .muduli:hover {
                border: 1px solid #f5a3b6;
                box-shadow: 0 1px 8px 0 #ccc;
              }

              .muduli:hover .mudulibb {
                display: block;
              }

              .muduli:hover .free {
                color: #f04040;
              }

              .muduli ::v-deep img {
                width: 64px;
                height: 64px;
              }
            }

            .mudububbles {
              z-index: 23;
              position: absolute;
              bottom: -15px;
              left: 217px;
              width: 0;
              height: 0;
              border: 3px solid;
              border-color: #fff transparent transparent transparent;
              filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
            }
          }

          .mudu {
            text-align: center;
            font-size: 5px;
          }

          img {
            width: 50px;
            height: 50px;
          }
        }

        .icon {
          img {
            width: 64px;
            height: 64px;
          }
        }

        .try {
          margin-bottom: 5px;
          width: 600px;
        }

        .eye {
          width: 10px;
          height: 10px;
          vertical-align: middle;
          margin-right: 5px;
        }

        .pepo {
          vertical-align: middle;
        }
      }

      .livebotb {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;

        .phone {
          width: 10px;
          height: 10px;
          vertical-align: middle;
        }

        .kan {
          vertical-align: middle;
        }

        .el-switch {
          margin-left: 5px;
        }

        .el-switch ::v-deep .el-switch__core {
          margin-bottom: 2px;
        }

        .el-switch ::v-deep .el-switch__core:after {
          top: 0;
        }
      }
    }

    .chat {
      position: relative;
      width: 360px;
      // height: 804px;
      background-color: #fff;

      .chattit {
        padding: 6px 0 6px 6px;
        border-bottom: 1px solid #e5e5e5;
      }

      .list {
        height: 650px;
        overflow-y: scroll;
      }

      .list::-webkit-scrollbar {
        display: none;
      }

      .item {
        p {
          margin-top: 3px;
          text-align: center;
          color: #cbcbcb;
        }

        .items {
          display: flex;
          // justify-content: start;
          margin: 3px 0 0 6px;
          padding-right: 60px;

          img {
            margin-right: 4px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }

          .message {
            display: inline-block;
            margin-top: 3px;
            // height: 30px;
            padding: 5px 3px;
            line-height: 1px;
            border-radius: 0 3px 3px 3px;
            background-color: #f2f6fd;
            white-space: normal;
            word-break: break-all;
            overflow: hidden;
          }

          .message ::v-deep img {
            width: 48px;
            height: 48px;
          }
        }
      }

      .chatbot {
        position: absolute;
        bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;

        .face {
          width: 10px;
          height: 10px;
        }

        input {
          width: 240px;
          margin-left: 3px;
          outline: none;
          border: none;
          background-color: #f4f6f8;
          padding-left: 3px;
        }

        .send {
          width: 50px;
          height: 25px;
          text-align: center;
          line-height: 25px;
          border-radius: 3px;
          background-color: #448aff;
          margin-left: 3px;
          border: none;
          color: #fff;
        }
      }

      .bubble {
        width: 380px;
        height: 150px;
        background-color: #fff;
        position: relative;
        padding: 7px 0 0 25px;
        top: -100px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);

        .emotion-item-row {
          display: flex;
          justify-content: flex-start;

          .emotion-item {
            margin: 0 7px 2px 0;
          }

          img {
            width: 32px;
            height: 32px;
          }
        }

        .bubbles {
          position: absolute;
          bottom: -15px;
          left: 7px;
          width: 0;
          height: 0;
          border: 3px solid;
          border-color: #fff transparent transparent transparent;
          filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
        }
      }
    }
  }

  .timebox {
    position: absolute;
    top: 100px;
    right: 21%;
    box-sizing: border-box;
    background-color: rgb(206, 35, 35);
    color: #fff;
    // font-size: 12px;
  }
}

.contbox {
  padding: 0px 67px 0px 120px;
  background-color: #fff;

  .advertising {
    // text-align: center;
    margin: 50px;
  }
}

.recommend {
  margin-top: 60px;

  h3 {
    font-size: 20px;
  }

  .list {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;

    .item {
      margin: 0 38px 10px 0;
    }

    .item:nth-child(4n) {
      margin: 0 0 10px 0;
    }

    img {
      width: 376px;
      height: 195px;
    }

    .meet {
      width: 350px;
      margin-top: 20px;
      font-weight: bold;
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .time {
      display: block;
      margin-top: 25px;
    }
  }
}

.btitle {
  margin-top: 80px;

  img {
    margin-right: 20px;
  }

  .mettbrief {
    font-weight: bold;
    font-size: 20px;
  }
}

.body {
  margin-top: 20px;
  padding: 0px 70px 70px;
}

.bodytop {
  padding: 30px 0 0;
}

.bodytop .guanggao {
  width: 1240px;
  height: 40px;
  /* background-color: #666; */
  margin-bottom: 20px;
  cursor: pointer;
}

.bodytop .guanggao img {
  height: 40px;
}

.tuwenbox {
  padding: 0 3%;

  image {
    width: 200px;
  }
}

.linkBox {
  width: 1000px;
}

.nextbutton0,
.nextbutton1,
.nextbutton2,
.nextbutton4,
.prevbutton0,
.prevbutton1,
.prevbutton4,
.prevbutton2 {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  box-sizing: border-box;
  border-radius: 30px;
  background: #0b8586;
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 99;
  cursor: pointer;
  outline: none;
}

.nextbutton0,
.nextbutton1,
.nextbutton4,
.nextbutton2 {
  margin-left: 95.5%;
}

.wrap_content {
  position: relative;
  padding: 10px 0;
  width: 100%;
}

.linkcard {
  width: 350px;
  // height: 100px;
  padding: 10px 15px;
  box-sizing: border-box;
  position: relative;
  // border: 1px solid red;
}

.linkcard .cont_img {
  width: 90%;
  // height: 100px;
  // border-radius: 5px 5px 0 0;
}

.video-card {
  width: 330px;
  height: 260px;
  padding: 10px 15px;
  background-color: #f6f4fa;
  box-sizing: border-box;
  position: relative;
}

.video-card .cont_img {
  width: 300px;
  height: 160px;
  border-radius: 5px 5px 0 0;
}

.video-card .btn {
  position: absolute;
  top: 35%;
  left: 45%;
  cursor: pointer;
}

.video-card p {
  height: 70px;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  margin-top: -3px;
  line-height: 20px;
  font-size: 14px;
  padding: 6px 5px 10px;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.vue-danmaku ::v-deep .bullimg {
  width: 64px;
  height: 64px;
  margin: 0 12px;
}

.my-button-disabled {
  background: #ddd;
  color: #999;
}

.bbox {
  // width: 87%;
  margin-left: 50px;
}

.livebtn {
  position: absolute;
  display: inline-block;
  width: 80px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  color: #6babff;
  border: 1px solid #6babff;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
}
</style>
  