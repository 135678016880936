import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import unit from "./assets/js/unit.js";
import Ellipsis from './assets/unit/ellipsis';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import "./assets/css/public.css";
import "./assets/css/comm.css";
import "./assets/css/globe.css";
import "./assets/css/iconfont.css";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import httphelper from './utils/request';
import $Load from './views/components/Loading/index';
import VueCookies from 'vue-cookies';
import { Popup, Picker, CountDown, Loading, RadioGroup, Radio, Tab, Tabs, Popover, Grid, GridItem, Button, Empty } from "vant";
Vue.prototype.httphelper = httphelper;
Vue.config.productionTip = false
Vue.prototype.$unit = unit;
Vue.prototype.ellipsis = Ellipsis;
import vuescroll from 'vuescroll';
Vue.use(vuescroll);

Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.use(Picker);
Vue.use(Popup);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Popover);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Button);
Vue.use(Empty);
Vue.use($Load);
Vue.use(VueCookies);

router.beforeEach((to, from, next) => {
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords);
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.meta.requireAuth) {
    if (localStorage.getItem('ResData')) {
      next();
    } else {
      if (to.path == '/journalxml') {
        next({
          path: '/loginIndex/login',
          query: { callback: to.path },
        });
      } else {
        next({
          path: '/loginIndex/login',
        });
      }
    }
  } else {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (flag) {
      console.log('shi')
      if (
        to.path.toLowerCase().search('meetingMobile'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('meetingpc'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('meetingPc'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('meetingMobileDetail'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('wxlogin'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('queryList'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('queryDetail'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('reading'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('mologin'.toLowerCase()) > -1 ||
        to.path.toLowerCase().search('livelist'.toLowerCase()) > -1
      ) {
        console.log(1)
        next();
      } else if (to.path.search('/') > -1) {
        window.location.href = 'https://h5rd.gp519.com/';
      } else if (to.path.search('/tool') > -1) {
        var params = 'https://h5rd.gp519.com/tool'
        window.location.href = params;
      } else if (to.path.search('/tool?token=false') > -1) {
        var params = 'https://h5rd.gp519.com/tool'
        window.location.href = params;
      }
    } else {
      console.log('bushi')
      // http://192.168.0.129:8080/ 杂志 ：https://chinagp.net/
      // http://localhost:8080 主站：https://gp519.com/
      if (to.name == 'home' && window.location.host.search('chinagp.net') > -1 && Object.keys(to.query).length == 0) {
        // if (to.name == 'home' && window.location.host.search('192.168.0.129:8080') > -1 && Object.keys(to.query).length == 0) {
        next({
          path: 'magazine',
        });
      } else if (to.name != 'magazine' && window.location.host.search('chinagp.net') > -1 && to.path.search('magazine') < 0) {
        // else if (to.name != 'magazine' && window.location.host.search('192.168.0.129:8080') > -1 && to.path.search('magazine') < 0) {
        window.location.href = httphelper.IsDebugGP519() + to.path;
        // window.location.href = 'http://localhost:8080' + to.path;
      } else if (to.name == 'magazine' && window.location.host.search('gp519.com') > -1) {
        // else if (to.name == 'magazine' && window.location.host.search('localhost:8080') > -1) {
        window.location.href = 'https://chinagp.net/' + to.name;
        // window.location.href = 'http://192.168.0.129:8080/' + to.name;
      } else if (to.name == 'feed') {
        window.location.href = 'https://chinagp.net/' + to.path;
      } else {
        next();
      }
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
