<template>
  <div class="container">
    <div class="title">论文写作</div>
    <div class="content">如何写好医学科研论文？——医学科研论文撰写要点解析，手把手传授论文写作规范与技巧</div>
    <div class="main">
      <div class="main-left">
        <div style="display: flex; align-items: center; margin-bottom: 21px;">
          <div class="main-right-top">
            <div class="main-right-top-child"
                 v-for="(key, index) in nav"
                 :key="index"
                 :class="{ active: activeIndex == index }"
                 @click="keyClick(key, index)">{{ key.name }}</div>
          </div>
          <div class="main-left-top"
               @click="toTool"
               v-if="list.length > 0">查看更多 ></div>
        </div>
        <div class="main-right-bottom"
             v-if="list.length > 0">
          <swiper :options="option">
            <swiper-slide v-for="(item, index) in list"
                          :key="index">
              <img :src="item.slideShow"
                   class="img"
                   alt=""
                   @click="itemClick(item)">
              <div class="name"
                   @click="itemClick(item)">{{ item.title }}</div>
              <div class="introduce"
                   @click="itemClick(item)">{{ item.content }}</div>
            </swiper-slide>
          </swiper>
          <div class="t5prevbutton el-icon-arrow-left"
               @click="prev"></div>
          <div class="t5nextbutton el-icon-arrow-right"
               @click="next"></div>
        </div>
        <div class="wu"
             v-if="list.length <= 0">
          <img src="../../assets/image/wu_bai.png"
               alt=""
               style="margin-top: 30px">
        </div>
      </div>
      <div class="main-right">
        <div class="main-right-title">推荐课程</div>
        <div class="main-left-top"
             @click="toMore">查看更多 ></div>
        <div style="display: flex; margin-top: 22px;">
          <div class="main-left-child"
               v-for="(item, index) in videoList"
               :key="index"
               @click="toVideo(item)">
            <img :src="item.acr_PictResUrl"
                 class="video-img"
                 alt="">
            <div class="main-left-child-content">{{ item.title }}</div>
            <img src="../../assets/icon/video.png"
                 class="bf"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeIndex: 0,
      option: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        slidesPerGroup: 6,
        spaceBetween: 20,
        slidesPerColumnFill: 'row',
        navigation: {
          nextEl: '.t5nextbutton',
          prevEl: '.t5prevbutton',
          disabledClass: 't5-button-disabled'
        }
      },
      nav: [],
      list: [],
      videoList: [],
      params: {
        navId: 36,
        courseType: 0,
        orderType: 0,
        page: 1,
        limit: 2
      },
      c_Id: '',
      pageIndex: 1
    }
  },

  created () {
    this.getColumn()
    this.getList()
  },

  methods: {
    // 获取栏目
    getColumn () {
      let params = {
        c_Id: this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 218 : 254,
        isCHorEN: 1
      }
      this.httphelper.post('/ScientificApi/GetColumsInfoAllList', params).then(res => {
        console.log(res, '==6')
        this.nav = res.data
        this.c_Id = res.data[0].id
        if (res.code == 200) {
          let data = res.data
          let params = {
            keyword: '',
            c_Id: data[0].id,
            c_PId: 0,
            userId: 0,
            pageIndex: this.pageIndex,
            pageSize: 6
          }
          this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
            if (res.code == 200) {
              this.list = res.data
            } else {
              this.list = []
            }
          })
        }
      })
    },

    getList () {
      let params = {
        labelName: '论文写作',
        number: 2
      }
      this.httphelper.post('NewApi/GetDbRecommendHomeCoursePageList', params).then(res => {
        this.videoList = res.data
      })
    },

    next () {
      this.pageIndex = this.pageIndex + 1
      let params = {
        keyword: '',
        c_Id: this.c_Id,
        c_PId: 0,
        userId: 0,
        pageIndex: this.pageIndex,
        pageSize: 6
      }
      this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
        if (res.code == 200 && res.data.length > 0) {
          this.list = res.data
        } else {
          this.pageIndex = this.pageIndex - 1
        }
      })
    },

    prev () {
      if (this.pageIndex == 1) return
      this.pageIndex = this.pageIndex - 1
      let params = {
        keyword: '',
        c_Id: this.c_Id,
        c_PId: 0,
        userId: 0,
        pageIndex: this.pageIndex,
        pageSize: 6
      }
      this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
    },

    keyClick (item, index) {
      this.activeIndex = index
      this.c_Id = item.id
      let params = {
        keyword: '',
        c_Id: item.id,
        c_PId: 0,
        userId: 0,
        pageIndex: 1,
        pageSize: 6
      }
      this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
        if (res.code == 200) {
          this.list = res.data
        } else {
          this.list = []
        }
      })
    },

    toMore () {
      this.$router.push({
        path: '/course#top5',
        // query: {
        //   id: 5
        // }
      })
    },

    toTool () {
      this.$router.push('/tool#top5')
    },

    itemClick (item) {
      if (item.address) {
        window.open(item.address, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: '/inforDetails',
          query: {
            id: item.id,
            column_Type: 0
          }
        });
        window.open(href, '_blank')
      }
    },

    toVideo (item) {
      let url = this.httphelper.IsVideo() + '/detail?courseId=' + item.id;
      window.open(url);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/banner4.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 220px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000;
  }
  .content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000;
    margin-top: 18px;
  }
  .main {
    width: 80%;
    // height: 450px;
    margin: 50px auto;
    display: flex;
    .main-left {
      position: relative;
      width: 50%;
      padding-right: 60px;
      padding-top: 42px;
      padding-bottom: 42px;
      .main-right-top {
        width: 50%;
        display: flex;
        .main-right-top-child {
          width: 120px;
          height: 36px;
          color: #ffffff;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 18px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
          margin-right: 10px;
        }
        .active {
          color: #000000;
          background: #fff;
        }
      }
      .main-left-top {
        margin: 20px 0;
        width: 50%;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000;
        cursor: pointer;
      }
      .main-right-bottom {
        width: 100%;
        position: relative;
        cursor: pointer;
        .img {
          width: 200px;
          height: 114px;
          border-radius: 8px;
        }
        .img:hover {
          border: 1px solid #0b8586;
          box-shadow: 7px 19px 30px 0px rgba(11, 133, 134, 0.35);
        }
        .name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          margin-top: 12px;
        }
        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-size: 40px;
          color: #0b8586;
        }
        .el-icon-arrow-left {
          position: absolute;
          top: 32%;
          transform: translateY(50%);
          left: -45px;
          z-index: 9;
        }
        .el-icon-arrow-right {
          position: absolute;
          top: 32%;
          transform: translateY(50%);
          right: -35px;
          z-index: 9;
        }
        .t5-button-disabled {
          color: #d5d5d5;
        }
      }
    }
    .main-left::after {
      position: absolute;
      top: 0;
      right: 0;
      content: " ";
      width: 1px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }

    .main-right {
      width: 50%;
      padding-left: 60px;
      padding-top: 42px;
      .main-right-title {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        margin: 10px 0;
        border-left: 3px solid #0b8586;
        padding: 0 10px;
      }
      .main-left-top {
        margin: 20px 0;
        // width: 100%;
        text-align: right;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        padding: 0 60px;
        cursor: pointer;
      }
      .main-left-child {
        width: 300px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        .video-img {
          width: 300px;
          height: 170px;
          // height: 173px;
          background: #fff;
          // margin-top: 36px;
          border-radius: 8px;
        }
        .main-left-child-content {
          width: 100%;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 25px;
          margin-top: 26px;
        }
        .bf {
          position: absolute;
          bottom: 60px;
          left: 10px;
          width: 30px;
          height: 30px;
        }
      }
      .main-left-child:last-child {
        margin-left: 20px;
      }
    }
  }
}
.wu {
  text-align: center;
  line-height: 270px;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}
// @media screen and (max-width: 1900px) {
//   .container {
//     padding-top: 130px;
//     .title {
//       font-size: 28px;
//       margin-top: 40px;
//     }
//     .main {
//       margin: 30px auto;
//     }
//   }
//   .container .main .main-left .main-right-bottom .img {
//     width: 100%;
//   }
// }
</style>