<template>
  <div class="container">
    <div class="title">期刊查询</div>
    <div class="content">写好论文，不会选刊怎么办？——千种期刊一站式查询，帮您更快找到接受自己论文的好期刊</div>
    <div class="main">
      <div class="search">
        <input type="text"
               v-model="params.name"
               class="input">
        <el-button type="primary"
                   class="btn"
                   @click="seach">搜索期刊</el-button>
      </div>
      <div class="nav">
        <div>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ nav }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="">全部</el-dropdown-item>
              <el-dropdown-item v-for="(k, key) in allnavList"
                                :key="key"
                                :command="k.perios">{{ k.perios }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-for="(item, index) in navList"
             :key="index"
             style="cursor: pointer; font-size: 14px;"
             @click="handleCommand(item.perios)">{{ item.perios }}</div>
      </div>
      <div class="search-content"
           v-if="list.length > 0">
        <swiper :options="option">
          <swiper-slide v-for="(item, index) in list"
                        :key="index">
            <div class="child">
              <div class="logo-view">
                <img :src="item.image_src"
                     class="logo"
                     alt="">
                <div class="openGw"
                     v-if="item.hyperlink"
                     @click="toGw(item.hyperlink)">进入期刊官网</div>
              </div>
              <div class="child-right">
                <div class="child-right-title">{{ item.name }}</div>
                <div class="num">
                  影响因子：{{ item.if }}
                  <span class="state">待更新</span>
                </div>
                <div class="weekly-title">收录期刊：</div>
                <div class="child-weekly"
                     v-if="item.perios">
                  <div v-for="(key, index) in item.perios.split(',')"
                       :key="index"
                       class="child-weekly-item">{{ key }}</div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="t5prevbutton el-icon-arrow-left"
             :class="params.page == 1 ? 't5-button-disabled':''"
             @click="previous"></div>
        <div class="t5nextbutton el-icon-arrow-right"
             @click="next"></div>
      </div>
      <div v-if="list.length == 0"
           class="empty">
        <img src="../../assets/image/empty.png"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      option: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.t5nextbutton',
          prevEl: '.t5prevbutton',
          // disabledClass: 't5-button-disabled'
        }
      },
      nav: '全部',
      navList: [],
      allnavList: [],
      list: [],

      params: {
        name: '',
        perios: '',
        page: 1,
        limit: 3
      },

      loading: ''
    }
  },

  watch: {
    activeIndex (old) {
      if (old == 7) {
        this.getNav()
        this.getList()
      }
    }
  },

  created () {
    if (this.activeIndex == 7) {
      this.getNav()
      this.getList()
    }
  },

  methods: {
    getNav () {
      this.httphelper.get('ScientificApi/GetPeriosClustering').then(res => {
        this.allnavList = res.data
        let arr = []
        if (res.data.length > 3) {
          arr.push(res.data[0])
          arr.push(res.data[1])
          arr.push(res.data[2])
        }
        this.navList = arr
      })
    },

    getList () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.httphelper.post('ScientificApi/GetWfPeriodicalInfoList', this.params).then(res => {
        this.loading.close()
        this.list = res.data
      })
    },

    // 进入官网
    toGw (url) {
      let new_url = ''
      if (url.indexOf(',') != -1) {
        let http = url.split(',')[0]
        if (http.indexOf('http') != -1) {
          new_url = http
        } else {
          new_url = 'https://' + http
        }
      } else if (url.indexOf(';') != -1) {
        let http = url.split(';')[0]
        if (http.indexOf('http') != -1) {
          new_url = http
        } else {
          new_url = 'https://' + http
        }
      } else {
        if (url.indexOf('http') != -1) {
          new_url = url
        } else {
          new_url = 'https://' + url
        }
      }
      window.open(new_url, '_blank')
    },

    // 搜索
    seach () {
      this.getList()
    },

    handleCommand (command) {
      if (command) {
        this.nav = command
      } else {
        this.nav = '全部'
      }
      this.params.perios = command
      this.getList()
    },

    next () {
      this.params.page = this.params.page + 1
      this.getList()
    },

    previous () {
      if (this.params.page == 1) return
      this.params.page = this.params.page - 1
      if (this.params.page < 1) {
        this.params.page = 1
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/banner8.png");
  background-repeat: no-repeat;
  background-size: 100% 110%;
  padding-top: 220px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
  .content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-top: 18px;
  }
  .main {
    width: 80%;
    margin: 100px auto;
    .search {
      width: 50%;
      margin: 0 auto;
      display: flex;
      .input {
        width: 100%;
        border: 2px solid #0b8586;
        border-radius: 25px 0 0 25px;
        outline: none;
        padding-left: 32px;
      }
      .btn {
        width: 100px;
        background: #0b8586;
        border-radius: 0px 18px 18px 0px;
        outline: none;
        border: none;
      }
    }
    .nav {
      width: 50%;
      display: flex;
      margin: 23px auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      div {
        margin-left: 33px;
      }
    }
    .search-content {
      width: 100%;
      // height: 280px;
      margin-top: 33px;
      position: relative;
      .child {
        width: 100%;
        height: 280px;
        background: #fff;
        margin-top: 26px;
        border-radius: 8px;
        box-shadow: 10px 17px 20px 0px rgba(0, 0, 0, 0.07);
        padding: 21px;
        display: flex;
        .logo-view {
          position: relative;
          height: 100%;
          .logo {
            width: 144px;
            height: 191px;
            background: #0b8586;
            margin-right: 24px;
            box-shadow: 10px 17px 20px 0px rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            margin-top: 8px;
          }
          .openGw {
            position: absolute;
            bottom: 5px;
            left: 0;
            width: 144px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8586;
            cursor: pointer;
          }
        }
        .child-right {
          width: calc(100% - 144px);
          .child-right-title {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-top: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .num {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0b8586;
            margin-top: 18px;
            .state {
              background: #757c81;
              border-radius: 2px;
              color: #fff;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              padding: 4px 5px;
            }
          }
          .weekly-title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-top: 18px;
          }
          .child-weekly {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            overflow-y: auto;
            .child-weekly-item {
              padding: 0 7px;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #8c9399;
              border: 1px solid #8c9399;
              border-radius: 2px;
              margin-top: 3px;
              margin-right: 3px;
            }
          }
          .child-weekly::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }

          .child-weekly::-webkit-scrollbar-track {
            background-color: transparent;
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
          }
          .child-weekly::-webkit-scrollbar-thumb {
            background-color: rgb(147, 147, 153, 0.5);
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
          }
        }
      }

      .el-icon-arrow-left,
      .el-icon-arrow-right {
        width: 37px;
        height: 37px;
        background: #0b8586;
        text-align: center;
        line-height: 37px;
        border-radius: 50%;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
      }
      .el-icon-arrow-left {
        position: absolute;
        top: 170px;
        left: -55px;
        z-index: 9;
      }
      .el-icon-arrow-right {
        position: absolute;
        top: 170px;
        right: -55px;
        z-index: 9;
      }
      .t5-button-disabled {
        color: #0b8586;
        background: #fff;
        border: 1px solid #0b8586;
      }
    }
    .empty {
      width: 435px;
      height: 211px;
      margin: 60px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 1900px) {
  // .container {
  //   padding-top: 130px;
  //   .title {
  //     font-size: 28px;
  //     margin-top: 40px;
  //   }
  // }
  // .container .main {
  //   width: 80%;
  //   margin: 30px auto;
  // }
  // .container .main .search-content .child {
  //   margin: 0;
  // }
  .container .main .search-content .child .child-right .child-weekly {
    height: 37%;
    overflow-y: auto;
  }
  .container
    .main
    .search-content
    .child
    .child-right
    .child-weekly
    .child-weekly-item {
    height: 35px;
    line-height: 35px;
  }
  // .container .main .search-content .child .logo-view .logo {
  //   width: 124px;
  //   height: auto;
  // }
}
</style>