import { createLogger } from "vuex";

/**时间格式化 */
function format (date, format) {
    if (!date) {
        return '';
    }
    return formatter(date, format);
}

function formatter (time, pattern = '') {
    var EnMMList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var format = time.replace(/-/g, '/'); //把“-”，替换成‘/’
    var date = new Date(format),
        y = date.getFullYear(),
        m = (date.getMonth() + 1).toString().padStart(2, 0),
        d = date
            .getDate()
            .toString()
            .padStart(2, 0),
        h = date
            .getHours()
            .toString()
            .padStart(2, 0),
        mi = date
            .getMinutes()
            .toString()
            .padStart(2, 0),
        se = date
            .getSeconds()
            .toString()
            .padStart(2, 0);
    if (pattern == 'YYYY/MM/DD') {
        return `${y}/${m}/${d}`;
    } else if (pattern == 'YYYY-MM-DD') {
        return `${y}-${m}-${d}`;
    } else if (pattern == 'en') {
        var mm = Number(m - 1);
        return EnMMList[mm] + '. ' + d + ',' + y; //`${y}-${m}-${d} ${h}:${mi}:${se}`;
    } else {
        return `${y}-${m}-${d} ${h}:${mi}:${se}`;
    }
}



export default format;
