<template>
  <div class="body">
    <div class="gl_title_bar top6t">
      <div class="gl_tb_left">
        <span class="normaltitlenew">直播课程</span>
      </div>
      <div class="t6titler">
        <div class="t4r2t t4rt"
             :style="myjigouname == '请选直播' ? 'margin-top:0;padding-left:20px;height:38px' : 'background: #0B8586;margin-top:0;padding-left:20px;height:38px'"
             @click="ismyjigou = !ismyjigou;">
          <span :style="myjigouname == '请选直播' ? 'padding-left:0;border:0' : 'color:#fff;padding-left:0;border:0'">{{
                            myjigouname
                        }}</span>
          <img src="../../../assets/img/organ/xia1.png"
               v-show="myjigouname != '请选直播' && !ismyjigou"
               alt="">
          <img src="../../../assets/img/organ/xia2.png"
               v-show="myjigouname == '请选直播' && !ismyjigou"
               alt="">
          <img src="../../../assets/img/organ/shang1.png"
               v-show="myjigouname != '请选直播' && ismyjigou"
               alt="">
          <img src="../../../assets/img/organ/shang2.png"
               v-show="myjigouname == '请选直播' && ismyjigou"
               alt="">
          <div class="t4r1m t4rm"
               style="top:36px"
               @click.stop
               v-show="ismyjigou">
            <p v-for="(item, index) in myjigoulist"
               @click="tomyjigou(item)"
               v-show="myjigouid != item.id"
               :key="index">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="top6"
         style="margin-top: 15px;"
         v-if="uid != 0 && myjigoulist.length > 0">
      <div class="t6kc">
        <div class="t3mid"
             v-show="mykecheng.length > 0">
          <div class="t3mbox2"
               v-for="(item, index) in mykecheng.slice(0, isgengduo ? mykecheng.length : 9)"
               :style="(index + 1) % 3 == 0 ? 'margin-right:0' : ''"
               @click="tokecheng(item.courseId)"
               :key="index">
            <!-- <div class="t3mbg" v-show="getIsTime() != 0">
                            <span>{{ getIsTime()== 2 ? '已结束' : '未开始'}}</span>
                        </div> -->
            <img :src="item.coverPicture"
                 class="t3kcfm"
                 alt="">
            <div class="t3mboxbot">
              <span class="t3title">{{ item.title }}</span>
              <div class="t3biaoqian">
                <span>学习进度</span>
                <div class="jindu">
                  <div class="jindubox"
                       :style="'width:' + Math.ceil(item.learning * 100) + '%'">
                  </div>
                </div>
              </div>
              <div class="t3more">
                <div>
                  <span>已学 </span>
                  <span style="color:#0b8586">{{ Math.ceil(item.learning * 100) }}%</span>
                </div>
                <div v-show="myXiangmuId != -1">
                  <img src="../../../assets/img/icon/ren.png"
                       style="width:10px;height:11px;margin-right:10px"
                       alt="">
                  <span>{{ item.numberPeople }}人</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t3bot"
             v-show="mykecheng.length > 9">
          <div class="t6gengduo"
               @click="isgengduo = !isgengduo">
            <img src="../../../assets/img/icon/xiangxia.png"
                 :style="isgengduo ? 'transform: rotate(180deg);' : ''"
                 alt="">
            <span v-show="!isgengduo">更多课程</span>
            <span v-show="isgengduo">收起</span>
          </div>
        </div>
        <div class="t3mid2"
             v-show="mykecheng.length == 0">
          <span>没有找到符合条件的课程</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'userMyCourse',
  data () {
    return {
      uid: 0,
      ist6xm: false,
      myXiangmu: [],
      myXiangmuId: 0,
      mykecheng: [],
      isgengduo: false,
      isxuejiBox: false,
      form: {
        name: '',
        number: '',
        unit: '',
        position: '',
        edu: '',
        department: '',
        region: []
      },
      positionOptions: [],
      eduOptions: [],
      departmentOptions: [],
      arealist: [],
      oidInfo: '',
      region: '',
      myjigouname: '请选择机构',
      myjigouid: 0,
      ismyjigou: false,
      myjigoulist: [],
      orgindex: 0,
      havejf: false,
      havenojf: false,
      provincelist: [],
    };
  },
  watch: {
    myXiangmuId: function () {
      this.getMyKecheng()
    },
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.uid = Number(JSON.parse(localStorage.getItem('ResData')).id)
    }
    this.getprovincelist()
    this.getMyjigou()
    this.getMyKecheng()
  },
  methods: {
    getprovincelist () {
      for (let a in areaList.province_list) {
        this.provincelist.push({
          value: a,
          label: areaList.province_list[a]
        });
      }
    },
    getMyjigou () {
      this.httphelper.get('CourseClassApi/GetUserLivecouList', {
        UserId: Number(this.uid),
      })
        .then(res => {
          if (res.code == 200) {
            this.myjigoulist = res.data
            if (res.data[0]) {
              this.myjigouid = res.data[0].id
              this.myjigouname = res.data[0].title
            }
            this.getMyKecheng()
          }
        });
    },
    getMyKecheng () {

      this.httphelper.post('CourseClassApi/GetClassLivePageList', {
        userId: Number(this.uid),
        liveId: this.myjigouid,
        page: 1,
        limit: 100
      }).then(res => {
        var datajson = res.data;
        if (datajson.code == 200) {
          this.mykecheng = datajson.data;
        }
      });

    },
    tokecheng (id) {
      if (this.myXiangmuId == -1) {
        let { href } = this.$router.resolve({
          path: '/course',
          query: {
            cid: id,
            orgid: this.myjigouid
          }
        });
        window.open(href, '_blank');
      } else {
        if (localStorage.getItem('ResData')) {
          window.open(this.httphelper.IsDebugCgphome() + '/detail?courseId=' + id + '&tokenKey=' + this.$cookies.get('token_chinagp'), "_blank")
        } else {
          window.open(this.httphelper.IsDebugCgphome() + '/detail?courseId=' + id, "_blank")
        }
      }
    },
    openxueji () {
      for (let i = 1; i < 7; i++) {
        this.httphelper.get('BasicsmesApi/GetCodeResultList', {
          type: i
        })
          .then(res => {
            if (res.code == 200) {
              if (i == 2) this.positionOptions = res.data
              else if (i == 1) this.eduOptions = res.data
              else if (i == 3) this.departmentOptions = res.data
            }
          });
      }
      for (let a in areaList.province_list) {
        this.arealist.push({
          value: a,
          label: areaList.province_list[a],
          children: []
        });
      }
      this.GetMyInfo()
      this.isxuejiBox = true
    },
    expandChange (val) {
      if (val.length == 1) {
        for (let i = 0; i < this.arealist.length; i++) {
          if (this.arealist[i].value == val[0]) {
            this.arealist[i].children = [];
          }
        }
        for (let b in areaList.city_list) {
          if (b.substr(0, 2) == val[0].substr(0, 2)) {
            for (let i = 0; i < this.arealist.length; i++) {
              if (this.arealist[i].value == val[0]) {
                this.arealist[i].children.push({
                  value: b,
                  label: areaList.city_list[b],
                  children: []
                });
              }
            }
          }
        }
      } else if (val.length == 2) {
        for (let i = 0; i < this.arealist.length; i++) {
          if (this.arealist[i].value == val[0]) {
            for (let a = 0; a < this.arealist[i].children.length; a++) {
              if (this.arealist[i].children[a].value == val[1]) {
                this.arealist[i].children[a].children = [];
              }
            }
          }
        }
        for (let b in areaList.county_list) {
          if (b.substr(0, 4) == val[1].substr(0, 4)) {
            for (let i = 0; i < this.arealist.length; i++) {
              if (this.arealist[i].value == val[0]) {
                for (let a = 0; a < this.arealist[i].children.length; a++) {
                  this.arealist[i].children[a].children.push({
                    value: b,
                    label: areaList.county_list[b]
                  });
                }
              }
            }
          }
        }
      }
    },
    GetMyInfo () {
      this.httphelper.post('CGpUserApi/GetUserMessage', {
        id: this.uid,
      })
        .then(res => {
          if (res.data) {
            this.form.name = res.data.userName
            this.form.number = res.data.iDcard
            this.form.unit = res.data.unit
            this.form.position = res.data.professionalId
            this.form.edu = res.data.educatId
            this.form.department = res.data.admoffId
            this.oidInfo = res.data
            for (let a in areaList.province_list) {
              if (a == res.data.province) {
                this.region = areaList.province_list[a]
              }
            }
            for (let a in areaList.city_list) {
              if (a == res.data.city) {
                this.region = this.region + ' / ' + areaList.city_list[a]
              }
            }
            for (let a in areaList.county_list) {
              if (a == res.data.district) {
                this.region = this.region + ' / ' + areaList.county_list[a]
              }
            }
          }
        });
    },
    postxueji () {
      this.httphelper.post('CGpUserApi/UpdUserCredit', {
        userId: Number(this.uid),
        userName: this.form.name,
        unit: this.form.unit,
        iDcard: this.form.number,
        province: this.form.region[0] ? this.form.region[0] : this.oidInfo.province,
        city: this.form.region[1] ? this.form.region[1] : this.oidInfo.city,
        district: this.form.region[2] ? this.form.region[2] : this.oidInfo.district,
        professionalId: Number(this.form.position),
        educatId: Number(this.form.edu),
        admoffId: Number(this.form.department)
      })
        .then(res => {
          if (res.code == 200) {
            this.$message.success('提交成功');
            this.isxuejiBox = false
          } else {
            this.$message.error('提交失败');
          }
        });
    },
    toXiangmu (item) {
      this.myXiangmuId = item.id
    },
    tomyjigou (item) {
      this.myjigouname = item.title
      this.myjigouid = item.id
      this.getMyKecheng()
      this.ismyjigou = false;

    },
    getIsTime () {
      for (let i = 0; i < this.myXiangmu.length; i++) {
        if (this.myXiangmu[i].id == this.myXiangmuId) {
          let stime = Date.parse(new Date(this.myXiangmu[i].startDate))
          let etime = Date.parse(new Date(this.myXiangmu[i].endDate))
          let time = Date.parse(new Date())
          if (stime > time) return 1
          else if (etime < time) return 2
          else return 0
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
}

.normaltitlenew {
  font-size: 26px;
  font-weight: bold;
  display: inline-block;
  border-bottom: 2px solid #000000;
  padding-bottom: 20px;
  font-family: Microsoft YaHei;
  color: #000000;
  line-height: 26px;
  cursor: pointer;
}

.t4rt {
  width: 294px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #007380;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 28px;
  padding-right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  position: relative;
  cursor: pointer;
}

.t4rm {
  width: 294px;
  height: 210px;
  background: #ffffff;
  border: 1px solid #007380;
  box-sizing: border-box;
  position: absolute;
  top: 44px;
  left: -1px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  padding: 15px 20px 15px 28px;
  overflow-y: scroll;
  z-index: 100;
  scrollbar-color: transparent transparent;
  scrollbar-arrow-color: #f0f2f5;
  /* 设置滚动条上的箭头颜色  */
  scrollbar-base-color: #f0f2f5;
  /* 设置滚动条的底色 */
  scrollbar-track-color: #f0f2f5;
  /* 设置滚动条块的背景色 */
  scrollbar-shadow-color: #f0f2f5;
  /* 设置箭头和滚动条右侧和底边的颜色 */
}

.t4rm::-webkit-scrollbar {
  /* 滚动条宽 */
  width: 6px;
  height: 4px;
}

.t4rm::-webkit-scrollbar-thumb {
  /* 滚动条 拖动条 */
  background-color: #0b8586;
  border-radius: 6px;
}

.t4rm::-webkit-scrollbar-track {
  /* 滚动条背景槽 */
  background-color: #fff;
  border-radius: 6px;
}

.t4rm p:hover {
  color: #0b8586;
}

.t3mid {
  display: flex;
  flex-wrap: wrap;
}

.t3mbox {
  height: 288px;
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  margin-bottom: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 5px 5px;
  cursor: pointer;
  position: relative;
}

.t3mbox2 {
  height: 288px;
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  margin-bottom: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 5px 5px;
  // filter: grayscale(100%);
  position: relative;
  cursor: pointer;
}

.t3mbg {
  width: 100%;
  height: 288px;
  background-color: rgba(128, 128, 128, 0.35);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.t3mbg span {
  width: 100px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t3kcfm {
  height: 158px;
  width: 300px;
  min-height: 158px;
}

.t3mboxbot {
  padding: 15px 15px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.t3title {
  width: 100%;
  height: 38px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.t3more {
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #919ca3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.t3biaoqian {
  display: flex;
  flex-wrap: wrap;
}

.t3biaoqian div {
  background: #ffffff;
  border: 1px solid #979fa6;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #979fa6;
  padding: 0px 3px;
  /* margin-left: 3px; */
  margin-right: 3px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.t3biaoqian span {
  white-space: nowrap;
}

.t3mid2 {
  width: 100%;
  text-align: center;
  line-height: 100px;
}

.t3bot {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top6t {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.top6t .t6titler {
  display: flex;
}

.top6t .t6titlerb {
  height: 36px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  margin-left: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  padding: 0 10px;
  position: relative;
}

.top6t .t6titlerb .dian {
  width: 6px;
  height: 6px;
  background: #ff0000;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
}

.top6t2 {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 16px;
  padding-left: 10px;
  border-left: 2px solid #0b8586;
  box-sizing: border-box;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #0b8586;
  line-height: 16px;
}

.top6 .t6xm {
  position: relative;
}

.top6 .t6xmbox {
  width: 218px;
  height: 88px;
  background: #ffffff;
  border: 1px solid #0b8586;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  position: relative;
  margin-right: 10px;
  padding: 13px 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.top6 .t6xmbox img {
  bottom: 13px;
  right: 15px;
  position: absolute;
}

.top6 .t6xmtitle {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.top6 .t6xmtime {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #9f9f9f;
}

.top6 .t6xmshow {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.top6 .t6xmshow img {
  margin-left: 5px;
}

.top6 .t6xm1 {
  display: flex;
}

.top6 .t6xm2 .t6xmbox {
  margin-bottom: 10px;
}

.top6 .t6xm2 {
  display: flex;
  flex-wrap: wrap;
  min-height: 98px;
  max-height: 196px;
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-arrow-color: #f0f2f5;
  /* 设置滚动条上的箭头颜色  */
  scrollbar-base-color: #f0f2f5;
  /* 设置滚动条的底色 */
  scrollbar-track-color: #f0f2f5;
  /* 设置滚动条块的背景色 */
  scrollbar-shadow-color: #f0f2f5;
  /* 设置箭头和滚动条右侧和底边的颜色 */
}

.top6 .t6xm2::-webkit-scrollbar {
  /* 滚动条宽 */
  width: 6px;
  height: 4px;
}

.top6 .t6xm2::-webkit-scrollbar-thumb {
  /* 滚动条 拖动条 */
  background-color: #0b8586;
  border-radius: 6px;
}

.top6 .t6xm2::-webkit-scrollbar-track {
  /* 滚动条背景槽 */
  background-color: rgb(241, 241, 241);
  border-radius: 6px;
}

.top6 .t6xian {
  width: 965px;
  height: 1px;
  margin: 15px 0;
  background-color: #eee;
}

.top6 .t6btn {
  margin-bottom: 30px;
  display: flex;
}

.top6 .t6btnbox {
  width: 201px;
  height: 32px;
  background: #0b8586;
  border-radius: 15px;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  cursor: pointer;
}

.top6 .t6gengduo {
  width: 240px;
  height: 33px;
  background: #0b8586;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.top6 .t6gengduo img {
  margin-right: 10px;
}

.top6 .jindu {
  width: 186px;
  height: 4px;
  background: #e5e5e5;
  border-radius: 2px;
  border: none;
  padding: 0 !important;
  margin: 0;
  margin-left: 8px;
}

.top6 .jindubox {
  width: 106px;
  height: 4px;
  background: #0b8586;
  border-radius: 2px;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
}

.top6 .t3biaoqian {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

.xuefenBox ::v-deep .el-dialog__body {
  padding: 10px 35px 40px;
}

.xftop {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.xftop img {
  margin-right: 10px;
  width: 22px;
  height: 22px;
}

.xfmid {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
}

.xuejiBox ::v-deep .el-dialog__body {
  padding: 10px 35px 40px;
}

.xuejiBox ::v-deep .el-input__inner {
  height: 35px;
  width: 297px;
}

.xuejiBox ::v-deep .el-form-item {
  margin-bottom: 10px;
}

.xuejibtn {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.xuejibtnbox {
  width: 130px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  border-radius: 16px;
  margin: 0 5px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
