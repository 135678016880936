export default {
  namespaced: true,
  state: () => ({
    userInfo: JSON.parse(localStorage.getItem('ResData')) || {},
    userType: localStorage.getItem('userType') || 0,
    tokenstatus: localStorage.getItem("token_newgphome") || true,
    token: localStorage.getItem("token_newgphome") || "",
  }),
  mutations: {
    setUserInfo (state, val) {
      state.userInfo = val
    },
    setUserType (state, val) {
      state.userType = val
    },
    setTokenStatus (state, val) {
      state.tokenstatus = val
    },
    setToken (state, val) {
      state.token = val
    }
  },
  actions: {
    fetchUserInfo (store, val) {
      store.commit('setUserInfo', val)
    },
    fetchUserType (store, val) {
      store.commit('setUserType', val)
    },
    fetchTokenStatus (store, val) {
      store.commit('setTokenStatus', val)
    },
    fetchToken (store, val) {
      store.commit('setToken', val)
    }
  },
}