<template>
  <div>
    <div class="ercodedialog"   v-if="phonebind">
      <div class="ercodedialog_cover">
        <div class="ercodedialog_cover_main">
          <div class="Retrpsw_cont">
            <div class="Retrpsw_cont_close" @click="closephoneBind">
              <i class="iconfont icon-guanbi"></i>
            </div>
            <div class="Retrpsw_cont_main">
              <div class="Retrpsw_cont_main_cont" v-show="PhonebindShow">
                <div class="Retrpsw_cont_main_head">
                  <div class="fl">
                    <p>手机号绑定</p>
                  </div>
                </div>
                <div class="main_form">
                  <el-form size="small" :model="formPhone" :rules="phoneRules" ref="FindpswForm">
                    <div class="Retrpsw_div">
                      <i class="iconfont icon-shouji fl"></i>
                      <el-form-item prop="BindCell">
                        <el-input placeholder="手机号" v-model="formPhone.BindCell"></el-input>
                      </el-form-item>
                    </div>
                    <div class="Retrpsw_div RetrCode">
                      <el-input placeholder="请输入验证码" @keyup.enter.native="ForgeStep" v-model="formPhone.PhoneVerCode"></el-input>
                      <el-button class="sendCode" style="padding:0px;" @click="findpswCode" v-if="isShowGetCode">发送验证码</el-button>
                      <el-button class="sendCode" style="padding:0px;" v-else>{{ countdown }}后可重试</el-button>
                    </div>
                    <div class="Retrpsw_div ReStep" @click="ForgeStep()" style="cursor:pointer;">
                      确定
                    </div>
                  </el-form>
                </div>
              </div>
              <div class="Retrpsw_cont_main_cont_resh" v-show="DragPhoneBind">
                <!-- 拖动验证码验证 -->
                <slide-verify :l="42" :r="10" :w="300" :h="200" slider-text="向右滑动" @success="onSuccesspsw" @fail="onFail" @refresh="onRefresh"> </slide-verify>
                <div style="font-size: 15px;margin-top: 7px;color:#E0001A">{{ findpswmsg }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gl_title_bar" >
      <div class="gl_tb_left">
        <span class="normaltitle">个人资料</span>
      </div>
    </div>
    <div class="formWrap"  >

      <div class="display_center_ud marginTop20 marginBottom40" style="position: relative;">
        <div class="PerData_con_image_logo fl">
          <el-upload action="123" :http-request="upLoad" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="form.headUrl" :src="form.headUrl" style=" width:110px;height:110px;border-radius: 50% " class="marginRight40" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <!-- <div class="PerData_con_image_cover">
              修改头像
            </div> -->
            <div  style="width: 110px;
  height: 110px;
  line-height: 100px;
  font-size: 70px;
  font-weight: 100;
  position: absolute;
  top: -3px;
  border: 2px solid rgb(90, 90, 90);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);" @click.prevent>+</div>
          </el-upload>
          <!-- <img src="../../assets/img/修改头像.png"
               alt=""
               style="width:50px;heght:50px; position: absolute;top:30px;left:30px"> -->
        </div>

      </div>
      <el-form size="small" ref="regForm" :rules="formRules">
        <div class="marginBottom50">
          <div class="display_flex marginBottom20">
            <div class="formTitle">
              手机号:
            </div>
            <div class="formcontent">
              <el-form-item prop="phone">
                <span v-if="form.phone">{{ form.phone }}</span>
                <span v-else @click="bindphone()" class="loubtn">去绑定</span>
              </el-form-item>
            </div>
          </div>
          <div class="display_between marginBottom20">
            <div class="display_center_ud">
              <div class="formTitle">
                昵称:
              </div>
              <div class="formcontent">
                <el-form-item prop="nickname">
                  <el-input class="input" v-model="form.nickname" placeholder="请输入昵称"></el-input>
                </el-form-item>

              </div>
            </div>
            <div class="display_center_ud">
              <div class="formTitle">
                单位:
              </div>
              <div class="formcontent">
                <el-form-item prop="unit">
                  <el-input v-model="form.unit" placeholder="请输入单位" class="input"></el-input>
                </el-form-item>
              </div>
            </div>

          </div>
          <div class="display_between marginBottom20">
            <div class="display_center_ud">
              <div class="formTitle">
                姓名:
              </div>
              <div class="formcontent">
                <el-form-item prop="userName">
                  <el-input class="input" v-model="form.userName" :disabled="userPerInfo.userName!=null&&userPerInfo.userName!=''" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <!-- <input type="text"   v-model="formData.name" value="" class="input"/> -->
              </div>
            </div>
            <div class="display_center_ud">
              <div class="formTitle">
                身份证号:
              </div>
              <div class="formcontent">
                <el-form-item>
                  <el-input class="input" v-model="form.idCard" :disabled="userPerInfo.iDcard!=null&&userPerInfo.iDcard!=''" placeholder="请输入身份证号"></el-input>
                  <!-- <el-input class="input"
                            v-model="form.idCard"
                            placeholder="请输入身份证号"></el-input> -->
                </el-form-item>
              </div>
            </div>
          </div>

          <div class="display_between marginBottom20">
            <div class="display_center_ud">
              <div class="formTitle">
                学历:
              </div>
              <div class="formcontent">
                <el-form-item prop="Educ ation">
                  <el-select class="input" size v-model="form.Education" placeholder="请选择" clearable @focus="getEcujobam(1)">
                    <el-option v-for="item in Eduoption" :key="item.id" :label="item.valueText" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="display_center_ud">
              <div class="formTitle">
                职称:
              </div>
              <div class="formcontent">
                <el-form-item prop="JobTitle">
                  <el-select class="input" size v-model="form.JobTitle" placeholder="请选择" clearable @focus="getEcujobam(2)">
                    <el-option v-for="item in Joboption" :key="item.id" :label="item.valueText" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="display_between marginBottom20">
            <div class="display_center_ud">
              <div class="formTitle">
                科室:
              </div>
              <div class="formcontent">
                <el-form-item prop="AdmiOffice">
                  <el-select class="input" size v-model="form.AdmiOffice" placeholder="请选择" clearable @focus="getEcujobam(3)">
                    <el-option v-for="item in AdmiOfficeData" :key="item.id" :label="item.valueText" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="display_center_ud">
              <div class="formTitle">
                所在地区:
              </div>
              <div class="formcontent">
                <el-form-item prop="AdmiOffice">
                  <el-cascader v-model="form.area" class="input" placeholder="请选择地区" :options="arealist"></el-cascader>
                  <span class="regionspan" v-show="form.area==1&&region!=''" @click.stop>{{region}}</span>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="display_between marginBottom20">
            <div class="display_center_ud">
              <div class="formTitle">
                邮箱:
              </div>
              <div class="formcontent">
                <el-form-item prop="userName">
                  <el-input class="input" v-model="form.mail" placeholder="请输入邮箱"></el-input>
                </el-form-item>
                <!-- <input type="text"   v-model="formData.name" value="" class="input"/> -->
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div class="text_center marginTop30">
        <a href="javascript:void(0);" @click="saveUserInfo()" class="confirmBtn">提 交</a>
      </div>
    </div>
  </div>
</template>

<script>
// import Sidentify from '../identify'
import validate from '../../assets/units/validate';
import axios from 'axios';
// import wxlogin from 'vue-wxlogin';
import { areaList } from '@vant/area-data';
 
export default {
  inject: ['reload'],
  name: 'userData',
  components: {
   
  },
  data() {
    const VALIDATE_MOBILE_NO = (rule, value, callback) => {
      if (!validate.validateMobile(value)) {
        callback(new Error('手机号需为11位数字'));
      } else {
        callback();
      }
    };
    const VALIDATE_IDCORD_NO = (rule, value, callback) => {
      if (!validate.validateIdCode(value)) {
        callback(new Error('请正确输入身份证号'));
      } else {
        callback();
      }
    };
    return {
      redirecturi: 'https://gp519.com/user/userdata',
      apiData: {},
      formData: {
        userId: '',
        phone: '',
        name: '',
        company: '',
        idCard: '',
        education: '',
        profession: '',
        department: ''
      },
      // 微信解绑验证码
      WxBindCloeOrd: '',
      findpswmsg: '',
      DragPhoneBind: false,
      countdown: 60,
      isShowGetCode: true,
      PhonebindShow: true,
      phonebind: false,
      bindsucces: true,
      wxbindShow: false,
      baseURL: window.uploadUrl,
      imageUrl: '',
      fileData: {
        userId: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).id : ''
      },
      form: {
        // 头像
        headUrl: '',
        // 机构认证
        org: '',
        // 医生圈认证
        doctor: '',
        // 医生会员认证
        doctorType: false,
        // 手机号
        phone: '',
        // 姓名
        userName: '',
        //昵称
        nickname: '',
        // 单位
        unit: '',
        // 微信ID
        wxid: '',
        // 学历
        Education: '',
        // 职称
        JobTitle: '',
        // 科室
        AdmiOffice: '',
        //身份证号
        idCard: '',
        mail: '',
        area: ''
      },
      formPhone: {
        BindCell: '',
        PhoneVerCode: ''
      },
      // 学历
      Eduoption: [],
      // 职称
      Joboption: [{}],
      // 科室
      AdmiOfficeData: [],
      rules: [],
      phoneLize: '',
      CerAuthShow: false,
      // 修改头像初始头像
      InitalHead: '../../../../static/timg.png',

      // 登录本地存储数据
      logiUserInfo: JSON.parse(localStorage.getItem('ResData')),
      // 用户个人信息
      userPerInfo: '',
      // 用户id
      AuthorId: '',
      phoneRules: {
        BindCell: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: VALIDATE_MOBILE_NO, trigger: 'blur' }
        ]
      },
      formRules: {
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: VALIDATE_IDCORD_NO, trigger: 'blur' }
        ]
      },
      region: '',
      arealist: [],
      type: 0
    };
  },
  created() {
    if (this.$route.query.type) {
      this.type = Number(this.$route.query.type)
    }
    this.getarealist();
    this.getEcujobam(1);
    this.getEcujobam(2);
    this.getEcujobam(3);
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id;
    }
    this.getUserInfo();
  },
  methods: { 
    getarealist() {
      for (let a in areaList.province_list) {
        this.arealist.push({
          value: a,
          label: areaList.province_list[a],
          children: []
        });
        for (let b in areaList.city_list) {
          if (b.substr(0, 2) == a.substr(0, 2)) {
            this.arealist[this.arealist.length - 1].children.push({
              value: b,
              label: areaList.city_list[b],
              children: []
            });
            for (let c in areaList.county_list) {
              if (c.substr(0, 4) == b.substr(0, 4)) {
                this.arealist[this.arealist.length - 1].children[this.arealist[this.arealist.length - 1].children.length - 1].children.push({
                  value: c,
                  label: areaList.county_list[c]
                });
              }
            }
          }
        }
      }
    },
    onRefresh() { },
    onFail() { },
    // 找回密码发送验证码拉起拖动验证码
    findpswCode() {
      this.$refs.FindpswForm.validate(valid => {
        if (valid) {
          this.PhonebindShow = false;
          this.DragPhoneBind = true;
        }
      });
    },
    // 找回密码拖拽验证码
    onSuccesspsw() {
      var _this = this;
      setTimeout(() => {
        _this.PhonebindShow = true;
        _this.DragPhoneBind = false;
        _this.SendVerCode();
        _this.countDown();
        _this.isShowGetCode = false;
      }, 2000);
    },
    SendVerCode () {
      this.httphelper
        .post('VerifCodeApi/NoteVerifCode', {
          type: 4,
          phoneNumber: this.formPhone.BindCell
        })
        .then(res => {
          if (res.code == 200) {
            this.$message({
              message: '发送成功',
              type: 'success'
            });
          } else if (res.code == 231) {
            this.$message({
              message: '手机号已存在，请直接登录',
              type: 'warning'
            });
            clearInterval(this.timer);
            this.isShowGetCode = true;
          } else if (res.code == 233) {
            this.$message({
              message: '发送失败',
              type: 'warning'
            });
            this.isShowGetCode = true;
            clearInterval(this.timer);
          }
        });
    },
    // 验证码数字变化
    countDown() {
      const self = this;
      this.timer = setInterval(() => {
        self.countdown--;
        if (self.countdown === 0) {
          clearInterval(self.timer);
          self.countdown = 60;
          self.isShowGetCode = true;
        }
      }, 1000);
    },
    ForgeStep() {
      if (this.formPhone.PhoneVerCode == '') {
        this.$message({
          message: '验证码不能为空',
          type: 'warning'
        });
      } else {
        this.httphelper
          .post('VerifCodeApi/NoteVerifCodeOk', {
            type: 4,
            phoneNumber: this.formPhone.BindCell,
            vcode: this.formPhone.PhoneVerCode,
            authorId: this.AuthorId
          })
          .then(res => {
            if (res.code == 234) {
              this.$message({
                message: '验证码失效',
                type: 'warning'
              });
            } else if (res.code == 205) {
              this.$message({
                message: '验证码错误',
                type: 'warning'
              });
            } else {
              this.$message({
                message: '绑定成功',
                type: 'success'
              });
              this.phonebind = false;
              this.reload();
            }
          });
      }
    },

    // 绑定手机号
    bindphone() {
      this.phonebind = true;
    },

    closephoneBind() {
      this.phonebind = false;
      this.reload();
    },
    // 获取学历、职称、科室
    getEcujobam(type) {
      axios.get('BasicsmesApi/GetCodeResultList?type=' + type).then(res => {
        if (type == 1) {
          this.Eduoption = res.data.data;
        } else if (type == 2) {
          this.Joboption = res.data.data;
        } else if (type == 3) {
          this.AdmiOfficeData = res.data.data;
        }
      });
    },
    // 手机号中间四位隐藏
    mobileStr(str) {
      if (str.length > 7) {
        return str.substring(0, 3) + '****' + str.substring(7, str.length);
      } else {
        return str.substring(0, str.length - 1) + '****';
      }
    },
    // 获取用户个人信息
    getUserInfo() {
      this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: parseInt(this.AuthorId)
        })
        .then(res => {
          // 绑定微信 
          this.userPerInfo = res.data;
          this.form.headUrl = this.userPerInfo.avatarUrl;
          if (this.userPerInfo.mobile) {
            this.form.phone = this.mobileStr(this.userPerInfo.mobile);
          } else {
            this.form.phone = '';
          }
          this.form.nickname = this.userPerInfo.nickname
          this.form.userName = this.userPerInfo.userName;
          this.form.unit = this.userPerInfo.unit;
          this.form.org = this.userPerInfo.org;
          this.form.doctor = this.userPerInfo.doct;

          this.form.Education = this.userPerInfo.educatId;
          this.form.JobTitle = this.userPerInfo.professionalId;
          this.form.AdmiOffice = this.userPerInfo.admoffId;
          this.form.idCard = this.userPerInfo.iDcard;
          this.form.mail = this.userPerInfo.mail
          this.form.area = 1

          // 医生会员认证
          if (this.userPerInfo.doctorType == 0) {
            this.form.doctorType = true;
          } else if (this.userPerInfo.doctorType == 1 || this.userPerInfo.doctorType == 9) {
            this.form.doctorType = false;
          }

          // // 学历
          if (this.userPerInfo.educatId == 0) {
            this.form.Education = '';
          } else {
            this.form.Education = this.userPerInfo.educatId;
          }
          // 职称
          if (this.userPerInfo.professionalId == 0) {
            this.form.JobTitle = '';
          } else {
            this.form.JobTitle = this.userPerInfo.professionalId;
          }
          // 科室
          if (this.userPerInfo.admoffId == 0) {
            this.form.AdmiOffice = '';
          } else {
            this.form.AdmiOffice = this.userPerInfo.admoffId;
          }
          for (let a in areaList.province_list) {
            if (a == this.userPerInfo.province) {
              this.region = areaList.province_list[a]
            }
          }
          for (let a in areaList.city_list) {
            if (a == this.userPerInfo.city) {
              this.region = this.region + ' / ' + areaList.city_list[a]
            }
          }
          for (let a in areaList.county_list) {
            if (a == this.userPerInfo.district) {
              this.region = this.region + ' / ' + areaList.county_list[a]
            }
          }
        })
    },
    // 修改个人基本信息
    // type-1 基本信息 type-2 个签修改
    saveUserInfo() {
      if (this.type == 1) {
        if (this.form.userName == '' || this.form.userName == null) return this.$message({
          message: '请输入姓名',
          type: 'warning'
        });
        if (this.form.idCard == '' || this.form.idCard == null) return this.$message({
          message: '请输入身份证号',
          type: 'warning'
        });
        if (this.form.unit == '' || this.form.unit == null) return this.$message({
          message: '请输入单位',
          type: 'warning'
        });
        if (this.region == '' && this.form.area == 1) return this.$message({
          message: '请选择所在地区',
          type: 'warning'
        });
      }
      if (this.form.idCard != null && this.form.idCard != '') {
        if (!validate.validateIdCode(this.form.idCard)) {
          this.$message({
            message: '请正确输入身份证号',
            type: 'warning'
          });
          return false;
        }
      }
      if (this.form.area == 1) {
        this.form.area = [this.userPerInfo.province, this.userPerInfo.city, this.userPerInfo.district]
      }
      var Education = 0,
        AdmiOffice = 0,
        JobTitle = 0;
      if (this.form.Education == '') {
        Education = 0;
      } else {
        Education = this.form.Education;
      }
      if (this.form.AdmiOffice == '') {
        AdmiOffice = 0;
      } else {
        AdmiOffice = this.form.AdmiOffice;
      }
      if (this.form.JobTitle == '') {
        JobTitle = 0;
      } else {
        JobTitle = this.form.JobTitle;
      }
      this.httphelper
        .post('CGpUserApi/UpdUserMessage', {
          id: this.AuthorId,
          type: 2,
          mobile: this.form.phone,
          nickname: this.form.nickname == null ? '' : this.form.nickname,
          introduction: 'string',
          unit: this.form.unit,
          userName: this.form.userName == null ? '' : this.form.userName,
          educatId: Education,
          admoffId: AdmiOffice,
          professionalId: JobTitle,
          idCard: this.form.idCard == null ? '' : this.form.idCard,
          province: this.form.area[0],
          city: this.form.area[1],
          district: this.form.area[2],
          mail: this.form.mail == null ? '' : this.form.mail,
        })
        .then(res => {
          if (res.code == 200) {
            this.$message({
              message: '提交成功',
              type: 'success'
            });
            this.$router.push({ name: 'userinfo' });
          } else {
            this.$message({
              message: '提交失败',
              type: 'warning'
            });
          }
        });
    },
    // 修改个性签名
    Modifsign() { },

    upLoad(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('Authorid', this.AuthorId);
      formData.append('OfType', 1);
      this.httphelper.post('OssFileApi/ImageUpload', formData).then(res => {
        if (res.code) {
          this.$message({
            message: '头像上传成功',
            type: 'success'
          });
          this.form.headUrl = res.data.src;
          this.reload();
        } else {
          this.$message('头像上传失败失败');
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.headUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 PNG 格式 或者 JPG!');
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return (isJPG || isPNG) && isLt10M;
    }
  }
};
</script>

<style scoped>
.regionspan {
  color: #606266;
  font-size: inherit;
  background-color: #fff;
  position: absolute;
  height: 30px;
  line-height: 30px;
  left: 15px;
  top: 2px;
}
.formWrap {
  position: relative;
}
.input {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
}
.formcontent {
  width: 377px;
}
.formTitle {
  margin-right: 20px;
  text-align: right;
  /* width: 60px; */
  margin-bottom: 17px;
}
.loubtn {
  display: inline-block;
  width: 70px;
  height: 27px;
  border: 1px solid #0b8586;
  border-radius: 13px;
  text-align: center;
  color: #0b8586;
  line-height: 27px;
}
.confirmBtn {
  width: 200px;
  height: 40px;
  background: #0b8586;
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #fff;
}
.ercodedialog_cover {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(000, 000, 000, 0.3);
  z-index: 999;
  left: 0;
  top: 0;
}
.ercodedialog_cover_main {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* 手机号绑定 */
.Retrpsw_cont {
  width: 562px;
  height: 360px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.Retrpsw_cont_close {
  width: 100%;
  height: 20px;
  text-align: right;
  line-height: 20px;
  position: relative;
}
.iconfont >>> icon-guanbi {
  color: #ffffff;
  position: absolute;
  font-size: 35px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.5s ease-out;
  -moz-transition: -moz-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  font-weight: bold;
}
.iconfont >>> icon-guanbi:hover {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
}
.Retrpsw_cont_main {
  width: 100%;
  height: 336px;
  background: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: relative;
}
.Retrpsw_cont_main_cont {
  width: 75%;
  margin: 0 auto;
  position: relative;
  top: 35px;
}
.main_form >>> .el-input__inner {
  border: none;
  height: 42px;
  line-height: 42px;
}

.Retrpsw_div {
  width: 404px;
  height: 42px;
  border: 1px solid #ebebeb;
  margin-bottom: 27px;
  line-height: 42px;
  position: relative;
}
.ReStep {
  background: #167eff;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 42px;
}
.RetrCode >>> .el-input {
  width: 286px;
  border: 1px solid #ebebeb;
}
.RetrCode {
  border: none;
}
.sendCode {
  width: 104px;
  height: 40px;
  border: 1px solid #157eff;
  background: #e9f5ff;
  float: right;
  color: #157eff;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.Retrpsw_cont_main_cont_resh {
  width: 53%;
  margin: 0 auto;
  position: relative;
  top: 34px;
}

.Retrpsw_cont_main_head {
  width: 100%;
  height: 50px;
}
.Retrpsw_cont_main_cont_resh {
  width: 53%;
  margin: 0 auto;
  position: relative;
  top: 34px;
} 
</style>
