var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),(_vm.Livelist.startlive.length > 0)?_c('div',_vm._l((_vm.Livelist.startlive),function(item,index){return _c('div',{key:index + '1',staticClass:"display_between marginTop20"},[_vm._l((item),function(itemx,indexx){return _c('div',{key:indexx + '11',staticClass:"pub_square",on:{"click":function($event){return _vm.meetinginfo(itemx.live_id)}}},[_c('div',{staticClass:"pubs_img"},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":Date.parse(itemx.start_time) < new Date() && Date.parse(itemx.end_time) > new Date() ? require('../../../assets/img/live/live.png')
              : Date.parse(itemx.end_time) < new Date() && !itemx.isPlayback ? require('../../../assets/img/live/over.png')
                  : Date.parse(itemx.end_time) < new Date() && itemx.isPlayback ? require('../../../assets/img/live/playback.png')
                      : Date.parse(itemx.start_time) > new Date() ? require('../../../assets/img/live/notice.png') : require('../../../assets/img/live/live.png')}}),_c('img',{staticClass:"pubs_img",attrs:{"src":itemx.coverPictureUrl}})]),_c('div',{staticClass:"pubs_body"},[_c('div',{staticClass:"pubs_title",attrs:{"title":itemx.title}},[_vm._v(_vm._s(_vm.ellipsis(itemx.title, 17))+" "+_vm._s(itemx.id))])]),(itemx.org_name!=null && itemx.org_name!='')?_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',{},[_c('img',{attrs:{"src":itemx.orgLogoUrl,"width":"18px"}})]),_c('span',{staticStyle:{"position":"absolute","color":"#000","margin-left":"10px","line-height":"20px"},attrs:{"title":itemx.org_name}},[_vm._v(_vm._s(_vm.ellipsis(itemx.org_name, 17)))])])]):_vm._e(),_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',[_vm._v("直播时间:")]),_c('span',{staticClass:"normalColor"},[_vm._v(" "+_vm._s(_vm.formatTime(itemx.start_time, 'YYYY-MM-DD'))+" 至 "+_vm._s(_vm.formatTime(itemx.end_time, 'YYYY-MM-DD')))])])])])}),_vm._l((3 - item.length),function(zhw){return _c('div',{key:zhw,staticClass:"pub_square1"})})],2)}),0):_vm._e(),(_vm.Livelist.liveall.length == 0)?_c('div',[_vm._m(1)]):_vm._e(),(_vm.Livelist.waitlive.length > 0)?_c('div',{staticClass:"gl_title_bar marginBottom10 marginTop20"},[_vm._m(2)]):_vm._e(),_vm._l((_vm.Livelist.waitlive),function(item,index){return _c('div',{key:index + '2',staticClass:"display_between marginTop20"},[_vm._l((item),function(itemx,indexx){return _c('div',{key:indexx + '21',staticClass:"pub_square",on:{"click":function($event){return _vm.meetinginfo(itemx.live_id)}}},[_c('div',{staticClass:"pubs_img"},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":Date.parse(itemx.start_time) < new Date() && Date.parse(itemx.end_time) > new Date() ? require('../../../assets/img/live/live.png')
          : Date.parse(itemx.end_time) < new Date() && !itemx.isPlayback ? require('../../../assets/img/live/over.png')
              : Date.parse(itemx.end_time) < new Date() && itemx.isPlayback ? require('../../../assets/img/live/playback.png')
                  : Date.parse(itemx.start_time) > new Date() ? require('../../../assets/img/live/notice.png') : require('../../../assets/img/live/live.png')}}),_c('img',{staticClass:"pubs_img",attrs:{"src":itemx.coverPictureUrl}})]),_c('div',{staticClass:"pubs_body"},[_c('div',{staticClass:"pubs_title",attrs:{"title":itemx.title}},[_vm._v(_vm._s(_vm.ellipsis(itemx.title, 17)))])]),(itemx.org_name!=null && itemx.org_name!='')?_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',{},[_c('img',{attrs:{"src":itemx.orgLogoUrl,"width":"18px"}})]),_c('span',{staticStyle:{"position":"absolute","color":"#000","margin-left":"10px","line-height":"20px"},attrs:{"title":itemx.org_name}},[_vm._v(_vm._s(_vm.ellipsis(itemx.org_name, 17)))])])]):_vm._e(),_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',[_vm._v("直播时间:")]),_c('span',{staticClass:"normalColor"},[_vm._v(" "+_vm._s(_vm.formatTime(itemx.start_time, 'YYYY-MM-DD'))+" 至 "+_vm._s(_vm.formatTime(itemx.end_time, 'YYYY-MM-DD')))])])])])}),_vm._l((3 - item.length),function(zhw){return _c('div',{key:zhw,staticClass:"pub_square1"})})],2)}),(_vm.Livelist.backlive.length > 0)?_c('div',{staticClass:"gl_title_bar marginBottom10 marginTop20"},[_vm._m(3)]):_vm._e(),_vm._l((_vm.Livelist.backlive),function(item,index){return _c('div',{key:index + '4',staticClass:"display_between marginTop20"},[_vm._l((item),function(itemx,indexx){return _c('div',{key:indexx + '31',staticClass:"pub_square",on:{"click":function($event){return _vm.meetinginfo(itemx.live_id)}}},[_c('div',{staticClass:"pubs_img"},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":Date.parse(itemx.start_time) < new Date() && Date.parse(itemx.end_time) > new Date() ? require('../../../assets/img/live/live.png')
          : Date.parse(itemx.end_time) < new Date() && !itemx.isPlayback ? require('../../../assets/img/live/over.png')
              : Date.parse(itemx.end_time) < new Date() && itemx.isPlayback ? require('../../../assets/img/live/playback.png')
                  : Date.parse(itemx.start_time) > new Date() ? require('../../../assets/img/live/notice.png') : require('../../../assets/img/live/live.png')}}),_c('img',{staticClass:"pubs_img",attrs:{"src":itemx.coverPictureUrl}})]),_c('div',{staticClass:"pubs_body"},[_c('div',{staticClass:"pubs_title",attrs:{"title":itemx.title}},[_vm._v(_vm._s(_vm.ellipsis(itemx.title, 17)))])]),(itemx.org_name!=null && itemx.org_name!='')?_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',{},[_c('img',{attrs:{"src":itemx.orgLogoUrl,"width":"18px"}})]),_c('span',{staticStyle:{"position":"absolute","color":"#000","margin-left":"10px","line-height":"20px"},attrs:{"title":itemx.org_name}},[_vm._v(_vm._s(_vm.ellipsis(itemx.org_name, 17)))])])]):_vm._e(),_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',[_vm._v("直播时间:")]),_c('span',{staticClass:"normalColor"},[_vm._v(" "+_vm._s(_vm.formatTime(itemx.start_time, 'YYYY-MM-DD'))+" 至 "+_vm._s(_vm.formatTime(itemx.end_time, 'YYYY-MM-DD')))])])])])}),_vm._l((3 - item.length),function(zhw){return _c('div',{key:zhw,staticClass:"pub_square1"})})],2)}),(_vm.Livelist.endlive.length > 0)?_c('div',{staticClass:"gl_title_bar marginBottom10 marginTop20"},[_vm._m(4)]):_vm._e(),_vm._l((_vm.Livelist.endlive),function(item,index){return _c('div',{key:index + '3',staticClass:"display_between marginTop20"},[_vm._l((item),function(itemx,indexx){return _c('div',{key:indexx + '31',staticClass:"pub_square",on:{"click":function($event){return _vm.meetinginfo(itemx.live_id)}}},[_c('div',{staticClass:"pubs_img"},[_c('img',{staticStyle:{"position":"absolute"},attrs:{"src":Date.parse(itemx.start_time) < new Date() && Date.parse(itemx.end_time) > new Date() ? require('../../../assets/img/live/live.png')
          : Date.parse(itemx.end_time) < new Date() && !itemx.isPlayback ? require('../../../assets/img/live/over.png')
              : Date.parse(itemx.end_time) < new Date() && itemx.isPlayback ? require('../../../assets/img/live/playback.png')
                  : Date.parse(itemx.start_time) > new Date() ? require('../../../assets/img/live/notice.png') : require('../../../assets/img/live/live.png')}}),_c('img',{staticClass:"pubs_img",attrs:{"src":itemx.coverPictureUrl}})]),_c('div',{staticClass:"pubs_body"},[_c('div',{staticClass:"pubs_title",attrs:{"title":itemx.title}},[_vm._v(_vm._s(_vm.ellipsis(itemx.title, 17)))])]),(itemx.org_name!=null && itemx.org_name!='')?_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',{},[_c('img',{attrs:{"src":itemx.orgLogoUrl,"width":"18px"}})]),_c('span',{staticStyle:{"position":"absolute","color":"#000","margin-left":"10px","line-height":"20px"},attrs:{"title":itemx.org_name}},[_vm._v(_vm._s(_vm.ellipsis(itemx.org_name, 17)))])])]):_vm._e(),_c('div',{staticClass:"display_between"},[_c('div',{staticClass:"pubs_tip pubs_body"},[_c('span',[_vm._v("直播时间:")]),_c('span',{staticClass:"normalColor"},[_vm._v(" "+_vm._s(_vm.formatTime(itemx.start_time, 'YYYY-MM-DD'))+" 至 "+_vm._s(_vm.formatTime(itemx.end_time, 'YYYY-MM-DD')))])])])])}),_vm._l((3 - item.length),function(zhw){return _c('div',{key:zhw,staticClass:"pub_square1"})})],2)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl_title_bar marginBottom10"},[_c('div',{staticClass:"gl_tb_left"},[_c('span',{staticClass:"normaltitle"},[_vm._v("直播中")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"NoneData"},[_c('p',[_c('span',[_vm._v("您还没有报名过任何直播！")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl_tb_left"},[_c('span',{staticClass:"normaltitle"},[_vm._v("待开始")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl_tb_left"},[_c('span',{staticClass:"normaltitle"},[_vm._v("回放中")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gl_tb_left"},[_c('span',{staticClass:"normaltitle"},[_vm._v("已结束")])])
}]

export { render, staticRenderFns }