<template>
  <div class="container">
    <header-view :activeIndex="activeIndex"></header-view>
    <swiper class="swiper"
            :options="options"
            ref="swiper"
            @slideChange="slideChange">
      <swiper-slide>
        <top-one @tojump="tojump"></top-one>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <top-two-vue></top-two-vue>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <top-three-vue></top-three-vue>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <top-four-vue></top-four-vue>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <top-five-vue></top-five-vue>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <top-six-vue></top-six-vue>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <top-seven-vue></top-seven-vue>
      </swiper-slide>
      <swiper-slide class="swiper-no-swiping">
        <top-eight-vue :activeIndex="activeIndex"></top-eight-vue>
      </swiper-slide>
      <swiper-slide>
        <top-nine-vue></top-nine-vue>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination"></div>
    <div class="top-view"
         v-if="activeIndex > 0">
      <div v-for="(item, index) in navList"
           :key="index"
           class="item"
           :class="{ 'active': activeIndex == (index + 1) }"
           @click="tojump(index + 1)">
        {{ item }}
      </div>
    </div>
    <div class="fanhui"
         v-show="activeIndex > 0"
         @click="top">
      <img src="../assets/icons/sanjiao5.png"
           alt="">
      TOP
    </div>
    <div v-if="ipbah"
         :class="activeIndex == 0 || activeIndex == 2 || activeIndex == 5 ? 'right-icon' : 'block_icon'">
      <div>C</div>
      <div>G</div>
      <div>P</div>
      <div>H</div>
      <div>O</div>
      <div>M</div>
      <div>E</div>
      <div>.</div>
      <div>C</div>
      <div>O</div>
      <div>M</div>
      <img src="../assets/icon/biao.png"
           alt=""
           v-if="activeIndex == 0 || activeIndex == 2 || activeIndex == 5">
      <img src="../assets/icon/black_biao.png"
           alt=""
           v-else>
    </div>
    <div v-if="!ipbah"
         :class="activeIndex == 0 || activeIndex == 2 || activeIndex == 5 ? 'right-icon' : 'block_icon'">
      <div>C</div>
      <div>G</div>
      <div>P</div>
      <div>S</div>
      <div>C</div>
      <div>I</div>
      <div>.</div>
      <div>O</div>
      <div>R</div>
      <div>G</div>
      <div>.</div>
      <div>C</div>
      <div>N</div>
      <img src="../assets/icon/biao.png"
           alt=""
           v-if="activeIndex == 0 || activeIndex == 2 || activeIndex == 5">
      <img src="../assets/icon/black_biao.png"
           alt=""
           v-else>
    </div>
    <img :src="activeIndex == 0 || activeIndex == 2 || activeIndex == 5 ? require('../assets/icon/xia_bai.png') : require('../assets/icon/xia.png')"
         alt=""
         v-if="activeIndex != 8"
         class="bottom_icon"
         @click="iconClick">
  </div>
</template>

<script>
import HeaderView from '@/components/header/HeaderView.vue'
import TopOne from '@/components/home/TopOne.vue'
import TopTwoVue from '@/components/home/TopTwo.vue'
import TopThreeVue from '@/components/home/TopThree.vue'
import TopFourVue from '@/components/home/TopFour.vue'
import TopFiveVue from '@/components/home/TopFive.vue'
import TopSixVue from '@/components/home/TopSix.vue'
import TopSevenVue from '@/components/home/TopSeven.vue'
import TopEightVue from '@/components/home/TopEight.vue'
import TopNineVue from '@/components/home/TopNine.vue'

export default {
  components: { HeaderView, TopOne, TopTwoVue, TopThreeVue, TopFourVue, TopFiveVue, TopSixVue, TopSevenVue, TopEightVue, TopNineVue },
  data () {
    return {
      options: {
        mousewheel: {
          thresholdTime: 1500,
        },
        direction: 'vertical',
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },

      videoList: [],

      activeIndex: 0,

      params: {
        navId: 36,
        courseType: 0,
        orderType: 0,
        page: 1,
        limit: 4
      },

      navList: ['科研选题与设计', '文献检索', '量表工具', '数据统计', '论文写作', '翻译工具', '期刊查询', '科研专家'],
      ipbah: true
    }
  },

  created () {
    // this.getList()
    if (window.location.host.indexOf('cgpsci.org.cn') > -1) {
      this.ipbah = false
    } else {
      this.ipbah = true
    }
  },

  methods: {
    tojump (index) {
      this.$refs.swiper.swiper.slideTo(index, 1000, false)
    },

    slideChange () {
      this.activeIndex = this.$refs.swiper.swiper.activeIndex
    },

    top () {
      this.tojump(0)
    },

    iconClick () {
      this.activeIndex = this.activeIndex + 1
      this.tojump(this.activeIndex)
    }

    // getList () {
    //   this.httphelper.post('/CourseApi/GetNaviCoursePageList', this.params).then(res => {
    //     this.videoList = res.data.splice(0, 2)
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.swiper-container {
  height: 100vh;
}
.swiper-pagination {
  top: 50%;
  transform: translate(-50%);
  left: 20px;
  width: 24px;
}
.swiper-pagination ::v-deep .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  display: block;
  margin: 8px auto;
}
.swiper-pagination ::v-deep .swiper-pagination-bullet-active {
  background: #0b8586;
  width: 100%;
  height: 8px;
  border-radius: 4px;
}
.fanhui {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 9;
  font-size: 16px;
  font-family: Arial;
  font-weight: bold;
  color: #0b8586;
  text-align: center;
  cursor: pointer;
}
.top-view {
  position: fixed;
  top: 100px;
  transform: translate(-50%);
  left: 50%;
  z-index: 9;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item {
    cursor: pointer;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000;
  }
  .active {
    padding: 0 20px;
    height: 36px;
    line-height: 36px;
    background: #0b8586;
    border-radius: 18px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.right-icon,
.block_icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(-50%);
  width: 16px;
  z-index: 9;
  div {
    font-size: 10px;
    width: 100%;
    text-align: center;
    font-family: Arial;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    opacity: 0.5;
    transform: rotate(90deg);
  }
  img {
    width: 100%;
    margin-top: 30px;
  }
}
.block_icon {
  div {
    color: #000;
  }
}
.bottom_icon {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  width: 16px;
  height: 12px;
  cursor: pointer;
}
@media screen and (max-width: 1900px) {
  .top-view {
    position: fixed;
    top: 100px;
    transform: translate(-50%);
    left: 50%;
    z-index: 9;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000;
    }
    .active {
      padding: 0 20px;
      height: 36px;
      line-height: 36px;
      background: #0b8586;
      border-radius: 18px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>