<template>
  <div class="container">
    <div class="title">翻译工具</div>
    <div class="content">英文基础差，读不懂文献，写不出论文？——“翻译工具箱”，汇集好用又精准的翻译工具</div>
    <div class="main">
      <div class="main-right-bottom"
           v-if="list.length > 0">
        <swiper :options="option">
          <swiper-slide v-for="(item, index) in list"
                        :key="index">
            <div class="body"
                 @click="itemClick(item)">
              <img :src="item.slideShow"
                   class="logo"
                   alt="">
              <div class="child-title">{{ item.title }}</div>
              <div class="child-content">{{ item.abstract }}</div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="t5prevbutton el-icon-arrow-left"></div>
        <div class="t5nextbutton el-icon-arrow-right"></div>
      </div>
      <div class="empty"
           v-else>
        <img src="../../assets/image/empty.png"
             alt="">
      </div>
    </div>
    <div class="more"
         v-if="list.length > 0"
         @click="toMore">查看更多工具</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      option: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 20,
        navigation: {
          nextEl: '.t5nextbutton',
          prevEl: '.t5prevbutton',
          disabledClass: 't5-button-disabled'
        }
      },
      list: []
    }
  },

  created () {
    this.getColumn()
  },

  methods: {
    // 获取栏目
    getColumn () {
      let params = {
        c_Id: this.httphelper.baseApi() == 'https://apitest.gp519.com/api/' ? 219 : 257,
        isCHorEN: 1
      }
      this.httphelper.post('/ScientificApi/GetColumsInfoAllList', params).then(res => {
        if (res.code == 200) {
          let data = res.data
          data.forEach(item => {
            let params = {
              keyword: '',
              c_Id: item.id,
              c_PId: 0,
              userId: 0,
              pageIndex: 1,
              pageSize: 10
            }
            this.httphelper.post('/ScientificApi/GetSRNewInfoPageList', params).then(res => {
              this.list = res.data
            })
          });
        }
      })
    },

    itemClick (item) {
      if (item.address) {
        window.open(item.address, '_blank')
      } else {
        const { href } = this.$router.resolve({
          path: '/inforDetails',
          query: {
            id: item.id,
            column_Type: 0
          }
        });
        window.open(href, '_blank')
      }
    },

    toMore () {
      this.$router.push({
        path: '/tool#top6',
        // query: {
        //   id: 3
        // }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/image/banner7.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 220px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
  }
  .content {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-top: 18px;
  }
  .main {
    width: 80%;
    height: 360px;
    margin: 70px auto;
    position: relative;
    .main-right-bottom {
      width: 100%;
      position: relative;
      .logo {
        width: 100%;
        // height: 122px;
      }
      .child-title {
        padding: 0 30px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        text-align: center;
        margin: 30px 0 24px 0;
      }
      .child-content {
        padding: 0 30px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .el-icon-arrow-left,
      .el-icon-arrow-right {
        width: 37px;
        height: 37px;
        background: #0b8586;
        text-align: center;
        line-height: 37px;
        border-radius: 50%;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
      }
      .el-icon-arrow-left {
        position: absolute;
        top: 170px;
        left: -55px;
        z-index: 9;
      }
      .el-icon-arrow-right {
        position: absolute;
        top: 170px;
        right: -55px;
        z-index: 9;
      }
      .t5-button-disabled {
        color: #0b8586;
        background: #fff;
        border: 1px solid #0b8586;
      }
    }
  }
}
.body {
  width: 100%;
  height: 360px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 7px 19px 30px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
}
.empty {
  width: 435px;
  height: 211px;
  margin: 100px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.more {
  width: 200px;
  height: 37px;
  border: 1px solid #0b8586;
  border-radius: 19px;
  margin: 0 auto;
  text-align: center;
  line-height: 37px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0b8586;
  cursor: pointer;
}
.more:hover {
  background: #0b8586;
  color: #fff;
}
// @media screen and (max-width: 1900px) {
//   .container {
//     padding-top: 130px;
//     .title {
//       font-size: 28px;
//       margin-top: 40px;
//     }
//     .main {
//       margin: 30px auto;
//     }
//   }
//   .container .body {
//     width: 100%;
//     height: 300px;
//     background: #fff;
//     border-radius: 8px;
//   }
// }
</style>