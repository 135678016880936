<template>
  <div>
    <div class="gl_title_bar marginBottom10">
      <div class="gl_tb_left">
        <span class="normaltitle">我的发票</span>
      </div>
    </div>
    <div class="gl_title_vertical_line gl_title_v_line_active_gree">
      温馨提示
    </div>
    <p class="marginTop10">1.付款成功后，请在本年度内完成发票申请；超出本年度，系统将无法开具发票；</p>
    <p class="marginTop10">2.如有问题请联系客服，联系电话：010-83116339。</p>

    <div v-if="tableData.length == 0"
         class="NoneData">
      <p>
        <i class="iconfont icon-wushujutanhao"></i>
        <span>暂无相关发票信息</span>
      </p>
    </div>
    <table v-else
           class="gl_table marginTop20">
      <tr>
        <th>序号</th>
        <th>服务名称</th>
        <th>下单时间</th>
        <th>订单号</th>
        <th>发票类型</th>
        <th>实付金额</th>
        <th>开票状态</th>
        <!-- <th>商品详情</th> -->
        <th>操作</th>
      </tr>
      <tr v-for="(item,index) in tableData"
          :key="index">
        <td>{{index+1}}</td>
        <td>服务费</td>
        <td>{{item.createDate}}</td>
        <td>{{item.iOrders}}</td>
        <td>{{item.invoiceType==1?'普通发票':'其他'}}</td>
        <td>￥{{item.totalAmount}}</td>
        <td>{{item.istatus=='0'?'正在开票':'开票完成'}}</td>
        <!-- <td>
          <a href="javascript:;"
             style="color:#0B8586">查看</a>
        </td> -->
        <td>
          <a style="color:#C32424"
             v-if="item.istatus == 0"
             @click="EditInvoice(item.id)">编辑</a>
          <a v-if="item.istatus != 0"
             style="color:#0B8586"
             @click="tomenudetgil(item.id)">发票详情</a>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
export default {
  name: "userMyInvoice",
  data () {
    return {
      tableData: []
    }
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id
    }
    this.getMyInvoice()
  },
  methods: {
    // 获取我的发票信息
    getMyInvoice () {
      this.httphelper.post('InvoiceApi/GetInvoiceInfoPageList', {
        userId: this.AuthorId,
        state: -1,
        page: 1,
        limit: 10
      }).then((res) => {
        this.tableData = res.data
      })
    },
    // 点击编辑跳转
    EditInvoice (id) {
      this.$router.push({
        path: '/user/Personal/OpenInvoice',
        query: {
          id: id,
          type: 'edit',
          name: '修改发票信息'
        }
      })
    },
    // 跳转发票详情
    tomenudetgil (id) {
      this.$router.push({
        path: '/user/Personal/Invoicedetail',
        query: {
          id: id,
          name: '发票信息'
        }
      })
    }
  }
}
</script>

<style scoped>
.NoneData {
  padding: 40px;
  text-align: center;
  color: #999;
  font-size: 18px;
  letter-spacing: 1px;
}
.NoneData i {
  font-size: 36px;
  color: #4da9e8;
  position: relative;
  top: 7px;
  margin-right: 7px;
}
.gl_table td {
  width: 1%;
}
.gl_table a {
  cursor: pointer;
}
</style>
