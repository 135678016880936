<template>
  <div>
    <div class="paymentcover"
         v-show="ZomCover">
      <div class="paymentcover_main">
        <div class="paymentcover_close"
             @click="ZomClose()">
          <i class="iconfont icon-guanbi"></i>
        </div>
        <div class="payment_body"
             v-show="Paymentgote">
          <div class="PayCont_body_notice">
            <span>选择支付方式</span>
          </div>
          <div class="PayMethods">
            <p>订单号：{{ ordernum }}</p>
            <div class="PayMethods_list">
              <li>
                <el-radio v-model="radio"
                          label="1">
                  <i class="iconfont icon-weixin1"></i>
                  <span>微信</span>
                </el-radio>
              </li>
              <li>
                <el-radio v-model="radio"
                          label="2">
                  <i class="iconfont icon-umidd17"></i>
                  <span>支付宝</span>
                </el-radio>
              </li>
            </div>
          </div>
          <div class="paysubmit"
               @click.once="paySubmit">确定</div>
        </div>
        <div class="payment_body"
             v-show="PaymentCode">
          <div class="PayMethods"
               style="border-top:none;margin-top:0px;">
            <p>{{ ordersType == 1 ? '课程购买' : '会员充值' }}</p>
          </div>
          <div class="PayMethods"
               style="border-top:none;margin-top:0px;">
            <p>支付金额：{{ ordertotalFee }} 元</p>
          </div>
          <div class="PayMethods"
               style="border-top:none;margin-top:0px;">
            <p>商品订单：{{ ordernum }}</p>
          </div>
          <!-- 付款二维码 -->
          <div class="PayCont_body_PayCode"
               v-loading="isloading"
               id="wxpay"></div>
        </div>
      </div>
    </div>
    <div class="gl_title_bar">
      <div class="gl_tb_left">
        <span class="normaltitle">我的订单</span>
      </div>
    </div>
    <div class="marginTop20 gl_top2"
         @click.stop>
      <span>订单状态：</span>
      <el-select v-model="theOrderStatus"
                 placeholder="待付款">
        <el-option v-for="(item, index) in orderStatus"
                   :key="index + 'ggg'"
                   :label="item.label"
                   :value="item.id"></el-option>
      </el-select>
      <span class="zhifufangshi">支付方式：</span>
      <el-select v-model="theOorderType"
                 placeholder="微信支付">
        <el-option v-for="(item2, index) in orderType"
                   :key="index + 'b'"
                   :label="item2.label"
                   :value="item2.id"></el-option>
      </el-select>
      <input type="text"
             placeholder="订单号"
             class="tableKcInput"
             v-model="kcname" />
      <button class="tableKcBtn"
              @click="tosx">筛选</button>
      <img src="../../../assets/img/icon/sousuo.png"
           class="sousuo"
           alt=""
           srcset="" />
    </div>
    <div v-if="tableDataList.length == 0"
         class="NoneData">
      <p>
        <i class="iconfont icon-wushujutanhao"></i>
        <span>暂无相关订单</span>
      </p>
    </div>
    <div v-else>
      <div class="obox"
           v-for="(item, index) in tableDataList"
           :key="index">
        <div class="obtop">
          <div class="obtleft">
            <img src="../../../assets/img/icon/订单.png"
                 alt="">
            <span>订单编号：</span>
            <span>{{ item.orderNumber }}</span>
            <span class="obtime">{{ item.createTime }}</span>
          </div>
          <div class="obtright">
            <span v-show="item.invoiceType == 1 && item.status == 20">未开发票</span>
            <span v-show="item.invoiceType == 9 && item.status == 20">已开发票</span>
            <span v-show="item.invoiceType == 2 && item.status == 20">开发票中</span>
            <!-- <span v-show="item.invoiceType == -1">订单作废</span> -->
            <!-- <span v-show="item.invoiceType == 0">不可开票</span> -->
            <span v-show="item.status == 10"
                  style="color:#C32424;margin-left:30px">未支付</span>
            <span v-show="item.status == 20"
                  style="margin-left:30px">交易完成</span>
            <span v-show="item.status == 60"
                  style="margin-left:30px">交易关闭</span>
          </div>
        </div>
        <div class="obmid">
          <div class="obmbox obmbox1"
               style="border:none">
            <div class="obsbox"
                 :style="indexs == 0 ? 'padding-top:0;margin-top:0;border:none' : ''"
                 @click="TomenuDetail(items)"
                 v-for="(items, indexs) in item.ordersDetaList"
                 :key="indexs">
              <img :src="items.coverPicture"
                   alt="">
              <div class="display_column_between"
                   style="padding:3px 0">
                <p>{{ items.courseName }}</p>
                <p style="color:#B70109">￥{{ items.totalFee.toFixed(2) }}<span v-show="item.ordersType == 3"
                        style="color:#0b8586"> x {{
                                            item.countNumber
                                        }}</span></p>
              </div>
            </div>
          </div>
          <div class="obmbox obmbox2">
            <span>￥{{ item.totalFee.toFixed(2) }}</span>
          </div>
          <div class="obmbox obmbox3">
            <div v-show="item.status == 10"
                 style="color:#fff;background-color: #F34343;"
                 @click="ToPayMent(item)">去支付</div>
            <div v-show="item.status != 20"
                 @click="todel(item)">删除订单</div>
            <div v-show="item.invoiceType == 1 && item.status == 20"
                 :style="getIsOverdue(item.createTime.substring(0, 4)) ? 'color:#fff;background-color: #0B8586;' : ''"
                 @click="ApplyMakeStart(item.ordesId, item.totalFee.toFixed(2), item.createTime.substring(0, 4))">
              申请发票</div>
            <div v-show="item.invoiceType == 9 && item.status == 20"
                 style="color:#fff;background-color: #0B8586;"
                 @click="alertymsg()">查看发票</div>
          </div>
        </div>
      </div>
      <pagination :total="page.total"
                  :limit="page.pageSize"
                  :page="page.currentPage"
                  @page-change="pageChange">
      </pagination>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import Pagination from '../../../components/page/pagination.vue';
export default {
  name: 'userMyOrder',
  inject: ['reload'],
  components: { Pagination },
  data () {
    return {
      kcname: '',
      theOrderStatus: 1,
      theOorderType: 0,
      // 订单类型
      ordersType: '',
      // 支付方式
      Paymentgote: true,
      // 支付二维码
      PaymentCode: false,
      ZomCover: false,
      // 支付订单号
      ordernum: '',
      radio: '',
      // 用户id
      AuthorId: '',
      CourHeadNav: [
        { name: '全部', id: 1 },
        { name: '未支付', id: 2 },
        { name: '已完成', id: 3 },
        { name: '已过期', id: 4 }
      ],
      page: {
        total: 0,
        pageSize: 5,
        currentPage: 1
      },
      orderId: '',
      orderindex: '',
      couhead: 2,
      tableDataList: [],
      ordertotalFee: '',
      checkArr: [],
      cheackAllChecked: false,
      checkCompleteArr: [],
      checkArrid: [],
      // 订单数量
      checkcoutlis: 0,
      // 订单价格
      totalPrice: 0,
      orderStatus: [
        { label: '全部', id: 1 },
        { label: '未支付', id: 2 },
        { label: '已完成', id: 3 },
        { label: '已过期', id: 4 }
      ],
      orderType: [
        { label: '未选择', id: 0 },
        { label: '微信', id: 1 },
        { label: '支付宝', id: 2 }
      ],
      userType: 0,
      isloading: true
    };
  },
  methods: {
    // 点击移开发票弹框
    alertymsg () {
      this.$router.push({
        path: '/user/userMyInvoice',
        query: {
          name: '我的发票'
        }
      })
    },
    // 选中
    changevalue () {
      this.checkcoutlis = this.checkArr.length;
      if (this.checkArr) {
        this.tableDataList.forEach((item, index) => {
          if (index >= 0) {
            this.checkArrid.push(item.ordesId);
          }
        });
      }
      var totalPrice = 0;
      for (var k in this.checkArr) {
        if (this.checkArr[k].totalFee) {
          totalPrice = totalPrice + Number(this.checkArr[k].totalFee);
        }
      }
      this.totalPrice = totalPrice;
    },
    // 全选
    toggleSelectionall () {
      var _this = this;
      _this.checkArr = [];
      if (_this.cheackAllChecked) {
        _this.tableDataList.forEach((item, index) => {
          if (index >= 0) {
            _this.checkArr.push(item);
            _this.checkArr.filter((j, i) => {
              if (j.invoiceType != 1) {
                _this.checkArr.splice(i, 1);
              }
            });
            var totalPrice = 0;
            for (var k in _this.checkArr) {
              if (_this.checkArr[k].totalFee) {
                totalPrice = totalPrice + Number(_this.checkArr[k].totalFee);
              }
            }
            _this.totalPrice = totalPrice;
          }
        });
      } else {
        _this.totalPrice = 0;
      }
      _this.checkcoutlis = _this.checkArr.length;
    },
    getIsOverdue (year) {
      var date = new Date();
      return year == date.getFullYear()
    },
    // 全部订单申请开票
    ApplyMakeStart (id, Price, year) {
      if (this.getIsOverdue(year)) {
        this.$router.push({
          path: '/user/Personal/OpenInvoice',
          query: {
            id: id,
            name: '申请发票',
            totalPrice: Price
          }
        })
      } else {
        this.$message({
          message: '超出本年度，系统将无法开具发票！'
        });
      }
    },
    // 全选开票
    ApplyMakeAll () {
      if (this.checkArr) {
        if (this.checkArr.length != 0) {
          this.$router.push({
            path: '/Personal/OpenInvoice',
            query: {
              name: '申请开发票',
              ordesId: this.checkArr,
              totalPrice: this.totalPrice
            }
          });
        } else {
          this.$message({
            message: '请勾选订单',
            type: 'warning'
          });
        }
      }
    },
    // 点击订单跳转详情
    TomenuDetail (item) {
      if (item.ordersType == 1) {
        let url = this.httphelper.IsDebugCgphome() + '/detail?courseId=' + item.courseId;
        window.open(url);
      } else if (item.ordersType == 3) {
        this.$router.push({
          path: '/user/userCredits'
        })
      } else {
        this.$router.push({
          path: '/user/userVip'
        })
      }
    },
    // 点击付款拉起支付弹框
    ToPayMent (item) {
      this.ordertotalFee = item.totalFee;
      // 支付类型
      this.ordersType = item.ordersDetaList[0].ordersType;
      this.ZomCover = true;
      // 去支付订单号
      this.ordernum = item.orderNumber;
    },
    // 关闭支付弹框
    ZomClose () {
      this.ZomCover = false;
      this.reload();
    },
    // 判断时间差的方法
    diffTimeHour (oldTime, newTime) {
      const timeDiff = new Date(newTime.replace(/-/g, '/')).getTime() - new Date(oldTime.replace(/-/g, '/')).getTime();
      const formatTimeDiff = timeDiff / (3600 * 1000);
    },
    // 获取订单列表
    getOrderList (id, index, val) {
      this.couhead = index;
      this.orderId = id;
      this.orderindex = index;
      this.page.currentPage = val;
      this.httphelper
        .post('OrdersAPI/GetUserOrdes', {
          OrdType: this.theOrderStatus,
          AuthorId: this.AuthorId,
          page: val,
          Limit: this.page.pageSize,
          payType: this.theOorderType,
          keyWords: this.kcname
        })
        .then(res => {
          this.tableDataList = res.data.filter(function (itme) { return itme["ordersType"] == 4 });
          this.page.total = this.tableDataList.length;
        });
    },
    // 点击分页
    pageChange (val) {
      this.page.currentPage = val;
      this.getOrderList(this.orderId, this.orderindex, val);
      let osTop = document.documentElement.scrollTop || document.body.scrollTop;
      let ispeed = Math.floor(-osTop / 1.0);
      document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
      this.isTop = true;
    },
    // 删除订单
    DelOrder (item) {
      this.$confirm('订单删除后不可找回，是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.httphelper
          .post('OrdersAPI/DelOrdersRecord', {
            orderNumber: item.orderNumber,
            authorId: this.AuthorId
          })
          .then(res => {
            if (res.code == 200) {
              this.$message({
                message: '删除订单成功',
                type: 'success'
              });
              this.getOrderList(this.orderId, this.orderindex, this.page.currentPage);
            } else if (res.code == 205) {
              this.$message({
                message: '删除订单失败',
                type: 'warning'
              });
            } else if (res.code == 202) {
              this.$message({
                message: '已支付订单禁止删除',
                type: 'warning'
              });
            }
          });
      });
    },
    // 提交支付
    paySubmit () {

      if (this.radio == false) {
        this.$message({
          message: '请选择付费方式',
          type: 'warning'
        });
      } else {
        this.isloading = true
        this.httphelper
          .post('CGpPayApi/OrderNumberPayment', {
            orderNumber: this.ordernum,
            userId: Number(this.AuthorId),
            payWay: Number(this.radio),
            spBillCreateIp: localStorage.getItem('Ip'),
            paymethod: this.radio == 1 ? 1 : 4,
            code: '150'
            // pageUrl: 'https://qkxy.chinagp.net/#/paySuccess?payType=' + this.ordersType
          })
          .then(res => {
            if (res.code == 200) {
              if (this.radio == 1) {
                this.Paymentgote = false;
                this.PaymentCode = true;
                var qrcode = new QRCode(document.getElementById('wxpay'), {
                  width: 150,
                  height: 150
                });
                qrcode.makeCode(res.data.qrCodeUrl);
                this.isloading = false
                this.wxOrderNumber = res.data.outTradeNo;
                var _this = this;
                this.timer = setInterval(() => {
                  _this.WxPayOK(this.ordersType);
                }, 3000);
              } else if (this.radio == 2) {
                this.Paymentgote = false;
                this.PaymentCode = true;
                const div = document.createElement('iframe');
                const wxcode = document.getElementById('wxpay')
                div.srcdoc = res.data.responseBody;
                div.style = 'width:150px;overflow: hidden;'
                div.marginwidth = "0"
                div.framespacing = "0"
                div.marginheight = "0"
                div.setAttribute('frameborder', 'no')
                div.scrolling = 'no'
                div.onload = () => {
                  this.isloading = false
                };
                wxcode.appendChild(div);
                this.wxOrderNumber = res.data.outTradeNo;
                // var _this = this;
                this.timer = setInterval(() => {
                  this.WxPayOK(this.ordersType);
                }, 3000);
              }
            } else if (res.code == 205) {
              this.$message({
                message: '此订单已失效',
                type: 'warning'
              });

              // 67951650
            } else if (res.code == 201) {
              this.$message({
                message: '此订单已付款，请确认订单',
                type: 'warning'
              });
            }
          });
      }
    },
    // 判断是否支付成功
    WxPayOK (ordersType) {
      this.httphelper
        .post('CGpPayApi/WeChatVer', {
          ordernumber: this.wxOrderNumber
        })
        .then(res => {
          if (res.code == 200) {
            if (ordersType == 2) {
              this.getInfo();
            }
            clearInterval(this.timer);
            this.$message({
              type: 'success',
              message: '支付成功!'
            });
            this.ZomCover = false;
            this.reload();
            this.$router.push({
              path: '/paySuccess',
              query: {
                payType: ordersType
              }
            });
          } else {
            // clearInterval(this.timer);
          }
        });
    },
    // 获取用户信息
    getInfo () {
      this.httphelper
        .post('CGpUserApi/GetUserMessage', {
          id: this.AuthorId
        })
        .then(res => {
          var aData = new Date();
          var CurrentDate = aData.getFullYear() + '-' + (aData.getMonth() + 1) + '-' + aData.getDate() + ' ' + aData.getHours() + ':' + aData.getMinutes() + ':' + aData.getSeconds();
          var VipEdDate = res.data.memberDate.m_EndDate;
          var CurrentDategetTime = new Date(CurrentDate.replace(/-/g, '/')).getTime() / 1000;
          var VipEdDategetTime = new Date(VipEdDate.replace(/-/g, '/')).getTime() / 1000;
          if (res.data.memberDate.m_EndDate == null || parseInt(CurrentDategetTime) - parseInt(VipEdDategetTime) >= 0) {
            this.userType = 0;
          } else {
            if (res.data.memberDate.m_Type == 1) {
              this.userType = 1;
            } else if (res.data.memberDate.m_Type == 2) {
              this.userType = 2;
            } else {
              this.userType = 0;
            }
          }
          localStorage.setItem('userType', this.userType);
        });
    },
    tosx () {
      this.couhead = 0;
      this.httphelper
        .post('OrdersAPI/GetUserOrdes', {
          OrdType: this.theOrderStatus,
          AuthorId: this.AuthorId,
          page: 1,
          Limit: this.page.pageSize,
          payType: this.theOorderType,
          keyWords: this.kcname
        })
        .then(res => {
          this.tableDataList = res.data.filter(function (itme) { return itme["ordersType"] == 4 });// res.data;
          this.page.total = this.tableDataList.length;
        });
    },
    todel (item) {
      this.$confirm('确定要删除该订单吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.httphelper.post('OrdersAPI/DelOrdersRecord', {
          AuthorId: this.AuthorId,
          orderNumber: item.orderNumber
        }).then(res => {
          if (res.code == 200) {
            this.tosx()
            this.$message({
              type: 'success',
              message: '删除成功'
            });
          } else if (res.code == 202) {
            this.$message({
              message: '已支付订单禁止删除',
              type: 'warning'
            });
          } else {
            this.$message({
              message: '删除失败',
              type: 'warning'
            });
          }
        })
      });

    }
  },
  created () {
    // 获取当前时间 时分秒
    var _this = this;
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth() + 1;
    let dd = new Date().getDate();
    let hh = new Date().getHours();
    let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
    let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
    _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id;
    }
    this.tosx()
  },
  destroyed () {
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
::v-deep.el-select {
  width: 215px;

  /* selecte 框的高度设置，默认是 40px*/
  .el-input__inner {
    height: 30px;
  }

  /* 下面设置右侧按钮居中 */
  .el-input__suffix {
    top: 5px;
  }

  .el-input__icon {
    line-height: inherit;
  }

  .el-input__suffix-inner {
    display: inline-block;
  }
}

.NoneData {
  padding: 40px;
  text-align: center;
  color: #999;
  font-size: 18px;
  letter-spacing: 1px;
}

.NoneData i {
  font-size: 36px;
  color: #4da9e8;
  position: relative;
  top: 7px;
  margin-right: 7px;
}

.titleBar {
  height: 40px;
  background: #f2f4f7;
  line-height: 40px;
  text-align: center;
}

.footbar {
  height: 50px;
  background: #f2f4f7;
  line-height: 50px;
  margin-top: 20px;
}

.zhifufangshi {
  margin-left: 41px;
}

.tableKcInput {
  margin-left: 41px;
  width: 203px;
  height: 28px;
  padding: 0;
  border: 1px solid #0b8586;
  border-radius: 3px 0px 0px 3px;
  border-right: none;
  padding-left: 24px;
  outline: none;
  font-size: 14px;
}

.tableKcBtn {
  width: 73px;
  height: 30px;
  line-height: 28px;
  background: #0b8586;
  border: none;
  border-radius: 0px 3px 3px 0px;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fffefe;
  position: relative;
  top: 1px;
  cursor: pointer;
}

.sousuo {
  position: absolute;
  top: 8px;
  left: 678px;
}

.gl_top2 {
  position: relative;
}

.gl_table td {
  width: 10%;
}

.gl_table .caozuo p {
  cursor: pointer;
  line-height: 30px;
}

.paymentcover {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(000, 000, 000, 0.3);
  z-index: 999;
  left: 0;
  top: 0;

  .paymentcover_main {
    width: 572px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .paymentcover_close {
      width: 100%;
      height: 20px;
      text-align: right;
      line-height: 20px;
      position: relative;

      i {
        color: #ffffff;
        position: absolute;
        font-size: 35px;
        cursor: pointer;
        -webkit-transition: -webkit-transform 0.5s ease-out;
        -moz-transition: -moz-transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
        font-weight: bold;
      }

      i:hover {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    .payment_body {
      background: #fff;
      box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      padding: 25px;
      padding-bottom: 25px;
      position: relative;

      .PayCont_body_notice {
        position: relative;
        line-height: 22px;

        span:nth-child(1) {
          color: #000000;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 1px;
        }
      }

      .PayMethods {
        width: 100%;
        margin-top: 15px;
        border-top: 1px solid #eee;
        padding-top: 21px;

        .PayMethods_list {
          height: 100px;
          background: #fafafa;
          margin-top: 18px;
          padding: 19px 14px;

          li {
            height: 50px;
            line-height: 50px;
            list-style: none;

            ::v-deep .el-radio__input.is-checked .el-radio__inner {
              border: 1px solid #c2c2c2;
              background: #fff;
            }

            ::v-deep .el-radio__inner {
              width: 12px;
              height: 12px;
            }

            ::v-deep .el-radio__inner::after {
              background: #e00019;
            }

            ::v-deep .el-radio__label {
              padding-left: 32px;
            }

            i {
              font-size: 26px;
              position: relative;
              top: 4px;
              margin-right: 15px;
            }

            span {
              color: #000;
              font-size: 15px;
            }
          }

          li:nth-child(2) {
            i {
              color: #07a0f8;
              font-size: 26px;
            }
          }

          li:nth-child(1) {
            i {
              color: #02bb29;
              font-size: 24px;
            }
          }
        }
      }

      .paysubmit {
        width: 80px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        border-radius: 3px;
        cursor: pointer;
        background: #23b8ff;
        border: 1px solid #23b8ff;
        color: #fff;
        margin-top: 20px;
        margin-left: 438px;
        // position: absolute;
        // right: 0;
      }
    }

    .PayCont_body_PayCode {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      position: relative;
      margin-top: 30px;
      padding-bottom: 25px;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      p {
        position: absolute;
        bottom: -28px;
        // left: 25px;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.obox {
  padding: 10px 30px;
  margin-top: 20px;
  width: 973px;
  box-sizing: border-box;
  border: 1px solid #eee;

  // box-shadow: 0 2px 5px 2px rgb(158, 158, 158);
  .obtop {
    border-bottom: 1px solid #eee;
    height: 50px;
    line-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .obtleft {
      display: flex;
      align-items: center;
      font-size: 15px;

      img {
        margin-right: 5px;
        margin-top: -2px;
      }

      .obtime {
        font-size: 14px;
        color: rgb(165, 165, 165);
        margin-left: 30px;
      }
    }

    .obtright {
      font-size: 15px;
    }
  }

  .obmid {
    margin: 20px 0;
    display: flex;

    .obmbox {
      border-left: 1px solid #eee;

      .obsbox {
        display: flex;
        padding-right: 10px;
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #eee;
        font-size: 15px;
        cursor: pointer;

        img {
          width: 160px;
          height: 90px;
          margin-right: 10px;
        }
      }
    }

    .obmbox1 {
      width: 511px;
    }

    .obmbox2 {
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #b70109;
    }

    .obmbox3 {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div {
        height: 25px;
        line-height: 25px;
        font-size: 13px;
        width: 100px;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
        margin: 5px;
        background-color: #eee;
      }
    }
  }
}
</style>
