<template>
  <div>

    <!-- <div class="EC_width">
      <Header />
    </div>
    <user_head /> -->
    <div class="gl_title_bar">
      <div class="gl_tb_left">
        <span class="normaltitle">个人资料</span>
      </div>
      <div class="gl_tb_right"
           @click="showUserData()">
        <a href="javascript:;"
           class="gl_tb_right_more">
          <img src="../../assets/image/bianji.png"
               class="gltb_moreimg">
          <span class="normalColor">编辑个人资料</span>
        </a>
      </div>
    </div>
    <div class="formWrap">

      <div class="display_center_ud marginTop20 marginBottom40">
        <!-- <img :src="apiData.headImg" class="marginRight40"> -->
        <!-- <el-upload action="123" :http-request="upLoad"  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"> -->
        <img v-if="form.headUrl"
             :src="form.headUrl"
             style=" width:110px;height:110px;border-radius: 50% "
             class="marginRight40">

      </div>
      <div class="marginBottom50"
           style="width: 600px;">
        <div class="display_between marginBottom20">
          <div class="display_center_ud flex1">
            <div class="formTitle">
              昵称:
            </div>
            <div class="formcontent">
              {{ form.nickname }}
            </div>
          </div>
        </div>
        <div class="display_between marginBottom20">
          <div class="display_center_ud flex1">
            <div class="formTitle">
              手机号:
            </div>
            <div class="formcontent">
              {{ form.phone }}
            </div>
          </div>
          <div class="display_center_ud flex1">
            <div class="formTitle">
              学历:
            </div>
            <div class="formcontent">
              {{ form.Education }}
            </div>
          </div>
        </div>
        <div class="display_between marginBottom20">
          <div class="display_center_ud flex1">
            <div class="formTitle">
              姓名:
            </div>
            <div class="formcontent">
              {{ form.userName }}
            </div>
          </div>
          <div class="display_center_ud flex1">
            <div class="formTitle">
              科室:
            </div>
            <div class="formcontent">
              {{ form.AdmiOffice }}
            </div>
          </div>
        </div>
        <div class="display_between marginBottom20">
          <div class="display_center_ud flex1">
            <div class="formTitle">
              身份证号:
            </div>
            <div class="formcontent">
              {{ form.IDcard }}
            </div>
          </div>
          <div class="display_center_ud flex1">
            <div class="formTitle">
              职称:
            </div>
            <div class="formcontent">
              {{ form.JobTitle }}
            </div>
          </div>
        </div>
        <div class="display_between marginBottom20">
          <div class="display_center_ud flex1">
            <div class="formTitle">
              邮箱:
            </div>
            <div class="formcontent">
              {{ form.mail }}
            </div>
          </div>
          <div class="display_center_ud flex1">
            <div class="formTitle">
              所在地区:
            </div>
            <div class="formcontent">
              {{ region }}
            </div>
          </div>
        </div>
        <div class="display_between marginBottom20">
          <div class="display_center_ud flex1">
            <div class="formTitle">
              单位:
            </div>
            <div class="formcontent">
              {{ form.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import axios from 'axios';
import { areaList } from '@vant/area-data';
// import Header from '@/components/header'
// import user_head from '@/components/header/user_head'
export default {
  inject: ['reload'],
  name: "userMyInforShow",
  components: {
    // Header,
    // user_head
  },
  data () {
    return {
      apiData: "",
      form: {
        // 头像
        headUrl: '',
        // 机构认证
        org: '',
        // 医生圈认证
        doctor: '',
        // 医生会员认证
        doctorType: false,
        // 手机号
        phone: '',
        // 姓名
        userName: '',
        // 单位
        unit: '',
        // 微信ID
        wxid: '',
        // 学历
        Education: '',
        // 职称
        JobTitle: '',
        // 科室
        AdmiOffice: '',
        //身份证号
        IDcard: '',
        nickname: '',
        mail: ''
      },
      // 用户个人信息
      userPerInfo: '',
      // 用户id
      AuthorId: '',
      region: ''
    }
  },
  methods: {
    showUserData () {
      this.$router.push({
        path: 'userData'
      });
    },
    upLoad (file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('Authorid', this.AuthorId)
      formData.append('OfType', 1)
      this.httphelper.post('OssFileApi/ImageUpload', formData).then((res) => {
        if (res.code) {
          this.$message({
            message: '头像上传成功',
            type: 'success'
          })
          this.form.headUrl = res.data.src
          //刷新页面
          this.reload();
        } else {
          this.$message('头像上传失败失败');
        }
      })
    },
    handleAvatarSuccess (res, file) {
      this.form.headUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 PNG 格式 或者 JPG!');
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return (isJPG || isPNG) && isLt10M;
    },
    // 手机号中间四位隐藏
    mobileStr (str) {
      if (str.length > 7) {
        return str.substring(0, 3) + '****' + str.substring(7, str.length);
      } else {
        return str.substring(0, str.length - 1) + '****';
      }
    },
    // 获取用户个人信息
    getUserInfo () {
      this.httphelper.post('CGpUserApi/GetUserMessage', {
        id: parseInt(this.AuthorId)
      }).then((res) => {
        // 绑定微信
        this.bindingWeChat = res.data.bindingWeChat
        this.userPerInfo = res.data
        this.form.headUrl = this.userPerInfo.avatarUrl
        if (this.userPerInfo.mobile) {
          this.form.phone = this.mobileStr(this.userPerInfo.mobile)
        } else {
          this.form.phone = ''
        }
        this.form.userName = this.userPerInfo.userName
        this.form.unit = this.userPerInfo.unit
        this.form.org = this.userPerInfo.org
        this.form.doctor = this.userPerInfo.doct
        this.form.Education = this.userPerInfo.educatId
        this.form.JobTitle = this.userPerInfo.professionalId
        this.form.AdmiOffice = this.userPerInfo.admoffId
        this.form.nickname = this.userPerInfo.nickname
        this.form.IDcard = this.userPerInfo.iDcard
        this.form.mail = this.userPerInfo.mail

        // 医生会员认证
        if (this.userPerInfo.doctorType == 0) {
          this.form.doctorType = true
        } else if (this.userPerInfo.doctorType == 1 || this.userPerInfo.doctorType == 9) {
          this.form.doctorType = false
        }

        // // 学历
        if (this.userPerInfo.educatId == 0) {
          this.form.Education = ''
        } else {
          this.form.Education = this.userPerInfo.educatId
          this.getEcujobam(1);
        }
        // 职称
        if (this.userPerInfo.professionalId == 0) {
          this.form.JobTitle = ''
        } else {
          this.form.JobTitle = this.userPerInfo.professionalId
          this.getEcujobam(2);
        }
        // 科室
        if (this.userPerInfo.admoffId == 0) {
          this.form.AdmiOffice = ''
        } else {
          this.form.AdmiOffice = this.userPerInfo.admoffId
          this.getEcujobam(3);
        }
        for (let a in areaList.province_list) {
          if (a == this.userPerInfo.province) {
            this.region = areaList.province_list[a]
          }
        }
        for (let a in areaList.city_list) {
          if (a == this.userPerInfo.city) {
            this.region = this.region + ' / ' + areaList.city_list[a]
          }
        }
        for (let a in areaList.county_list) {
          if (a == this.userPerInfo.district) {
            this.region = this.region + ' / ' + areaList.county_list[a]
          }
        }
      })
    },
    // 获取学历、职称、科室
    getEcujobam (type) {
      axios.get('BasicsmesApi/GetCodeResultList?type=' + type).then((res) => {
        if (type == 1) {
          this.Eduoption = res.data
          console.log(res.data)
          res.data.data.forEach((item) => {
            if (item.id == this.userPerInfo.educatId) {
              this.form.Education = item.valueText
            }
          })
        } else if (type == 2) {
          this.Joboption = res.data
          res.data.data.forEach((item) => {
            if (item.id == this.userPerInfo.professionalId) {
              this.form.JobTitle = item.valueText
            }
          })
        } else if (type == 3) {
          this.AdmiOfficeData = res.data
          res.data.data.forEach((item) => {
            if (item.id == this.userPerInfo.admoffId) {
              this.form.AdmiOffice = item.valueText
            }
          })
        }
      })
    },
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.AuthorId = JSON.parse(localStorage.getItem('ResData')).id
    }
    this.getUserInfo()
  },
}
</script>

<style scoped>
.formWrap {
  position: relative;
}

.input {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
}

.renzhengbtn {
  display: inline-block;
  width: 120px;
  height: 30px;
  background: #785513;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}

.formTitle {
  margin-right: 20px;
  text-align: right;
  /* width: 60px; */
  color: #8d8d8d;
}

.cltop {
  display: flex;
  margin-bottom: 20px;
}

.cltbtn {
  width: 97px;
  height: 40px;
  background: #c32424;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fffefe;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  margin-left: 10px;
}

.cltinput {
  flex: 1;
}

.cltinput /deep/ .el-input__inner {
  border-color: #0b8586;
}

.cltinput /deep/ .el-input-group__append,
.cltinput /deep/.el-button {
  background-color: #0b8586;
  color: #fffefe;
  border-color: #0b8586;
}

.cltinput /deep/.el-button {
  width: 97px;
  font-family: Microsoft YaHei;
}

.Courselist /deep/ .el-radio__inner {
  border-color: #aaaaaa;
}
</style>
 
 