<template>
  <div>
    <div class="gl_title_bar">
      <div class="gl_tb_left">
        <span class="normaltitle">我的收藏</span>
      </div>
    </div>
    <div class="formWrap">
      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane label="全部"
                     :name="null"></el-tab-pane>
        <el-tab-pane v-for="item in dateList"
                     :label="item.label"
                     :key="item.label"
                     :name="item.label">
        </el-tab-pane>
      </el-tabs>
      <div class="content">
        <div class="item"
             v-if="courselist.length > 0"
             v-for="item in courselist"
             :key="'cour' + item.id">
          <div class="suitable"
               @click="tocurr(item.address)">
            <img class="book_image"
                 v-if="item.slideShow"
                 :src="item.slideShow"
                 alt="">
            <img class="lb_img"
                 v-else
                 src="../../assets/icon/lb.png"
                 alt="">
            <!-- <img class="bo"
                 src="@/assets/icon/bofang2.png"
                 alt=""> -->
          </div>
          <div class="items">
            <div class="explain">
              {{ item.title }}
            </div>
            <!-- <div class="lbbot">
              <div class="lbbleft">
                <span>已学</span>
                <span class="lbjd">{{ Math.ceil(item.learning * 100).toFixed(0) + '%' }}</span>
              </div>
              <span class="lbtime"> {{ item.createTime }}</span>
            </div> -->
          </div>
          <div class="display_between"
               style="float: right;"
               @click.stop="collection(item)">
            <div class="pubs_body"
                 style="padding-bottom: 10px; padding-top: 0; cursor: pointer;">
              <div style="border: 1px red solid; border-radius: 5px;
                             width: 80px; line-height: 25px;
                             font-size: 14px; text-align: center; 
                            background-color: red; color: #fff;">
                <span>取消收藏</span>
              </div>
            </div>
          </div>
        </div>
        <div class="empty"
             v-if="courselist <= 0">
          <img src="../../assets/image/empty.png"
               alt="">
        </div>
      </div>
      <div class="pagination_Wrap">
        <pagination v-if="page.total > 0"
                    :total="page.total"
                    :limit="page.limit"
                    :page="page.index"
                    @page-change="pageChange"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userMyCourse',
  components: {
  },
  data () {
    return {
      AuthorId: 0,
      courselist: [],
      page: {
        index: 0,
        limit: 12,
        total: 0
      },
      dateList: [
        {
          label: '科研选题与设计'
        },
        {
          label: '文献检索'
        },
        {
          label: '量表工具'
        },
        {
          label: '数据统计'
        },
        {
          label: '论文写作'
        },
        {
          label: '翻译工具'
        }
      ],
      activeName: null,
      type: 0
    }
  },
  created () {
    if (localStorage.getItem('ResData')) {
      this.AuthorId = Number(JSON.parse(localStorage.getItem('ResData')).id);
      this.getcourselist(1)
    }
  },
  methods: {
    handleClick (tab) {
      console.log(tab)
      this.type = tab.label == '科研选题与设计' ? 6 : (tab.label == '文献检索' ? 1 : (tab.label == '量表工具' ? 2 : (tab.label == '数据统计' ? 3 : (tab.label == '论文写作' ? 4 : (tab.label == '翻译工具' ? 5 : 0)))))
      console.log(this.type)
      this.getcourselist(1)
      // if (tab.label == '全部') {
      //   this.yearIndex = null
      //   this.getNewCoursePagelist(0)
      // } else {
      //   this.yearIndex = tab.index - 1
      //   this.getNewCoursePagelist(tab.label)
      // }
    },
    pageChange (val) {
      this.page.index = val;
      this.getcourselist(val);
      let osTop = document.documentElement.scrollTop || document.body.scrollTop;
      let ispeed = Math.floor(-osTop / 1.0);
      document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;

    },
    tocurr (url) {
      //跳转详情页面 
      // this.$router.push({
      //   name: 'detail',
      //   query: {
      //     courseId: cid
      //   }
      // })
      if (url) {
        window.open(url, '_blank')
      }
    },
    getcourselist (val) {
      // claType:1---购买课程,免费课程;2---机构课程;3---医生圈课程;4---VIP限免
      let _this = this;
      _this.page.index = val
      this.httphelper
        .post('ScientificApi/GetMyGpSiteNewFavorites', {
          type: this.type,
          userId: _this.AuthorId,
          // page: val,
          // limit: _this.page.limit
        })
        .then(res => {
          if (res.code == 200) {
            // res.data.forEach(i => {
            //   i.createTime = i.createTime.substr(0, 10);
            // });
            if (res.data != null && res.data.length > 0) {
              _this.courselist = res.data;
              _this.page.total = res.totalcount
            } else {
              _this.courselist = []
            }
          }
        });
    },
    toMore () {
      this.page.index++
      this.httphelper.post('CourseClassApi/GetClassSchedPageList', {
        claType: 1,
        authorId: this.AuthorId,
        page: this.page.index,
        limit: this.page.limit
      })
        .then(res => {
          if (res.code == 200) {
            this.courselist = this.courselist.concat(res.data)
          }
        });
    },
    toSouqi () {
      this.page.index = 1
      this.courselist = this.courselist.slice(0, this.page.limit)
    },

    collection (item) {
      console.log(item)
      this.$confirm('你确定要取消收藏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          newId: item.id,
          userId: this.AuthorId,
          isFavorite: item.isFavorite ? 0 : 1,
          type: 2,
          c_Id: item.c_ID
        }
        this.httphelper.post('/ScientificApi/AddGpSiteNewInfoFavorites', params).then(res => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.getcourselist()
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      })
    }
  }
};
</script>

<style lang="scss" scoped>
/* 分页器 */
.pagination_Wrap {
  margin-top: 50px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

.content {
  margin-top: 7px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 280px;
    margin: 0 26px 27px 0;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 5px 5px;

    .suitable {
      position: relative;
      cursor: pointer;
      min-height: 158px;
      display: flex;
      align-items: center;
      .bo {
        position: absolute;
        bottom: 13px;
        left: 11px;
      }

      .book_image {
        width: 280px;
        height: 158px;
      }
      .lb_img {
        width: 80px;
        margin: 0 auto;
        display: block;
      }
    }

    .items {
      padding: 7px 15px 12px;

      .explain {
        // margin-bottom: 14px;
        width: 100%;
        // height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .ident {
        span {
          display: inline-block;
          padding: 0 5px;
          margin-right: 3px;
          height: 19px;
          line-height: 19px;
          border: 1px solid #979fa6;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #979fa6;
        }
      }

      .price {
        .money {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #f01414;
        }

        img {
          width: 10px;
          height: 11px;
        }

        .shu {
          margin-left: 9px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #919ca3;
        }
      }

      .lbbot {
        display: flex;
        justify-content: space-between;

        .lbbleft {
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #919ca3;
          line-height: 12px;

          .lbjd {
            color: #0b8586;
          }
        }

        .lbtime {
          font-size: 10px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #919ca3;
          line-height: 12px;
        }
      }
    }
  }

  .item:nth-child(4n) {
    margin: 0 0 27px 0;
  }
}

.more {
  margin: 3px auto 0;
  width: 240px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  border: 1px solid #979fa6;
  border-radius: 18px;
}
.empty {
  width: 435px;
  height: 211px;
  margin: 60px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
