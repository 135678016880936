function delUrlData (name) {
  var url = window.location.href;
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    //参数名前缀
    var prefix = encodeURIComponent(name) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //循环查找匹配参数
    for (var i = pars.length; i-- > 0;) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        //存在则删除
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
}
function isMobile () {
  let m =
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
  return navigator.userAgent.match(m);
}
function isAndroid () {
  let m =
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i;
  return navigator.userAgent.match(m)[1] == "Android";
}
function isWechat () {
  var w = navigator.userAgent.toLowerCase();
  return w.match(/MicroMessenger/i) == "micromessenger";
}
function strToBase64 (str) {
  if (str) {
    let encode = encodeURI(str);
    let base64 = btoa(encode);
    return base64;
  }
}
function base64ToStr (base64) {
  if (base64) {
    let decode = atob(base64);
    let str = decodeURI(decode);
    return str;
  }
}
function urlToCode (str) {
  if (str) return encodeURIComponent(str);
}
function codeToUrl (str) {
  if (str) return decodeURIComponent(str);
}
function getLocalStorage (name) {
  if (name) return base64ToStr(localStorage.getItem(name));
}
function setLocalStorage (name, value) {
  if (name) localStorage.setItem(name, strToBase64(value));
}
function getTime (type, str) {
  let date = "";
  if (str) date = new Date(Number(str) * 1000);
  else date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  if (type == 1)
    return year + "-" + month + "-" + day + " " + h + ":" + m + ":" + s;
  else if (type == 2) return year + "-" + month + "-" + day;
}
function getTimeCode (str) {
  let code = "";
  if (str != undefined && typeof str == "string") str = str.replace(/\-/g, "/"); // eslint-disable-line
  if (str) code = Date.parse(new Date(str)) / 1000;
  else code = Date.parse(new Date()) / 1000;
  return code;
}
function getIsToday (str) {
  if (str) {
    if (str != undefined && typeof str == "string")
      str = str.replace(/\-/g, "/"); // eslint-disable-line
    let boo =
      new Date(Number(str)).toDateString() ==
      new Date(Date.now()).toDateString();
    return boo;
  }
}
function getForNowTime (str) {
  if (str && str != undefined && typeof str == "string") {
    str = str.replace(/\-/g, "/"); // eslint-disable-line
    let code1 = Date.parse(new Date(str));
    let code2 = Date.parse(new Date());
    if (code1 > code2) return 1;
    else if (code1 < code2) return 2;
    else return 0;
  } else {
    return 0;
  }
}
/* * 
 用途：js中字符串超长作固定长度加省略号（...）处理
 参数说明：
    str:需要进行处理的字符串，可含汉字
    len:需要显示多少个汉字，两个英文字母相当于一个汉字。
 */
function beautySub (str, len) {
  var reg = /[\u4e00-\u9fa5]/g; //专业匹配中文
  var slice = str.substring(0, len);
  var chineseCharNum = ~~(slice.match(reg) && slice.match(reg).length);
  var realen = slice.length * 2 - chineseCharNum;
  return str.substr(0, realen) + (realen < str.length ? "..." : "");
}
export default {
  delUrlData,
  isMobile,
  strToBase64,
  base64ToStr,
  urlToCode,
  codeToUrl,
  getLocalStorage,
  setLocalStorage,
  getTime,
  getIsToday,
  getTimeCode,
  getForNowTime,
  isAndroid,
  isWechat,
  beautySub,
};
