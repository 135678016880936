<template>
  <div class="container">
    <div class="body">
      <swiper :options="optionsTwo"
              ref="swiper"
              v-if="banner.length > 0">
        <swiper-slide v-for="(item, index) in banner">
          <img :src="item.resUrl"
               style="width: 100vw; height: 100vh;">
        </swiper-slide>
      </swiper>
      <div class="swiper-paginationTwo"></div>
    </div>
    <div class="main">
      <swiper :options="options"
              ref="swiper">
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(1)"
               @mouseout="onOut">
            <img :src="index == 1 ? require('../../assets/image/ky_hover.png') : require('../../assets/image/ky.png')"
                 class="img"
                 alt="">
            <div class="child"
                 style="width: 150px;"
                 @click="toJump(1)">
              <div class="title"
                   :class="index == 1 ? 'active' : ''">科研选题与设计</div>
              <div class="content"
                   :class="index == 1 ? 'active' : ''">还没确定选题，正在为选题而茫然？——多维度选题推荐，助您快速找到高价值的选题方向</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(2)"
               @mouseout="onOut">
            <img :src="index == 2 ? require('../../assets/image/wx_hover.png') : require('../../assets/image/wx.png')"
                 class="imgTwo"
                 alt="">
            <div style="width: 150px;"
                 @click="toJump(2)">
              <div class="title"
                   :class="index == 2 ? 'active' : ''">文献检索</div>
              <div class="content"
                   :class="index == 2 ? 'active' : ''">怎么去找、如何去读文献？——轻松读懂文献，为您提供文献找不到、看不懂、效率低、收获少的解决方案</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(3)"
               @mouseout="onOut">
            <img :src="index == 3 ? require('../../assets/image/lb_hover.png') : require('../../assets/image/lb.png')"
                 class="img"
                 alt="">
            <div style="width: 150px;"
                 @click="toJump(3)">
              <div class="title"
                   :class="index == 3 ? 'active' : ''">量表工具</div>
              <div class="content"
                   :class="index == 3 ? 'active' : ''">到处找量表、找工具，苦不堪言？——“量表工具箱”，助您一站式找到最全医学科研量表和工具</div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(4)"
               @mouseout="onOut">
            <img :src="index == 4 ? require('../../assets/image/sj_hover.png') : require('../../assets/image/sj.png')"
                 class="img"
                 alt="">
            <div style="width: 150px;"
                 @click="toJump(4)">
              <div class="title"
                   :class="index == 4 ? 'active' : ''">数据统计</div>
              <div class="content"
                   :class="index == 4 ? 'active' : ''">如何正确统计分析数据？——这里有简单好用的统计分析工具、高效实用的统计分析方法</div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(5)"
               @mouseout="onOut">
            <img :src="index == 5 ? require('../../assets/image/lw_hover.png') : require('../../assets/image/lw.png')"
                 class="img3"
                 alt="">
            <div style="width: 150px;"
                 @click="toJump(5)">
              <div class="title"
                   :class="index == 5 ? 'active' : ''">论文写作</div>
              <div class="content"
                   :class="index == 5 ? 'active' : ''">如何写好医学科研论文？——医学科研论文撰写要点解析，手把手传授论文写作规范与技巧</div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(6)"
               @mouseout="onOut">
            <img :src="index == 6 ? require('../../assets/image/fy_hover.png') : require('../../assets/image/fy.png')"
                 class="img"
                 alt="">
            <div style="width: 150px;"
                 @click="toJump(6)">
              <div class="title"
                   :class="index == 6 ? 'active' : ''">翻译工具</div>
              <div class="content"
                   :class="index == 6 ? 'active' : ''">英文基础差，读不懂文献，写不出论文？——“翻译工具箱”，汇集好用又精准的翻译工具</div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(7)"
               @mouseout="onOut">
            <img :src="index == 7 ? require('../../assets/image/qk_hover.png') : require('../../assets/image/qk.png')"
                 class="img4"
                 alt="">
            <div style="width: 150px;"
                 @click="toJump(7)">
              <div class="title"
                   :class="index == 7 ? 'active' : ''">期刊查询</div>
              <div class="content"
                   :class="index == 7 ? 'active' : ''">写好论文，不会选刊怎么办？——千种期刊一站式查询，帮您更快找到接受自己论文的好期刊</div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide>
          <div class="swiper-slide-child"
               @mousemove="onMove(8)"
               @mouseout="onOut">
            <img :src="index == 8 ? require('../../assets/image/zj_hover.png') : require('../../assets/image/zj.png')"
                 class="img"
                 alt="">
            <div style="width: 150px;"
                 @click="toJump(8)">
              <div class="title"
                   :class="index == 8 ? 'active' : ''">科研专家</div>
              <div class="content"
                   :class="index == 8 ? 'active' : ''">遇到科研难题和挑战，向谁求助？——多位科研领域专家大咖，为您答疑解惑，助您勇攀高峰</div>
            </div>
          </div>

        </swiper-slide>
      </swiper>
      <div class="t5prevbutton el-icon-arrow-left"></div>
      <div class="t5nextbutton el-icon-arrow-right"></div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
export default {
  data () {
    return {
      options: {
        slidesPerView: 6,
        slidesPerGroup: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: '.t5nextbutton',
          prevEl: '.t5prevbutton',
          disabledClass: 't5-button-disabled'
        }
      },

      optionsTwo: {
        autoplay: {
          delay: 3000
        },
        speed: 1500,
        loop: true,
        grabCursor: true,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        pagination: {
          el: '.swiper-paginationTwo',
          clickable: true
        },
      },

      index: '',
      banner: []
    }
  },

  created () {
    this.getBanner()
  },

  methods: {
    toJump (index) {
      this.$emit('tojump', index)
    },

    onMove (index) {
      this.index = index
    },

    onOut () {
      this.index = ''
    },

    // 获取轮播图
    getBanner () {
      let params = {
        type: 1,
        ruselocation: 1,
        source: 6
      }
      this.httphelper.post('WebSetMessApi/GetRotationInfo', params).then(res => {
        console.log(res, '轮播图')
        this.banner = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  // background: url("../../assets/image/banner.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  position: relative;
}
.body {
  width: 100%;
  height: 100%;
  position: relative;
}
.main {
  position: absolute;
  bottom: 50px;
  left: 10%;
  width: 80%;
  height: 130px;
  margin: 0 auto;
  z-index: 9;
}
.swiper-slide {
  background: rgba(0, 0, 0, 0.4);
  // opacity: 0.4;
  // width: calc(16.6666% - 2px) !important;
  // height: calc(130px - 26px);

  border-radius: 10px;
  // margin-right: 2px !important;
  display: flex;
  justify-content: center;
  cursor: pointer;
  .swiper-slide-child {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 15px 20px 15px;
    .title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .content {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.7;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .active {
      color: #000;
    }
  }
}
.swiper-slide:hover {
  background: #ffffff;
}
.el-icon-arrow-left,
.el-icon-arrow-right {
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  border-radius: 50%;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.el-icon-arrow-left {
  position: absolute;
  bottom: 50px;
  left: -55px;
  z-index: 9;
}
.el-icon-arrow-right {
  position: absolute;
  bottom: 50px;
  right: -55px;
  z-index: 9;
}
// .t5-button-disabled {
//   color: #0b8586;
// }

.img {
  width: 46px;
  height: 46px;
  margin-right: 11px;
}
.imgTwo {
  width: 52px;
  height: 46px;
  margin-right: 11px;
}
.img3 {
  width: 46px;
  height: 44px;
  margin-right: 11px;
}
.img4 {
  width: 43px;
  height: 44px;
  border-radius: 0;
  margin-right: 11px;
}
.swiper-paginationTwo {
  z-index: 99;
  position: relative;
  bottom: 210px;
  left: 85%;
  // width: 24px;
}
.swiper-paginationTwo ::v-deep .swiper-pagination-bullet {
  width: 50px;
  height: 3px;
  // display: block;
  background: 7596cc;
  // margin: 8px auto;
  border-radius: 4px;
  margin-left: 10px;
}
.swiper-paginationTwo ::v-deep .swiper-pagination-bullet-active {
  background: #fff;
  width: 50px;
  height: 3px;
  border-radius: 4px;
}
@media screen and (max-width: 1900px) {
  .swiper-slide .swiper-slide-child .title {
    margin-bottom: 5px;
  }
}
</style>