<template>
  <div class="pagination_Wrap">
    <ul class="pagination_normal">
      <li class="page_item_normal pwfirst">
        <a class="page_link_normal"
           href="javascript:;"
           @click="pageChange(1)">
          <span v-if="en">First</span>
          <span v-else>首页</span>
        </a>
      </li>
      <li class="page_item_normal pwtype1">
        <a class="page_link_normal"
           href="javascript:;"
           @click="pageChange('pre')">
          <span v-if="en">Previous</span>
          <span v-else>上一页</span>
        </a>
      </li>
      <li class="page_item_normal pwtype2">
        <a class="page_link_normal"
           href="javascript:;"
           @click="pageChange('pre')">
          <span v-if="en">Previous</span>
          <span v-else>&lt;</span>
        </a>
      </li>
      <li class="page_item_normal"
          v-if="pageList[2] - 2 > 1"><a class="page_link_normal"
           href="javascript:;">...</a></li>
      <li class="page_item_normal"
          :class="{ page_active: page == item }"
          v-for="(item, index) in pageList"
          :key="'0' + index">
        <a class="page_link_normal"
           @click="pageChange(item)"
           href="javascript:;">{{ item }}</a>
      </li>
      <li class="page_item_normal"
          v-if="pageMax - pageList[2] > 2"><a class="page_link_normal"
           href="javascript:;">...</a></li>
      <li class="page_item_normal pwtype1">
        <a class="page_link_normal"
           href="javascript:;"
           @click="pageChange('next')">
          <span v-if="en">Next</span>
          <span v-else>下一页</span>
        </a>
      </li>
      <li class="page_item_normal pwtype2">
        <a class="page_link_normal"
           href="javascript:;"
           @click="pageChange('next')">
          <span v-if="en">Next</span>
          <span v-else>&gt;</span>
        </a>
      </li>
      <li class="page_item_normal pwlast">
        <a class="page_link_normal"
           href="javascript:;"
           @click="pageChange(pageMax)">
          <span v-if="en">Last</span>
          <span v-else>尾页</span>
        </a>
      </li>
    </ul>
    <div class="allnum"
         v-show="isNum">
      <span>共{{total}}条</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    // 内容总数
    total: {
      type: Number,
      default: 0
    },
    // 每页数量
    limit: {
      type: Number,
      default: 10
    },
    // 当前页码
    page: {
      type: Number,
      default: 1
    },
    //英文
    en: {
      type: Boolean,
      default: false
    },
    //是否展示总数
    isNum: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pageList: [] // 页码列表
    };
  },
  computed: {
    // 最大页数
    pageMax () {
      return Math.ceil(this.total / this.limit);
    }
  },
  created () {
    this.initData();
  },
  methods: {
    // 生成pageList页码列表
    initData () {
      this.pageList = []; // 清空页码
      var i = 1;
      do {
        this.pageList.push(i);
        i++;
      } while (i <= this.pageMax);
      this.pageList.length > 5 && (this.pageList = this.pageList.slice(0, 5)); // 最多显示5页
    },
    // 子组件事件回调：分页
    pageChange (pageCurrent) {
      if (pageCurrent == 'pre') {
        if (this.page > 1) {
          this.$emit('page-change', this.page - 1);
        } else {
          this.$emit('page-change', this.page);
        }
      } else if (pageCurrent == 'next') {
        if (this.page < this.pageMax) {
          this.$emit('page-change', this.page + 1);
        } else {
          this.$emit('page-change', this.page);
        }
      } else {
        this.$emit('page-change', pageCurrent);
      }
    },
    // 上一页
    prePage () {
      this.$emit('prePage');
    },
    // 下一页
    nextPage () {
      this.$emit('nextPage', this.pageMax);
    }
  },
  watch: {
    // 监听页码变化 -> 页码列表更新
    page (val) {
      if (val <= 3) {
        this.pageList = [];
        var i = 1;
        do {
          this.pageList.push(i);
          i++;
        } while (i <= this.pageMax);
        this.pageList.length > 5 && (this.pageList = this.pageList.slice(0, 5)); // 最多显示5页
      } else if (val === this.pageMax) {
        this.pageList = [val - 2, val - 1, val];
      } else if (val === this.pageMax - 1) {
        this.pageList = [val - 2, val - 1, val, val + 1];
      } else {
        this.pageList = [val - 2, val - 1, val, val + 1, val + 2];
      }
    },
    // 监听页码变化 -> 总数更新
    total () {
      this.initData();
    }
  }
};
</script>

<style scoped>
.pwtype2 {
    display: none;
}
.allnum {
    font-size: 15px;
    color: rgb(58, 58, 58);
    margin-right: -40px;
    margin-left: 20px;
}
.pagination_normal {
    align-items: center;
}
</style>
